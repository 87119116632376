import { solidstate } from ":shared/utils/state-manager";
import { teaming_history } from "./apis";
import { Loader2 } from ":src/components/loaders";
import { For, Match, Show, Switch } from "solid-js";
import { getDateDetails } from "@qundus.tc/agnostic.helpers/date";
export default function TeamHistory(props: IntrinsicElement<"section">) {
  const $details = solidstate.createAsync(teaming_history.GET);
  return (
    <section {...props} class={`flex flex-col w-full h-full ${props.class ?? ""}`}>
      <Switch>
        <Match when={$details.state.loading}>
          <Loader2 msg="Loading Team Logs" />
        </Match>
        <Match when={$details.state.failed}>an error occurde while trying to fetch team logs</Match>
        <Match when={$details.state.success}>
          <Show
            when={$details.value != undefined && $details.value.length > 0}
            fallback={<div>you have no actions yet</div>}
          >
            <For each={$details.value}>
              {(item) => {
                const date = getDateDetails(item.created_at);
                return (
                  <div class="flex flex-col space-x-3">
                    <p class="text-gray">
                      {date.year} | {date.month_short}-{date.day_number}-{date.day}:
                    </p>
                    <p class="text-rose">{item.action}</p>
                    <p class="text-gray">was done by</p>
                    <p class="text#a">{item.by}</p>
                    <p class="text-gray">as a</p>
                    <p class="text#a">{item.type}</p>
                  </div>
                );
              }}
            </For>
          </Show>
        </Match>
      </Switch>
    </section>
  );
}
