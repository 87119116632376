import { solidstate } from ":shared/utils/state-manager";
import type { ANavigate, NavigateOptions, PopulateRouteHrefProps } from "./_model";
import { useSearchParams, useParams } from "@solidjs/router";
import type { SetParams } from "@solidjs/router";
import { mergeRouteHref, populateRouteHref } from "./helpers";
import { onCleanup } from "solid-js";
export const $route = solidstate.create<ANavigate>(undefined);
export const $route_params = solidstate.create<{
  params: SetParams;
  options?: Partial<Omit<NavigateOptions, "afterNavigate">>;
}>(undefined);

/** i find 3 to be the sweet spot to detect this, configure it however you like. */
const max_watch_routes = 5; // when to start paying attention to
const max_route_occurance = 3; // how many times is the occurance of /auth for example is allowed
const max_window_ms = 100; // check route occurance if difference between calls or navigations is less than this max_window_ms
let last_update_interval = 0;
let route_tracker: string[] = [];
let route_error = undefined as string;
/** detect multiple repetetive redirects to prevent unnecessary navigations. can be manipulated through max_redirects */
function WillNavigationCauseError(to: string, pathname: string) {
  if (route_tracker.length < max_watch_routes) {
    route_tracker.push(to);
  }
  // console.log("WillNavigationCauseError :: ", to, pathname, route_tracker);
  let error: string = undefined;
  if (to === pathname) {
    // console.log("should disallow reroute :: ", to, " :: pathname :: ", pathname, " :: tracker :: ", route_tracker);
    error = `disallow navigation to same pathname preventing infinite redirects to ${to} \n`;
    error += `this could happen for various reasons, check route trace below to see if automatic or conditional routing is applied and is causing redirect cycle`;
    error += `\n|| route trace ||\n${route_tracker.join("\n")}`;
  } else {
    if (route_tracker.length >= max_watch_routes) {
      const now_ms = Date.now();
      const time_diff_ms = now_ms - last_update_interval;
      last_update_interval = now_ms;
      if (time_diff_ms <= max_window_ms) {
        const f = route_tracker.filter((d) => d === to);
        if (f.length >= max_route_occurance) {
          error = ` detected multiple reroutes without loading any route at ${to} \n`;
          error += `tracked routes :: ${route_tracker.join(" -> ")}`;
        } else {
          // console.log("route tracker has shifted ", route_tracker);
          route_tracker.shift();
        }
        // console.log("filter is ", f);
      }
      // console.log("time diff is ", last_update_interval, time_diff_ms);
    }
  }
  // console.log("route tracker is ", route_tracker);
  if (error == undefined) {
    // route_tracker.push(to);
  } else {
    console.warn(error);
  }
  route_error = error;
  return error;
}

let global_base: string = undefined;
export const _route_actions = {
  setGlobalBaseFromGuard: (base: string) => {
    global_base = base;
  },
  isRouteBase(path: string) {
    if (!global_base) return false;
    return global_base === path;
  },
  getGobalRouteError: () => route_error,
};

export const hooks = {
  useSearchParams,
  useParams,
};

export const triggers_funcs = {
  on_navigated: [] as (() => void)[],
};
export const triggers = {
  onNavigated(func: () => void) {
    triggers_funcs.on_navigated.push(func);
    onCleanup(() => {
      triggers_funcs.on_navigated = triggers_funcs.on_navigated.filter((x) => x !== func);
    });
  },
};

export const actions = {
  navigate(to: PopulateRouteHrefProps, options?: Partial<NavigateOptions>) {
    const final_to = mergeRouteHref({ base: global_base }, to);
    const path = populateRouteHref(final_to);
    // const path = populateRouteHref({ path: to, base: global_base });
    const error = WillNavigationCauseError(path, window.location.pathname);
    if (error == undefined) {
      $route.set({ to: path, options });
    }
    return error;
  },
  navigateDelta(delta: number) {
    $route.set({ delta });
  },
  // navigateHref(to: PopulateRouteHrefProps, options?: Partial<NavigateOptions>) {
  //   const final_to = mergeRouteHref({ base: global_base }, to);
  //   const path = populateRouteHref(final_to);
  //   const error = WillNavigationCauseError(path, window.location.pathname);
  //   // console.log("final to :: ", final_to);
  //   // console.log("pathname from HREF :: ", path, " :: ", window.location.pathname, " :: error :: ", error);
  //   if (error == undefined) {
  //     // console.log("navigating to :: ", path);
  //     $route.set({ to: path, options });
  //   }
  //   return error;
  // },
  setSearchParams(params: SetParams, options?: Partial<NavigateOptions>) {
    $route_params.set({ params, options });
  },
};
