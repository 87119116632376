import { Match, Switch, createEffect, createMemo, createSignal, splitProps } from "solid-js";
import { createFormControl } from "solid-forms";
import type { MainProps } from "./_model";
import type { FormControlData, FormControlValue } from "../SHARED/_model";

export default function <V extends FormControlValue, D extends FormControlData>(props: MainProps<V, D>) {
  const [local, others] = splitProps(props, ["control", "onChange", "update"]);
  const _control = Array.isArray(local.control) ? createFormControl<V, D>(...local.control) : local.control;
  const control_is_array = Array.isArray(local.control);

  const [$show_password, set_show_password] = createSignal(false);
  const $type = createMemo(() => {
    if (others.type === "password") {
      return $show_password() ? "text" : "password";
    }
    return others.type;
  });
  createEffect(() => {
    if (control_is_array && local.update) {
      _control.setValue(local.control[0]);
    }
  });

  return (
    <div class={"relative flex flex-row w-auto h-auto justify-between overflow-hidden"}>
      <input
        {...others}
        type={$type()}
        classList={{
          invalid: !!_control.errors,
          touched: _control.isTouched,
          disabled: _control.isDisabled,
          // required: control.isRequired,
        }}
        value={others.type !== "file" ? (_control.value as any) : ""}
        oninput={(e) => {
          const { type } = others; //e.currentTarget || {};
          let v = e.currentTarget.value as V;
          if (type === "radio") {
            v = e.currentTarget.id as V;
          } else if (type === "checkbox") {
            // v = e.currentTarget.id as V;
            // v = (!e.target.checked ? "false" : "true") as V;
            v = e.target.checked as unknown as V;
            // console.log("form ype is :: ", type, v);
          } else if (type === "file") {
            if (e.target.files && e.target.files?.[0]) {
              v = e.target.files?.[0] as any as V;
              // _control.setValue(content);
            }
          }

          // console.log("value form is :: ", v);
          _control.setValue(v);
          if (_control.isValid) {
            if (local.onChange) {
              local.onChange(v);
            }
          }
        }}
        onblur={() => _control.markTouched(true)}
        onfocus={() => _control.markTouched(true)}
        onfocusin={() => _control.markTouched(true)}
        onfocusout={() => _control.markTouched(false)}
        disabled={_control.isDisabled}
        required={_control.isRequired}
      />
      <div class="absolute top-0 flex flex-row  text-black w-full h-0 cursor-pointer justify-end items-start px-3">
        <Switch>
          <Match when={others.type === "password"}>
            {$show_password() ? (
              <i class="icon-ri:eye-fill min-w-24px h-24px " onclick={() => set_show_password(false)} />
            ) : (
              <i class="icon-ri:eye-close-fill w-24px h-24px" onclick={() => set_show_password(true)} />
            )}
          </Match>
        </Switch>
      </div>
    </div>
  );
}
