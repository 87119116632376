import { For, Match, Show, Switch, createMemo, splitProps } from "solid-js";
import type { EvaluationData, Team } from "../_model";
//
import { Table } from ":src/components/list";
import { EmptyTable } from "../components/empty-table";
import { global } from ":global";

export type Props = {
  details: EvaluationData;
  filter: { search?: string; filter?: "graded" | "ungraded" };
  onEvaluate: (props: { item: Team; itemIndex: () => number }) => void;
};

export default function SubmissionsTable(props: Props) {
  const $teams = createMemo(() => {
    const result = [];
    const teams = props.details?.teams;
    if (teams == undefined || teams.length <= 0) {
      return undefined;
    }
    const filters = props.filter;
    if (filters == undefined) {
      return teams;
    }
    const { search, filter } = filters;
    console.log("filter :: ", filter);
    for (const index in teams) {
      const item = teams[index];
      const search_values = `${item.team_name},${item.project_name}`; // search keys
      let remove = false;
      if (search != undefined && !search_values.toLowerCase().includes(search)) {
        remove = true;
      } else if (filter != undefined) {
        const has_score = item.scores != undefined;
        if (filter === "graded" && !has_score) {
          remove = true;
        } else if (filter === "ungraded" && has_score) {
          remove = true;
        }
      }
      if (!remove) {
        result.push(item);
      }
    }
    return result;
  });

  return (
    <Table
      class="text-black"
      _items={$teams()}
      _fallback={<EmptyTable msg="no submission to evaluate" />}
      _class={{
        desktop: {
          table: "border-spacing-y-2 border-spacing-x-1 text-black pt-0",
          thead: "h-2rem font-semibold text-0.77rem",
          theadTrTh: "px-5",
          theadBase: "bg#bg-default rounded-t-6",
          tbodyTr: "text-0.75rem",
          tbodyTrTd: "items-center py-2 px-5",
        },
        phone: {
          wrapper: "gap-y-3 py-2 px-0.5",
          card: "items-center shadow-md py-2 px-2 gap-y-2 rounded-xl !justify-between text-0.8rem text-black",
          header: "w-100px",
          cell: "justify-start overflow-x-auto",
        },
      }}
      _containers={{
        items: ({ item, itemIndex, screen }) => {
          if (item.scores != undefined) {
            return {
              class: "!ring-green ring-2 ring-solid rounded-xl ",
            };
          }
          return {
            class: "!ring-gray-100 ring-2 ring-solid rounded-xl",
          };
        },
      }}
      _overlays={{
        items: ({ screen, item, itemIndex }) => {
          return (
            <div
              aria-label="item_name"
              class="w-full h-full rounded-xl cursor-pointer"
              onclick={() => {
                props.onEvaluate?.({ item, itemIndex });
              }}
            />
          );
        },
      }}
      _headers={{
        ID: {
          key: "id",
          class: {
            desktop: {
              thtd: "min-w-10px w-10px max-w-10px !overflow-hidden",
            },
          },
        },
        "Team Name": {
          key: "team_name",
          class: {
            desktop: {
              th: "min-w-100px",
            },
          },
        },
        Grade: {
          key: ({ item }) => item?.scores?.total ?? "Not Graded",
        },
        Status: {
          key: ({ item }) => {
            const status = !item.scores ? "pending" : "done";
            return (
              <span
                class={
                  "capitalize rounded-xl px-2 py-1 text-0.64rem h-fit" +
                  " " +
                  (status === "pending" ? "bg-orange-100 text-orange-400" : "bg-[#85B1AD40] text-[#85B1AD]")
                }
              >
                {status}
              </span>
            );
          },
        },
        "Evaluation Time": {
          key: ({ item }) => item?.eval_time,
        },
      }}
    />
  );
}
