import type { ResourcesCardProps } from "../_model";
import { Show, splitProps } from "solid-js";
import placeholder_image from "../assets/images/cover_placeholder.png";

export function ResourcesCard(props: ResourcesCardProps) {
  const [local, others] = splitProps(props, ["link", "image", "title", "category", "description", "id"]);
  return (
    <a {...others} href={local.link} target="_blank">
      <div class="flex w-full h-50%">
        <img class="w-full h-full object-stretch" src={local.image ?? placeholder_image} alt="alt" />
      </div>
      <div class="flex flex-col w-full h-full overflow-y-auto px-2 py-2 ">
        <span class="text-black font-bold whitespace-nowrap text-truncate h-fit">{local.title ?? "No Title"}</span>
        <span class="absolute top-2 right-2  text-white text-[14px] py-1 px-4 rounded-xl bg#warn">
          {local.category ?? "No category"}
        </span>
        <p class="text-black  text-16px h-full !overflow-y-auto overflow-x-hidden py-2 scrollbar">
          {local.description ?? "No description"}
        </p>
      </div>
    </a>
  );
}
