import { createRootProps } from "@qundus.tc/dom.helpers/css";

export const THEME_VARS = createRootProps({
  object: {
    p: "primary",
    paper: "paper",
    hint: "hint",
    pt: "primary-text",
    gray: "gray",
    a: "accent",
    at: "accent-text", // <div class="bg#at" />
    page: "page",
    paget: "page-text",
    error: "error",
    info: "info",
    success: "success",
    warn: "warn",
    skeleton: "skeleton",
    green: "green",
    red: "red",
    yellow: "yellow",
    orange: "orange",
    brown: "brown",
    skin: "skin",
    disabled: "disabled",
    txtgray: "txtgray",
    border_light: "border_light",
    border_dark: "border_dark",
    bg: {
      default: "background",
      orange: "background-orange",
      light: "light-orange",
      pink: "pink",
    },

    input: {
      bg: "bg",
      text: "text",
      border: "border",
      placeholder: "placeholder",
      touched: "focus",
    },
    label: {
      text: "text",
      gray: "gray",
    },
    alert: {
      bg: "bg",
      text: "text",
      info: "info",
      error: "error",
      success: "success",
      warn: "warn",
    },

    select: {
      bg: "bg",
      bga: "bg-active",
      bgh: "bg-hover",
      text: "text",
      texta: "text-active",
      texth: "text-hover",
    },
    "nav-side": {
      bg: "bg",
      bga: "bg-active",
      text: "text",
      texta: "text-active",
      divider: "separator",
    },
  },
});
