import { global } from ":global";
import { ENDPOINTS } from "./endpoints";

export async function get_participants() {
  // return Promise.resolve(import("./get-participants.mocks")).then((r) => {
  //   return r.success;
  // });
  return global.api
    .getAuth(ENDPOINTS.get_participants)
    .then((res) => {
      // res.data[0] = res.data[res.data.length - 1];
      // res.data[1] = res.data[res.data.length - 2];
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      // message: service.api.decode_error(err, "couldn't update password"),
      return Promise.reject(err.message);
    });
}
