export const CategoryList = [
  {
    id: 1,
    value: "yyy",
  },
  {
    id: 2,
    value: "Category",
  },
  {
    id: 3,
    value: "III",
  },
];

export const teamsButtons = [
  {
    status: "In-Complete",
    value: "Send Request",
    styles: "bg#orange cursor-pointer ",
  },
  {
    status: "Completed",
    value: "Team is full",
    styles: "bg#disabled cursor-not-allowed",
  },
  {
    value: "Cancel Request",
    styles: "bg#brown cursor-pointer",
  },
];

export const teamsType = [
  {
    id: 1,
    value: "with team",
  },
  {
    id: 2,
    value: "without team",
  },
];

export const TeamsStatus = [
  {
    id: 1,
    value: "In-Complete",
  },
  {
    id: 2,
    value: "Completed",
  },
  {
    id: 3,
    value: "Active",
  },
];

// export const TeamsList:TeamsCardProps[] = [
//   {
//     id: 1,
//     status: "In-Complete",
//     category: "Design for Good",
//     teamName: "Team Name in 2 lines maximum Team Name in 2 lines maximum ",
//     projectDescription: "Project description in 2 lines Project description in 2 lines Project description in 2 lines Project description in 2 lines Project description in 2 lines ",
//     teamsImageList: [
//       {
//         id: "1",
//         src: image,
//         alt: "alt",
//         width: 200,
//         height: 300,
//       },
//       {
//         id: "2",
//         src: image,
//         alt: "alt",
//         width: 200,
//         height: 300,
//       },
//       {
//         id: "2",
//         src: image,
//         alt: "alt",
//         width: 200,
//         height: 300,
//       },
//     ],
//     btn: "Send Request",
//   },
//   {
//     id: 2,
//     status: "In-Complete",
//     category: "Design for Good",
//     teamName: "Team Name in 2 lines maximum Team Name in 2 lines maximum ",
//     projectDescription: "Project description in 2 lines Project description in 2 lines Project description in 2 lines Project description in 2 lines Project description in 2 lines ",
//     teamsImageList: [
//       {
//         id: "1",
//         src: image,
//         alt: "alt",
//         width: 200,
//         height: 300,
//       },
//       {
//         id: "2",
//         src: image,
//         alt: "alt",
//         width: 200,
//         height: 300,
//       },
//       {
//         id: "2",
//         src: image,
//         alt: "alt",
//         width: 200,
//         height: 300,
//       },
//     ],
//     btn: "Send Request",
//   },
//   {
//     id: 3,
//     status: "In-Complete",
//     category: "Design for Good",
//     teamName: "Team Name in 2 lines maximum Team Name in 2 lines maximum ",
//     projectDescription: "Project description in 2 lines Project description in 2 lines Project description in 2 lines Project description in 2 lines Project description in 2 lines ",
//     teamsImageList: [
//       {
//         id: "1",
//         src: image,
//         alt: "alt",
//         width: 200,
//         height: 300,
//       },
//       {
//         id: "2",
//         src: image,
//         alt: "alt",
//         width: 200,
//         height: 300,
//       },
//       {
//         id: "2",
//         src: image,
//         alt: "alt",
//         width: 200,
//         height: 300,
//       },
//     ],
//     btn: "Send Request",
//   },
// ]

// export const ParticipantsList:ParticipantsCardProps[] = [
//   {
//     id: 1,
//     image: {
//       id: "1",
//       src: image,
//       alt: "alt",
//       width: 200,
//       height: 300,
//     },
//     participantName: "Mohamed Dorgham",
//     jobTitle: "Job Title",
//     category: "Design for Good",
//     preivewProfile: "Preview Protofolio",
//     caption: "Lorem ipsum dolor sit amet consecte adipiscing elit amet hendrerit pretium nulla sed enim iaculis mi. ",
//     btn: "Send Invite",
//   },
//   {
//     id: 2,
//     image: {
//       id: "1",
//       src: image,
//       alt: "alt",
//       width: 200,
//       height: 300,
//     },
//     participantName: "Mohamed Dorgham",
//     jobTitle: "Job Title",
//     category: "Design for Good",
//     preivewProfile: "Preview Protofolio",
//     caption: "Lorem ipsum dolor sit amet consecte adipiscing elit amet hendrerit pretium nulla sed enim iaculis mi. ",
//     btn: "Send Invite",
//   },
//   {
//     id: 3,
//     image: {
//       id: "1",
//       src: image,
//       alt: "alt",
//       width: 200,
//       height: 300,
//     },
//     participantName: "Mohamed Dorgham",
//     jobTitle: "Job Title",
//     category: "Design for Good",
//     preivewProfile: "Preview Protofolio",
//     caption: "Lorem ipsum dolor sit amet consecte adipiscing elit amet hendrerit pretium nulla sed enim iaculis mi. ",
//     btn: "Send Invite",
//   },
//   {
//     id: 3,
//     image: {
//       id: "1",
//       src: image,
//       alt: "alt",
//       width: 200,
//       height: 300,
//     },
//     participantName: "Mohamed Dorgham",
//     jobTitle: "Job Title",
//     category: "Design for Good",
//     preivewProfile: "Preview Protofolio",
//     caption: "Lorem ipsum dolor sit amet consecte adipiscing elit amet hendrerit pretium nulla sed enim iaculis mi. ",
//     btn: "Send Invite",
//   },
//   {
//     id: 3,
//     image: {
//       id: "1",
//       src: image,
//       alt: "alt",
//       width: 200,
//       height: 300,
//     },
//     participantName: "Mohamed Dorgham",
//     jobTitle: "Job Title",
//     category: "Design for Good",
//     preivewProfile: "Preview Protofolio",
//     caption: "Lorem ipsum dolor sit amet consecte adipiscing elit amet hendrerit pretium nulla sed enim iaculis mi. ",
//     btn: "Send Invite",
//   },
//   {
//     id: 3,
//     image: {
//       id: "1",
//       src: image,
//       alt: "alt",
//       width: 200,
//       height: 300,
//     },
//     participantName: "Mohamed Dorgham",
//     jobTitle: "Job Title",
//     category: "Design for Good",
//     preivewProfile: "Preview Protofolio",
//     caption: "Lorem ipsum dolor sit amet consecte adipiscing elit amet hendrerit pretium nulla sed enim iaculis mi. ",
//     btn: "Send Invite",
//   },
// ]
