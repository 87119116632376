import { For, Portal } from "solid-js/web";
import type { Events } from "./_model";
import { $modals } from "./store";
import { Match, Show, Switch, runWithOwner, splitProps } from "solid-js";
import { solidstate } from ":shared/utils/state-manager";
import { directives$ } from ":shared/components/directives";

// TODO: add drawer to display other modals and allow jumping between them on click
// and allow linking dependant modals with modal tree like structure. also the for loop
// is going to be for the drawer and only one modal at a time is gonna be active
export default function Modal() {
  // @ts-ignore: unused function 'clickOutside'
  const { clickOutside } = directives$.events;
  const { owner, ownerMeta } = solidstate.helpers.getOwner();

  return (
    <Portal>
      <Show when={$modals.value.length > 0}>
        <main class={`!absolute top-0 !z-99 flex flex-row items-center justify-center w-full h-full bg-black/30`}>
          <For each={$modals.value}>
            {(props, index) => {
              return runWithOwner(owner, () => {
                const [local, others] = splitProps(props, ["title", "element", "startExpanded"]);
                local.element =
                  typeof local.element === "function"
                    ? local.element((event) => onModalAction(event, index()))
                    : local.element;

                // const { owner, ownerMeta } = solidstate.helpers.getOwner();
                console.log("setup from modal :: ", owner, " :: ", ownerMeta);
                const $expand = solidstate.create(local.startExpanded ?? false);
                function onModalAction(event: Events, idx: number) {
                  if (event === "close") {
                    $modals.set((s) => s.filter((_, i) => i !== idx));
                  } else if (event === "expand" && !$expand.value) {
                    $expand.set(true);
                  } else if (event === "shrink" && $expand.value) {
                    $expand.set(false);
                  }
                }
                return (
                  //? outer container
                  <section
                    {...others.outerContainer}
                    class={`!z-10 !my-1
                  px-2 py-3
                  flex flex-col  self-center !items-center !justify-center  transition-all duration-300 ease-in-out
                  ${$expand.value ? " w-full h-full " : " w-70% h-70% <md:(!w-85% !h-80%)"}
                  ${others.outerContainer?.class ?? ""}
                  `}
                    use:clickOutside={() => {
                      onModalAction("close", index());
                    }}
                  >
                    {/* inner container */}
                    <section
                      {...others.innerContainer}
                      class={`
                    !z-50 flex flex-col w-full h-full justify-between !self-center overflow-hidden rounded-lg 
                    bg-white text-black
                    px-2 py-1
                    ${others.innerContainer?.class ?? ""}
                    `}
                    >
                      <div class="!sticky !top-0 flex flex-row justify-between items-center border-b border-solid border#p bg#paper bg-white <md:(!flex-col-reverse mb-1)">
                        <h1 class="text-1.2rem <md:(text-1.05rem py-1)">{local.title || "TITLE"}</h1>
                        <div class="flex flex-row items-center space-x-2 <md:(w-full items-center justify-between py-2)">
                          <Switch>
                            <Match when={$expand.value}>
                              <i
                                class="icon-lucide:shrink w-30px h-30px cursor-pointer"
                                onclick={() => onModalAction("shrink", index())}
                              />
                            </Match>
                            <Match when={!$expand.value}>
                              <i
                                class="icon-bx:expand w-30px h-30px cursor-pointer"
                                onclick={() => onModalAction("expand", index())}
                              />
                            </Match>
                          </Switch>
                          <i
                            class="icon-carbon:close-filled w-30px h-30px cursor-pointer"
                            onclick={() => onModalAction("close", index())}
                          />
                        </div>
                      </div>
                      <div class="flex flex-col w-full h-full overflow-auto">{local.element}</div>
                    </section>
                  </section>
                );
              });
            }}
          </For>
        </main>
      </Show>
    </Portal>
  );
}
