import { global } from ":global";
import { ENDPOINTS } from "./endpoints";

export async function requestPasswordResetEmail(email: string) {
  return global.api
    .post(ENDPOINTS.post_password_reset, { email })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
