import { For, Match, Show, Switch, createEffect, createMemo, createSignal, untrack } from "solid-js";
import type { MainProps } from "./_model";
import { solidstate } from ":shared/utils/state-manager";
import { get_user } from "./apis";
import { Loader2 } from ":src/components/loaders";
import { DetailsForm, PasswordForm } from "./components";

export default function Profile(props: MainProps) {
  const $details = solidstate.createAsync(get_user);
  const $meta = createMemo(() => {
    const details = $details.value;
    const exist = details != undefined;
    return {
      id: exist ? details.id : "UNKNOWN ID",
    };
  });

  return (
    <section class="w-full flex-col h-full md:px-5 md:py-3 px-3 py-4 overflow-x-hidden  !overflow-y-auto text-black">
      <h1 class="flex flex-col text-black text-1.1rem whitespace-nowrap capitalize space-y-2 mb-2 <md:text-1rem">
        <p>Profile Setting</p>
        <span class="before:(content-['User_ID:_'] text#gray) text-yellow text-0.7em <md:(text-0.5em)">
          {$meta().id}
        </span>
      </h1>
      <Switch>
        <Match when={$details.state.loading}>
          <div class="flex w-full min-h-screen md:h-full items-center justify-center">
            <Loader2 msg="Loading Details..." />
          </div>
        </Match>
        <Match when={$details.state.failed}>an error occurred while fetching {$details.error}</Match>
        <Match when={$details.state.success}>
          <DetailsForm details={$details.value} />
          <PasswordForm details={$details.value} />
        </Match>
      </Switch>
    </section>
  );
}
