import type { ASingleStat } from "../_model";
import { Title } from "./title";

export function Percentage(props: ASingleStat<number>) {
  return (
    <div class="flex flex-col w-full h-full space-y-2">
      {/* title and percentage */}
      <div class="flex flex-row w-full h-fit justify-between items-center">
        <Title title={props?.title} />
        <p class="text#orange font-bold text-1.5rem  <md:(text-0.7rem)">{`${props?.data?.toFixed(1)}%`}</p>
      </div>
      {/* progress bar */}
      <div class="block w-full h-2 rounded-2xl bg-gray-200 overflow-hidden">
        <div style={{ width: `${props?.data}%` }} class="h-full bg#green"></div>
      </div>
    </div>
  );
}
