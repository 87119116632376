export type * as _Platform from "./_model";

import { actions, hooks } from "./store";
import { getPlatformSettings } from "./apis/platform-settings";
export const platform$ = {
  store: {
    actions,
    hooks,
  },
  apis: {
    getPlatformSettings,
  },
};
