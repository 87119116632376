import { global } from ":global";

export const is_dev = global.helper.env.isDev;
export const mocks = {
  // eslam on mail.tm, password same as email on mail.tm and moc
  eslam_hd_part: {
    email: "eslam-hd-part@rustyload.com",
    password: "Temp@4321",
  },
};
