import axios from "axios";
import type { Options } from "../_model";

export default function createApiInstance(options: Options) {
  return axios.create({
    ...options?.axiosConfig,
    baseURL: options?.baseUrl,
    // timeout: 3000,
    headers: {
      // "accept": "*/*",
      "Content-Type": "application/json",
      // "Set-Cookie": "frontend=sad; HTTPOnly; Secure;",
      // "Cross-Origin-Opener-Policy": "same-origin",
      // "X-Content-Type-Options": "nosniff"
      ...options?.axiosConfig?.headers,
    },
  });
}
