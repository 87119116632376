import { global } from ":global";
import { ENDPOINTS } from "./endpoints";
// import { Login } from "../model";
// import { actions } from "../store";

export async function validateToken(token: any) {
  return global.api
    .post(ENDPOINTS.post_password_reset_validate_token, { token })
    .then((res) => {
      // log.dev.info("token validated :: ", res);
      // store.alert.send({
      //   type: "success",
      //   message: "valid token, use your time wisely.",
      // });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      // store.alert.send({
      //   type: "error",
      //   message: service.api.decode_error(
      //     err,
      //     "invalid token, try requesting another one."
      //   ),
      // });
      return Promise.reject(err);
    });
}
