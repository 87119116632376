import { Match, Show, Switch, createSignal, onMount } from "solid-js";
import { createPasswordResetInputs } from "./forms";
import type { MainProps } from "./_model";
import { useParams } from "@solidjs/router";
import { resetPassword, validateToken } from "./apis";
import { solidstate } from ":shared/utils/state-manager";
import { global } from ":global";
import login_logo from "../SHARED/assets/images-hackyard-demo/logo.png";
// import rectangle from "../SHARED/assets/images/auth_rectangle.png";
import OrangeSquare from "../SHARED/assets/icons/tick-square.svg";
import { Loader2 } from ":src/components/loaders";

export default function PasswordReset(props: MainProps) {
  // const [local, others] = splitProps(props, ["signinRoute"]);
  const ResetPasswordInputs = createPasswordResetInputs();
  const [verifing, setVerifing] = createSignal<boolean>(true);
  const $token_verified = solidstate.create<boolean>(false);
  const $password_changed_success = solidstate.create<boolean>(false);
  const params = useParams<{ token: string }>();

  onMount(() => {
    validateToken(params.token)
      .then((res) => {
        console.log("token valid:: ", res);
        global.store.actions.alert.pushToast({
          type: "success",
          message: "Token has been verified!",
        });
        $token_verified.set(true);
      })
      .catch((res) => {
        console.log("token invalid :: ", res);
        $token_verified.set(false);
        setTimeout(() => {
          if (props.signinRoute) {
            global.store.actions.route.navigate(props.signinRoute);
          }
        }, 3000);
      })
      .finally(() => setVerifing(false));
  });

  const onSubmit = async () => {
    const values = ResetPasswordInputs.Actions.getValuesLowercase();
    await resetPassword(params.token, values.confirm_password).then((res) => {
      console.log("Res:: ", res);
      global.store.actions.alert.pushToast({
        type: "success",
        message: "Password has been reset!",
      });
      $password_changed_success.set(true);
      // if (local.onSuccessRedirectTo !== undefined && local.onSuccessRedirectTo !== null) {
      //   global.store.actions.route.navigate(local.onSuccessRedirectTo);
      // }
    });
  };

  return (
    <section class=" bg-white w-full h-screen">
      <Switch>
        {/* Checking token */}
        <Match when={verifing()}>
          <div class="flex flex-col !font-rubik$  h-fit bg-[#FDEFEC]! text#p rounded-lg p-40px w-60% mx-auto bg-white w-full h-full">
            <Loader2 msg="Verifing token..." />
          </div>
        </Match>

        {/* Show inputs filed when verified token , else show Invalid or expired token message */}
        <Match when={!verifing()}>
          <Show
            when={$token_verified.value}
            fallback={
              <div class="flex flex-col !font-rubik$  h-fit bg-[#FDEFEC]! text#p rounded-lg p-40px w-60% mx-auto bg-white w-full h-full items-center">
                <span class="text#orange! flex$col extend$ fight$ p-10px gap-10px flex justify-center items-center">
                  Token has been expired
                </span>
                {props.signinRoute && (
                  <span class="!text-0.8rem <md:(text-0.6rem)">redirecting to login page soon</span>
                )}
              </div>
            }
          >
            <section class="w-full h-full mx-auto  flex justify-between items-center relative overflow-y-hidden ">
              <div class="w-full md:w-[40%]  flex flex-col justify-between  mx-auto gap-5 px-5 py-4 md:px-6 md:py-8 lg:py-0  ">
                <form>
                  <Show
                    fallback={
                      <div class="flex justify-start translate-x-[-1.25rem] ">
                        <img class="" width={110} src={`${login_logo}`} alt="logo" />
                      </div>
                    }
                    when={$password_changed_success.value}
                  >
                    <OrangeSquare class="md:w-50px! md:h-50px " />
                    {/* <img src={`${OrangeSquare}`} alt="reset_success_icon" /> */}
                  </Show>

                  <h1 class="text-black text-xl md:text-2xl font-bold py-2 md:py-3">
                    <Switch fallback={"Reset password"}>
                      <Match when={$password_changed_success.value}>Password changed</Match>
                    </Switch>
                  </h1>

                  <Show
                    fallback={
                      <p class="text#hint text-17px xl:max-w-[60%]! py-2">Please type something you’ll remember</p>
                    }
                    when={$password_changed_success.value}
                  >
                    <p class="text#hint text-17px xl:max-w-[60%]!">Your password has been changed succesfully</p>
                  </Show>

                  <Show
                    fallback={
                      <button
                        onclick={() => {
                          // global.store.actions.route.navigate(local.onSuccessRedirectTo);
                          global.store.actions.route.navigate("/signin");
                        }}
                        class="font-bold bg#orange w-full rounded-xl shadow-sm py-2 md:py-3 px-3 text-white text-18px outline-none border-none cursor-pointer mt-4 "
                      >
                        Back to login
                      </button>
                    }
                    when={!$password_changed_success.value}
                  >
                    <div class="w-full py-3 relative">
                      <ResetPasswordInputs.Password.Label title="New password" class="form-label">
                        <ResetPasswordInputs.Password.Error class="form-input-error" />
                      </ResetPasswordInputs.Password.Label>
                      <ResetPasswordInputs.Password.Input
                        autocomplete="current-password"
                        type="password"
                        class="form-input"
                        placeholder="must be 8 characters"
                        id="password"
                      />
                    </div>

                    <div class="w-full py-3 relative">
                      <ResetPasswordInputs.ConfirmPassword.Label title="Confirm new password" class="form-label">
                        <ResetPasswordInputs.ConfirmPassword.Error class="form-input-error" />
                      </ResetPasswordInputs.ConfirmPassword.Label>
                      <ResetPasswordInputs.ConfirmPassword.Input
                        autocomplete="current-password"
                        type="password"
                        class="form-input"
                        placeholder="repeat password"
                        id="confirm-password"
                      />
                    </div>
                    <ResetPasswordInputs.Actions.Button
                      class="form-btn text-white! flex$ min-h-2rem text-0.8rem px-2 py-3! fight$ my-24px rounded-md shadow-sm bg#orange! "
                      onclick={onSubmit}
                      statusValid={"Reset Password"}
                      statusInvalid={"Reset Password"}
                    />
                  </Show>
                </form>
              </div>
              <div class="hidden md:block h-full relative md:w-[40%]">
                <img class="object-cover w-full h-full" src={`${rectangle}`} alt="login_img" />
              </div>
            </section>
          </Show>
        </Match>
      </Switch>
    </section>
  );
}
