import { Show } from "solid-js";
import { getAppFlavor } from "./apis";
import { AppFlavorContext } from "../SHARED/store";
import type { MainProp } from "./_model";
import { solidstate } from ":shared/utils/state-manager";
import { Platform } from ":shared/components/loaders";
import { whitelabel$ } from ":public-dynamic/whitelabel";

export default function AppFlavor(props: MainProp) {
  const $flavor = solidstate.createAsync(getAppFlavor);
  $flavor.on((event, { value }) => {
    const active_vars = value.theme?.user_index || value.theme?.admin_index || 0;
    whitelabel$.CONST.THEME_VARS.set(value.theme?.schemes[active_vars] as any);

    if (props.onLoaded) {
      props.onLoaded(value);
    }
  });

  return (
    <Show
      when={!$flavor.state.loading}
      fallback={
        <Platform
          class="relative flex$col extend$ fight$"
          title={props.title ?? "Hackyard"}
          msg={props.message ?? "Loading platform..."}
        />
      }
    >
      <AppFlavorContext.Provider value={$flavor.value}>{props.children}</AppFlavorContext.Provider>
    </Show>
  );
}
