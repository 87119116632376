import { createSignal, splitProps } from "solid-js";
// import SearchIcon from "./assets/search-normal.svg";
import type { SearchToolProps } from "./_model";
export default function SearchTool(props: SearchToolProps) {
  const [value, setValue] = createSignal("");

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setValue(value);
    props.onSearch({ value });
  };

  return (
    <section class="relative flex w-full h-full  !h-2rem">
      <input
        value={value()}
        onInput={handleSearch}
        type="text"
        placeholder="Search for ..."
        class="form-input w-full  text-16px <md:(text-1.5vh) placeholder-black text-black"
      />
      {/* <SearchIcon class="h-full" /> */}
    </section>
  );
}
