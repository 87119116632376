import { Anchor, type _Navigation } from ":shared/components/route";
import { default as routes } from "../routes";
export default function Mentors(props: _Navigation.SideNavJSXItemProps) {
  return (
    <Anchor
      class={props.anchor?.class}
      setup={{
        to: routes,
        classes: {
          active: `${props.anchor.setup.classes.active}`,
        },
      }}
      components={{
        title: "Mentors",
        idleIcon: <i class="i-mynaui:user-octagon" />,
        activeIcon: <i class="i-mynaui:user-octagon" />,
      }}
    />
  );
}
