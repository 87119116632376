import { solidstate } from ":shared/utils/state-manager";
import type { EvaluationData, Team } from "../../_model";
import { Show, createEffect, createMemo, untrack } from "solid-js";
import { Button } from ":shared/components/form";

export function Grading(props: {
  data: EvaluationData;
  entity: Team;
  onSubmit: (props: { scores: Record<string, number> }) => void;
  onNext?: () => void;
  onGraded?: (props: { score: number; graded: boolean }) => void;
  class?: string;
}) {
  const c_keys = props.data?.c_keys;
  const criteria = props.data?.criteria;
  const scores_tracker = solidstate.create({} as { [k: string]: any });
  const scores = createMemo(() => props.entity?.scores);
  const graded = createMemo(() => {
    const graded = props.entity?.eval_id !== null && props.entity?.eval_id !== undefined;
    const score = scores()?.total;
    props.onGraded?.({ score, graded });
    return graded;
  });
  const scores_tracker_keys = createMemo(() => scores_tracker.value && Object.keys(scores_tracker.value));
  const btn_status = createMemo(() => (scores_tracker_keys()?.length >= c_keys.length ? "ready" : "awaiting"));

  createEffect(() => {
    // update scores_tracker
    const _scores = scores();
    const _graded = untrack(() => graded());
    scores_tracker.set((s) => {
      const f = {};
      c_keys.forEach((q) => {
        if (_graded && !f?.[q]) {
          f[q] = _scores[q];
        }
      });
      return f;
    });
  });

  function onChange(e: Event, q: string | number) {
    const value = (e.target as any).value;
    const value_as_string = String(value);
    scores_tracker.set((s) => {
      const n = { ...s };
      if (!value_as_string || value_as_string.length <= 0 || value_as_string === "ungraded") {
        delete n[q];
      } else {
        n[q] = Number(value);
      }
      return n;
    });
  }

  async function onSubmit() {
    const scores = scores_tracker.unwrap;
    console.log("on submit :: ");
    return props.onSubmit?.({ scores });
  }

  async function onSubmitAndNext() {
    return onSubmit().then(() => {
      props.onNext?.();
    });
  }

  return (
    <div class={props.class}>
      <Show
        when={c_keys && c_keys.length > 0}
        fallback={"Unfortunately, There are no evaluation criteria, contact platform support to resolve this!"}
      >
        <section class="flex flex-col w-full h-full space-y-2 !overflow-y-auto overflow-x-hidden  bg#page text#pt rounded-xl text-0.75rem px-2">
          {c_keys.map((q, i) => {
            const c = criteria[q];
            const name = c?.name;
            // const options_width = solidstate.create("1rem");
            const datalist: { value: number; label: string }[] = [];
            for (let f = c.min; f <= c.max; f++) {
              datalist.push({ value: f, label: String(f) });
            }
            return (
              <div class="flex flex-col w-full h-full px-3 py2 space-y-2 bg#skeleton text-black text-0.65rem rounded-xl">
                <span class="flex flex-row w-full h-fit capitalize font-bold">{String(name)}</span>
                <div class="flex flex-row w-full space-x-2">
                  <input
                    type="range"
                    min={c.min}
                    max={c.max}
                    step={1}
                    value={scores_tracker.value?.[q] ?? 0}
                    // oninput={(e) => console.log("oninout ", e.target.value)}
                    oninput={(e) => {
                      e.preventDefault();
                      onChange(e, q);
                    }}
                    class="w-full !h-0 accent#a py-2 outline-none ring-0 border-0 cursor-pointer"
                    // list={`list.${i}`}
                  />
                  <Show
                    when={scores_tracker.value?.[q] !== undefined && scores_tracker.value?.[q] !== null}
                    fallback={<span class="w-fit">ungraded</span>}
                  >
                    <span class="min-w-1rem">{scores_tracker.value?.[q]}</span>
                  </Show>
                </div>

                <div class="flex flex-row w-full h-fit items-end space-x-2 justify-between children:(capitalize)">
                  <div class="space-x-2 ">
                    <span class="text#p opacity-55">Previous Grade</span>
                    <span class="text#p !capitalize">{!scores() ? "Unevaluated" : scores()[q]}</span>
                  </div>
                  <div class="space-x-2">
                    <span class="text#p opacity-55">Min</span>
                    <span class="text#p">{c.min}</span>
                    <span class="text#p opacity-55">Max</span>
                    <span class="text#p">{c.max}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
        {graded() && (
          <div class="flex flex-row w-full h-fit justify-between text-0.8rem px-2">
            <p class="text#paget">Grade</p>
            <p class="bg#a text#page px2 rounded-1">{scores()?.total}</p>
          </div>
        )}
        <div class="flex flex-row w-full h-fit space-x-2">
          <Button
            controls={[]}
            onSubmit={onSubmit}
            class="w-full h-fit !text-0.65rem !py-2 !px-3  justify-center items-center  rounded-lg bg#a text#pt font-semibold cursor-pointer disabled:(opacity-50 cursor-not-allowed)"
            triggers={{
              manualStatus: btn_status() === "ready" ? "valid" : "invalid",
            }}
            statusInvalid={"Finish Grading"}
            statusValid={graded() ? "Update" : "Submit"}
          />
          <Button
            controls={[]}
            onSubmit={onSubmitAndNext}
            class="w-full h-fit !text-0.65rem !py-2 !px-3  justify-center items-center  rounded-lg bg#a text#pt font-semibold cursor-pointer disabled:(opacity-50 cursor-not-allowed)"
            // triggers={{
            //   manualStatus: btn_status() === "ready" ? "valid" : "invalid",
            // }}
            events={{
              onSetButtonEnabledState(enable_button, status) {
                return btn_status() === "ready" ? true : false;
              },
            }}
            statusInvalid={"Finish Grading"}
            statusValid={graded() ? "Update And Next" : "Submit And Next"}
          />
        </div>
      </Show>
    </div>
  );
}

// {/* <Show
// when={scores_tracker.value?.[q] !== undefined && scores_tracker.value?.[q] !== null}
// fallback="unevaluated"
// >
// <datalist
//   id={`list.${i}`}
//   // class="flex flex-row  text-0.5rem w-full !h-2 justify-between !my-0"
// >
//   <For each={datalist}>
//     {(d, index) => {
//       const show_label =
//         d.value <= c.min || d.value >= c.max ? true : d.value % (c.min + 2) === 0 ? true : false;
//       console.log("show label :: ", show_label);
//       return (
//         <option
//           // style={{ width: "1rem" }}
//           value={d.value}
//           label={show_label ? d.label : undefined}
//         >
//           {d.value}
//         </option>
//       );
//     }}
//   </For>
// </datalist>
// </Show> */}
