import { AxiosError } from "axios";
import type { Props } from "./_model";

export default function Response(props: Props) {
  const { instance, options, cycles, helpers, _coh } = props;
  instance.interceptors.response.use(
    async (res) => {
      try {
        const res_obj = options?.processResponds?.({ res });
        // @ts-ignore
        if (res_obj !== undefined && res_obj !== null && "data" in res_obj) {
          // ! i still think res_obj should be added to a special key defined in defaults
          res = {
            ...res,
            ...res_obj,
          };
        }
        // const config = res.config;
        const auth_res = await cycles?.auth?.response?.({ res, helpers });

        if (auth_res != undefined) {
          if (auth_res.then) {
            return auth_res;
          }

          res = auth_res;
        }
      } catch (e: any) {
        console.error(e);
        if (typeof e === "string") {
          throw new AxiosError(e);
        } else if ("isAxiosError" in e) {
          throw e;
        } else {
          throw new AxiosError("Qunxios: unknown response error!!");
        }
      }
      return Promise.resolve(res);
    },
    // !!!!!
    async (err: AxiosError) => {
      try {
        const err_obj = options?.processRespondsErrors?.({ err });
        const config = _coh.appendResponseError({ config: err?.config, obj: err_obj });
        err.config = config;

        //
        const auth_err = await cycles?.auth?.responseError?.({ err, helpers });
        // console.log("from interceptor response error :: ", err);

        if (auth_err != undefined) {
          if (auth_err.then) {
            return auth_err;
          }
          err = auth_err;
        }
      } catch (e: any) {
        console.error(e);

        if (typeof e === "string") {
          throw new AxiosError(e);
        } else if ("isAxiosError" in e) {
          throw e;
        } else {
          throw new AxiosError("Qunxios: unknown response_err error!!");
        }
      }
      // console.log("from interceptor response confirmed error :: ", err);

      if (!err.isAxiosError) {
        return Promise.resolve(err);
      }
      return Promise.reject(err);
    }
  );
}
