import { global } from ":global";
import { ENDPOINTS } from "./endpoints";
import type { UpdatePasswordProps, UpdateProfileImageProps, UpdateProfileProps } from "../_model";
// TODO : add data type 
export async function update_user(data ) {
  return global.api
    .putAuth(ENDPOINTS.update_user, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function update_profile_image(data) {
  return global.api
    .putAuth(ENDPOINTS.update_user, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}


export async function update_password(data: UpdatePasswordProps) {
  return global.api
    .putAuth(ENDPOINTS.update_password, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}