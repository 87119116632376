import { createFormControl } from "solid-forms";
import { Error } from "../error";
import { Label } from "../label";
import Input from "./main";
import type { ICollectionInput } from "./_model";
import type { CreateFormControlProps, FormControlData, FormControlValue } from "../SHARED/_model";

export default function <V extends FormControlValue, D extends FormControlData = FormControlData>(
  ...args: CreateFormControlProps<V, D>
): ICollectionInput<V, D> {
  const control = createFormControl<V, D>(...args);
  const name = String(control.id);
  return {
    control,
    name,
    Label: (props) => <Label control={control} for={name} {...props} />,
    Error: (props) => <Error control={control} {...props} />,
    Input: (props) => <Input control={control} name={name} dir="auto" {...props} />,
  };
}
