import { global } from ":global";

export interface Get {
  props: never;
  returns: any;
}

export default {
  endpoint: "/teaming/leave-team",
  async get(): Promise<Get["returns"]> {
    return global.api.getAuth(`/teaming/leave-team/`).then((res) => {
      return res.data;
    });
  },
};
