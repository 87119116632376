import { solidstate } from ":shared/utils/state-manager";
import { getEvaluations } from "./apis/get-evaluations";
import { global } from ":global";
import { createMemo, Match, Switch } from "solid-js";

import { Loader2 } from ":src/components/loaders";
import { SubmissionsTable } from "./components";

import { EvaluationModal } from "./components";
import { updateEvaluation } from "./apis/update-evaluation";

export default function () {
  const jwt = global.api.auth.token.decoded();
  const is_judge = jwt.user_key !== "evaluator";
  const $details = solidstate.createAsync(getEvaluations);
  const $filter = solidstate.create(undefined as "graded" | "ungraded");
  const $search = solidstate.create(undefined as string);
  const counts = createMemo(() => {
    const history_length = $details.value?.history?.length ?? 0;
    const teams_length = $details.value?.teams?.length ?? 0;

    return {
      total: teams_length,
      graded: history_length,
      ungraded: teams_length - history_length,
    };
  });

  return (
    <section class="flex flex-col w-full h-full px-2 py-2 ">
      <h1>{is_judge ? "Judging" : "Evaluation"}</h1>
      <div class="flex flex-row   min-w-full justify-between py-2 md:(space-x-2) <md:(!flex-col flex-wrap space-y-2) ">
        {/* search */}
        <input
          class="form-input flex-1  caret-orange cursor-text text-1rem bg#bg-default text-black "
          placeholder="search by team name..."
          type="search"
          oninput={(e) => {
            const value = e.currentTarget.value?.toLowerCase();
            console.log("search ", $search.value);
          }}
        />
        {/*filters */}
        <div
          class={`
          flex flex-row my-2 gap-x-2
          children:(flex flex-row flex-1 h-6vh items-center rounded-md px-8 justify-between gap-x-2 text#pt whitespace-nowrap cursor-pointer )
          <lg:(flex-wrap gap-y-2)
        `}
        >
          <p class="bg#success  " onclick={() => $filter.set("graded")}>
            <span>Graded</span>
            <span class="flex flex-row w-2rem h-1rem items-center justify-center  rounded-full bg-white text#success text-0.7rem ">
              {counts().graded}
            </span>
          </p>
          <p class="bg#a " onclick={() => $filter.set("ungraded")}>
            <span>Not Graded </span>
            <span class="flex flex-row w-2rem h-1rem items-center justify-center  rounded-full bg-white text#a text-0.7rem ">
              {counts().ungraded}
            </span>
          </p>
          <p class="bg-black " onclick={() => $filter.set(undefined)}>
            <span>Total</span>
            <span class="flex flex-row w-2rem h-1rem items-center justify-center  rounded-full bg-white text-black text-0.7rem ">
              {counts().total}
            </span>
          </p>
        </div>
      </div>
      <Switch>
        <Match when={$details.state.loading}>
          <Loader2 msg="Loading Support Requests..." />
        </Match>
        <Match when={$details.state.failed}>error occured while loading memebres {$details.error}</Match>
        <Match when={$details.state.success}>
          <SubmissionsTable
            details={$details.value}
            filter={{ search: $search.value, filter: $filter.value }}
            onEvaluate={({ item, itemIndex }) => {
              global.store.actions.popop.pushModal({
                title: "Evaluate Team",
                startExpanded: true,
                element(action) {
                  return (
                    <EvaluationModal
                      data={$details as any}
                      idx={itemIndex()}
                      onSubmit={({ team, scores }) => {
                        return updateEvaluation({ team, scores });
                      }}
                    />
                  );
                },
              });
            }}
          />
        </Match>
      </Switch>
    </section>
  );
}
