import { solidstate } from ":shared/utils/state-manager";
import type { ScreenResizeEventReport } from "./_model";

// TODO: change screen caculations to depend on window.matchMedia
// TODO: replace solidstate with nanostores for a framework agnostic approach
export default function createOnBreakpointHook<T extends Record<string, number | string>>(breakpoints: T) {
  const $state = solidstate.create(undefined as ScreenResizeEventReport<T>);
  const sorted: Record<keyof T, { width: number; order: number; name: string }> = {} as any;
  let highest_breakpoint: (typeof sorted)[string] = undefined;
  if (typeof breakpoints !== "object") {
    console.warn("breakpoints have to be an object of keys and values!");
    return undefined;
  }
  const _breakpoints = { ...breakpoints } as Record<string, number>;
  // forcing all keys to be of type number and raising an error when not.
  for (const key in _breakpoints) {
    let value = _breakpoints[key] as any;
    if (typeof value === "string") {
      try {
        value = parseInt(value);
        if (Number.isNaN(value)) {
          throw "value did not start with a number!!";
        }
        _breakpoints[key] = value;
      } catch (e: any) {
        console.error(
          `BreakpointHook: couldn't parse int for key ${key} with value ${_breakpoints[key]}, removed key!!`
        );
        delete _breakpoints[key];
      }
    }
  }
  Object.entries(_breakpoints)
    ?.sort(([, a], [, b]) => a - b)
    .forEach(([k, v], index) => {
      // console.log("k is :: ", k, " :: ", v);
      // @ts-ignore
      sorted[k] = { width: v, order: index, name: k };
      highest_breakpoint = sorted[k];
    });
  // console.log("sorted :: ", sorted);
  function onWindowResize<T extends Record<string, number>>(props: { e?: UIEvent }) {
    // const { e, breakpoints } = props;
    const w = window.innerWidth;
    // console.log("-------------------------");
    // console.log("-------------------------");
    // console.log("-------------------------");
    // console.log("-------------------------");
    // console.log("window width :: ", w);
    const result: ScreenResizeEventReport<T> = {
      active: undefined,
      currentWidth: w,
      shown: {} as any,
      hidden: {} as any,
      misc: {} as any,
    };
    for (const bp in sorted) {
      const bp_width = sorted[bp].width;
      const ratio = w / bp_width;
      const in_range = 1 - ratio;
      const out_range = ratio - 1;
      const active_range = out_range >= 0 && out_range < 1;
      const shown = in_range > 0; // >= 0 means at 640 for example sm would show, i choose not to do that
      const hidden = out_range >= 0;
      result.misc[bp] = {
        ratio,
        outRange: out_range,
        isActive: active_range,
        shouldShow: shown,
        shouldHide: hidden,
        widthIsLess: bp_width / w > 1,
        widthIsGreaterOrEquals: bp_width / w <= 1,
      };
      if (shown) {
        result.shown[bp] = true;
      }
      if (hidden) {
        result.hidden[bp] = true;
      }
      if (!result.active && result.misc[bp].widthIsLess) {
        result.active = bp;
      }
    }
    if (!result.active) {
      result.active = highest_breakpoint.name;
    }
    // console.log("result :: ", result);
    const old_active_bp = $state.unwrap?.active;
    if (!old_active_bp) {
      $state.set(result as any);
    } else {
      if (result.active !== old_active_bp) {
        $state.set(result as any);
      }
    }
  }
  let inited = false;

  return {
    get sorted() {
      return sorted;
    },
    get highestBreakpoint() {
      return highest_breakpoint;
    },
    useBreakpoints: () => {
      if (!inited) {
        onWindowResize({});
        window.addEventListener("resize", (e) => onWindowResize({ e }));
        inited = true;
      }
      return $state.accessor;
    },
    useDedicatedBreakpoints: <G extends (keyof T)[]>(...bps: G) => {
      if (!inited) {
        onWindowResize({});
        window.addEventListener("resize", (e) => onWindowResize({ e }));
        inited = true;
      }
      let highest_sorted: (typeof sorted)[string] & { name: string } = undefined;
      for (const k of bps) {
        if (!highest_sorted) {
          highest_sorted = { ...sorted[k], name: k };
        } else {
          const order = sorted[k].order;
          if (order > highest_sorted.order) {
            highest_sorted = { ...sorted[k], name: k };
          }
        }
      }
      return (...bpa: G[number][]) => {
        const v = $state.accessor();
        if (!bpa || bpa.length <= 0) {
          return true;
        }
        let activate = false;
        for (const bp of bpa) {
          if (v.misc[bp].widthIsLess && bp === v.active) {
            activate = true;
            // console.log("bp :: ", bp, " activated ");
            break;
          } else if (bp === highest_sorted.name && v.misc[bp].widthIsGreaterOrEquals) {
            activate = true;
            // console.log("bp :: ", bp, " activated ");
            break;
          }
        }
        return activate;
      };
    },
  };
}
