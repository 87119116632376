import type { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import type { __ConfigObjectHandler } from "../_model";

export function checkAuthConfig(props: {
  config?: AxiosRequestConfig | InternalAxiosRequestConfig;
  _coh: __ConfigObjectHandler;
}) {
  let { config, _coh } = props;
  if (config == undefined) config = {};
  config = _coh.appendKey({ config: config as any, key: _coh.keys.auth, obj: {} });
  return config;
}
