import type { AxiosError } from "axios";

export function decodeError(err: AxiosError | string, instead?: string) {
  if (instead && instead.length >= 3) instead += "*";
  if (typeof err === "string") {
    if (err.length <= 0) {
      return instead;
    } else {
      return err;
    }
  }
  instead = err.message ?? instead;
  const rec_err = err?.response?.data as any as any;
  if (rec_err) {
    const { detail, details, error, errors, password, email } = rec_err;
    let another_one = undefined;
    if (details && typeof details === "object") {
      Object.entries(details).every(([k, v]) => {
        another_one = `${String(k)}: ${String(v)}`;
        return false; // just break on first value
      });
    }
    const final_error_msg = another_one || detail || details || error || errors || password || email || instead;
    return typeof final_error_msg === typeof [] ? final_error_msg[0] || instead : final_error_msg || instead;
  }
  return instead;
}
