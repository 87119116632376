import person_img from "../assets/images/person_placeholder.png";
//
import { createEffect, createMemo, on, Show } from "solid-js";
import { updateProfileInputs } from "../forms";
import { solidstate } from ":shared/utils/state-manager";
import { update_user, update_profile_image } from "../apis";
import { PrimaryButton } from ":src/components/form";
import { global } from ":global";

export default function DetailsForm(props: { details: any }) {
  const Form = updateProfileInputs();
  Form.Email.control.markDisabled(true);
  // FormInputs.Track.control.markDisabled(true);
  const tracks = global.store.actions.platform.getTracks();
  const char_limit = 300;
  //
  const $loading_image_update = solidstate.create(false);
  //
  const $BioChars = createMemo(() => calculateCharCount(Form.Bio));

  createEffect(
    on(
      () => props.details,
      (data) => {
        console.log("updated :: ", data);
        Form.Actions.update(data);
      }
    )
  );

  function calculateCharCount(inputField: typeof Form.Bio) {
    const user_chars = inputField.control.value as string;
    return `${!user_chars ? 0 : user_chars.length}/${char_limit}`;
  }

  async function onUploadProfileImage() {
    if (!!Form.Image.control.value) {
      $loading_image_update.set(true);
      const formData = new FormData();
      const file = Form.Image.control.value[0];
      formData.append("image", file);
      try {
        await update_profile_image(formData);
        global.store.actions.alert.pushToast({
          type: "success",
          message: "Profile Image Updated!",
        });
        $loading_image_update.set(false);
      } catch (error: any) {
        $loading_image_update.set(false);
      }
    } else {
      global.store.actions.alert.pushToast({
        type: "info",
        message: "Please click on the image and upload image first!",
      });
    }
  }

  async function onSubmit(e: Event) {
    e.preventDefault();
    const values = Form.Actions.getValuesSnakecase();

    const formData = new FormData();

    for (const v in values) {
      if (v !== "image") {
        formData.append(v, values[v]);
      } else if (Form.Image?.control?.value?.[0] !== undefined && Form.Image?.control?.value?.[0] !== null) {
        formData.append("image", Form.Image?.control?.value?.[0]);
      }
    }

    return update_user(formData).then(() => {
      global.store.actions.alert.pushToast({
        type: "success",
        message: "Profile Updated!",
      });
    });
  }

  return (
    <form class="flex flex-col w-full h-fit overflow-auto">
      {/* image */}
      <section class="flex flex-row w-full h-full my-5 items-center gap-x-3 !<md:(flex-col my-3) ">
        <Form.Image.InputFile
          accept="image/*"
          classes={{
            container: "min-w-100px min-h-100px rounded-full border-dashed border-2 border-red",
            input: "bg#orange! text-white opacity-60",
          }}
          elements={{
            input(props) {
              return (
                <div
                  class="flex flex-col justify-center items-center bg#orange! text-white w-100px h-100px absolute top-0 opacity-60 cursor-pointer"
                  onclick={props.onInputClicked}
                >
                  <span class="text-16px">{props.drag ? "Drop Files" : "Edit"}</span>
                </div>
              );
            },
            input_text: ({ drag, file }) =>
              !file ? (!drag ? "Edit" : "drop files") : drag ? "update file" : file.name,
            viewer({ placeholder }) {
              console.log("placeholder:: ", placeholder);
              return (
                <Show fallback={<img src={person_img} class="w-full h-full object-cover " />} when={placeholder}>
                  <img src={placeholder} class="w-full h-full object-cover " />
                </Show>
              );
            },
          }}
        />

        <div class="flex flex-col w-full h-full gap-1 !<md:(items-center text-center)">
          <p class="text#txtgray py-1 text-14px ">Recommended 500 x 500 px. JPG or PNG is allowed</p>
          <PrimaryButton
            label={$loading_image_update.value ? "Updating Avatar..." : "Update Avatar"}
            onClick={onUploadProfileImage}
            classes="w-fit px-4! py-[16px]! text-[14px]!"
            disabled={$loading_image_update.value}
          />
        </div>
      </section>
      <section class="grid grid-cols-[49%_49%] gap-x-2 gap-y-2 w-full h-full <md:(grid-cols-1 !gap-x-0) children:(flex flex-col  w-full h-full)">
        {/* first name */}
        <div>
          <Form.FirstName.Label title="First Name" class="form-label ">
            <Form.FirstName.Error class="form-input-error" />
          </Form.FirstName.Label>

          <Form.FirstName.Input autocomplete="First Name" type="text" class="form-input" placeholder={`First Name`} />
        </div>
        {/* last name */}
        <div>
          <Form.LastName.Label title="Last Name" class="form-label">
            <Form.LastName.Error class="form-input-error " />
          </Form.LastName.Label>

          <Form.LastName.Input autocomplete="LastName" type="text" class="form-input" placeholder={`Last Name`} />
        </div>
        {/* email */}
        <div>
          <Form.Email.Label title="Email" class="form-label">
            <Form.Email.Error class="form-input-error" />
          </Form.Email.Label>

          <Form.Email.Input autocomplete="Email" class="form-input" />
        </div>
        {/* track */}
        <div>
          <Form.Track.Label title="Track" class="form-label">
            <Form.Track.Error class="form-input-error" />
          </Form.Track.Label>

          <Form.Track.Select
            class="form-select"
            options={tracks.values}
            onNoOptions={() => {
              console.warn("no track options to select from");
              global.store.actions.alert.pushToast({
                type: "error",
                message: "Tracks list was not provided by admin!",
              });
            }}
          />
        </div>
        {/* first name */}
        <div>
          <Form.Portfolio.Label title="Portfolio Link" class="form-label">
            <Form.Portfolio.Error class="form-input-error" />
          </Form.Portfolio.Label>

          <Form.Portfolio.Input
            autocomplete="Portfolio Link"
            type="text"
            class="form-input"
            placeholder={`https://...`}
          />
        </div>
        {/* major */}
        <div>
          <Form.Major.Label title="Job Title" class="form-label">
            <Form.Major.Error class="form-input-error " />
          </Form.Major.Label>

          <Form.Major.Input class="form-input" />
        </div>
        {/* bio */}
        <div class="w-full py-2 md:col-span-2">
          <Form.Bio.Label title="Bio" class="form-label">
            <Form.Bio.Error class="form-input-error" />
          </Form.Bio.Label>

          <div class="relative ">
            <Form.Bio.TextArea
              autocomplete="Bio"
              class="form-textarea"
              placeholder="Write a brief about yourself"
              maxlength={char_limit}
            />
            <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">{$BioChars()}</div>
          </div>
        </div>
      </section>
      <Form.Actions.Button
        class="form-btn self-center !md:(w-40% self-end)"
        onclick={onSubmit}
        statusValid="Save Changes"
        statusInvalid="Save Changes"
      />
    </form>
  );
}
