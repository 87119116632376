import { Guarded } from ":shared/components/route";
import routes from "./routes";
import authRoutes from "../auth/routes";

export default function Landing() {
  const baseRoute = routes.BASE;
  return (
    <Guarded
      setup={{
        baseRoute,
      }}
      components={{
        layout: (props) => {
          return null;
        },
        pageNotFound: () => <div>this is landing page error, this page doesn't exist</div>,
      }}
      events={{
        onRouteFirstLoad(trigger, props) {
          trigger.navigate({ base: authRoutes.BASE });
        },
      }}
    ></Guarded>
  );
}
