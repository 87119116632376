import { For, Show, createSignal, splitProps } from "solid-js";
import ArrowIcon from "./assets/arrow-down.svg";
import type { DropDownProps } from "./_model";

export default function Dropdown(props: DropDownProps) {
  const [local, others] = splitProps(props, ["label", "options"]);
  const [dropMenu, setDropMenu] = createSignal(false);
  const [dropMenuValue, setDropMenuValue] = createSignal("");

  const toggleDropMenu = () => {
    setDropMenu(!dropMenu());
  };

  const handleChoosenValue = (id: number) => {
    const choosenValue = local.options.find((option) => option.id === id).value;
    setDropMenuValue(choosenValue);
    setDropMenu(false);
    others.onValueChoose(choosenValue);
  };
  const getAll = () => {
    others.onValueChoose("");
    setDropMenuValue("");
    setDropMenu(false);
  };

  return (
    <div class="w-full relative text-16px <md:(text-1.5vh)">
      <div
        onClick={toggleDropMenu}
        class="w-full relative rounded-sm border#gray border-solid border-1 flex justify-between items-center px-2 py-1vh cursor-pointer"
      >
        <label
          // type="text"
          // value={}
          class=" text-black w-full cursor-pointer h-fit max-h-fit whitespace-nowrap "
        >
          {dropMenuValue() ? dropMenuValue() : local.label}
        </label>
        <ArrowIcon class={`w-20px text-end duration-300 absolute right-1 ${dropMenu() ? "rotate-180" : ""}`} />
        {/* <img  src={arrow_icon} class={`text-20px text-end duration-300 ${dropMenu()? 'rotate-180': ''}`}/>  */}
      </div>
      <Show when={dropMenu()}>
        <div class="w-full absolute top-100% left-0 z-50 bg-white rounded-sm border#gray border-solid border-2 text-black overflow-hidden">
          <p
            onClick={getAll}
            class="w-full px-2 py-2 hover:bg#gray cursor-pointer text-[14px] list-none bg#a text-white"
          >
            Clear Filter
          </p>
          <For each={local.options}>
            {(option, i) => (
              <p
                onClick={() => handleChoosenValue(option.id)}
                class="w-full px-2 py-1vh hover:bg#gray cursor-pointer list-none capitalize overflow-x-auto scrollbar"
              >
                {option.value}
              </p>
            )}
          </For>
        </div>
      </Show>
    </div>
  );
}
