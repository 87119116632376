export type * as _SolidState from "./_model";
import { default as create } from "./create";
import { default as createAsync } from "./create-async";
import * as helpers from "./helpers";

export const solidstate = {
  create,
  createAsync,
  helpers,
};
