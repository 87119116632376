import { global } from ":global";
import { ENDPOINTS } from "./enpoints";
// TODO : add data type 
export async function send_request(data:string) {
  return global.api
    .postAuth(ENDPOINTS.request, data, {
      // TODO : add proper type 
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((res) => {
      console.log("res :: ", res);
      const res_data = res.response.data;
      const errors = res_data?.error;
      return Promise.reject("an error occured");
    });
}