import { global } from ":global";
export type GET = {
  returns: {
    team: {
      team_name: string;
      project_name: string;
      track: string;
      problem_statement: string;
      research_process: string;
      ideation: string;
      applicable_solution: string;
      feasibility: string;
      impact: string;
      business_model: string;
      project_cover: unknown;
      project_images: string;
      project_video: string;
      presentation: string;
    };
    members: {
      id: number;
      first_name: string;
      last_name: string;
      email: string;
    }[];
    invitations: unknown[];
    image: string;
    presentation: string;
  };
};
export const endpoint = "teaming/team/";

export async function get(): Promise<GET["returns"]> {
  return global.api
    .getAuth<GET["returns"]>(endpoint)
    .then((res) => {
      const data = res.data;
      data.presentation = cleanupLinks({ value: data.presentation });
      data.image = cleanupLinks({ value: data.image });
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err.message);
    });
}

function cleanupLinks(props: { value: string }) {
  const { value } = props;
  if (value == undefined) {
    return value;
  }
  if (typeof value !== "string") {
    console.warn("api/teaming/team: link is a string");
    return undefined;
  }
  const slash_index = value.lastIndexOf("/");
  const result = value.substring(slash_index + 1);
  return result;
}
