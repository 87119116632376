import { global, type _Global } from ":global";
import type { AParticipant } from "../_model";

export interface Get {
  props: never;
  returns: {
    role: "leader" | "member";
    team_members: AParticipant<{ role: "leader" | "member" }>[];
    received_requests: AParticipant<{ status: "pending" | "accepted" | "rejected"; type: string }>[];
    sent_requests: AParticipant<{ team: string | null }>[];
    team_info: {
      id: number;
      name: string;
      track: string;
      created_at: string;
    };
  };
}

const endpoint = "teaming/user-requests/";
export async function GET(): Promise<Get["returns"]> {
  // return Promise.resolve(
  //   import("./mock.user-requests").then((e) => {
  //     return Promise.resolve(e.success);
  //   })
  // );

  return global.api.getAuth(endpoint).then((res) => {
    return res.data;
  });
}
