import participant from ":src/routes/participant/routes";
import evaluator from ":src/routes/evaluator/routes";
import judge from ":src/routes/judge/routes";
import auditor from ":src/routes/auditor/routes";
import support from ":src/routes/support/routes";
import auth from ":src/routes/auth/routes";

// add routes on a need to use bases, not all routes should be added at first
export const ROUTES = {
  auth,
};

export const TOKEN_TYPE = {
  // admin: { id: 1, base_route: ROUTE_BASES.admin },
  participant: { id: 2, base_route: participant.BASE },
  filterer: { id: 3, base_route: undefined },
  mentor: { id: 4, base_route: undefined },
  judge: { id: 5, base_route: judge.BASE },
  evaluator: { id: 6, base_route: evaluator.BASE },
  auditor: { id: 7, base_route: auditor.BASE },
  support: { id: 8, base_route: support.BASE },
} as const;

// # User Types -----------
//     ADMIN=1
//     PARTICIPANT=2
//     FILTERER=3
//     MENTOR=4
//     JUDGE=5
//     EVALUATOR=6
//     AUDITOR=7
//     SUPPORT=8
