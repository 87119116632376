import type { _Api } from "../../SHARED/_model";
import { createTeamInputs } from "../form";
import { create_team } from "../apis";
import { global } from ":global";

export type Props = {
  details: _Api._UserRequests.Get["returns"];
  onCreate: (props: _Api._UserRequests.Get["returns"]) => void;
};

export function CreateTeamForm(props: Props) {
  const jwt = global.api.auth.token.decoded();
  const Form = createTeamInputs({ team_name: jwt.first_name });
  async function onSubmit() {
    const data = Form.Actions.getValuesSnakecase();
    await create_team.post({ team_name: data.team_name }).then((data) => {
      global.store.actions.alert.pushToast({
        type: "success",
        message: "team has been created, good luck!",
      });
      props.onCreate?.(data);
    });
  }
  return (
    <form class="flex flex-col w-full h-full justify-center">
      <Form.TeamName.Label title="Team Name" class="form-label">
        <Form.TeamName.Error class="form-input-error" />
      </Form.TeamName.Label>
      <Form.TeamName.Input
        autocomplete="team_name"
        type="text"
        class="form-input"
        placeholder={"Enter your team name"}
      />
      <Form.Actions.Button
        class="form-btn flex flex-row min-h-2rem text-0.8rem px-2 py-1 items-center justify-center my-24px rounded-md shadow-sm bg#orange! text-white!"
        onclick={onSubmit}
        statusValid={"Create Team"}
        statusInvalid={"Must Enter Team Name!"}
        // onlyStatus={["valid", "submit"]}
      />
      <p class="w-full text-black text-0.8em ">
        you currently do not have a team, please create one to start inviting individuals or you can go the teams page
        and join other teams.
      </p>
    </form>
  );
}
