import { createPasswordResetInputs } from "../forms";
import { update_password } from "../apis";
import { global } from ":global";

export default function PasswordForm(props: { details: any }) {
  const ResetPasswordInputs = createPasswordResetInputs();

  async function onPasswordUpdate(e: Event) {
    e.preventDefault();
    const values = ResetPasswordInputs.Actions.getValuesSnakecase();
    const payload = {
      // password: "!!@@",
      password: values["password"],
    };

    return update_password(payload).then(() => {
      global.store.actions.alert.pushToast({
        type: "success",
        message: "Password Updated!",
      });
    });
  }
  return (
    <form class="flex flex-col w-full h-fit overflow-auto">
      <h1 class="text-xl font-600 text-black pb-2 <md:(text-20px)">Reset Password</h1>
      <section class="grid grid-cols-[49%_49%] gap-x-2 gap-y-2 w-full h-full <md:(grid-cols-1 !gap-x-0) children:(flex flex-col  w-full h-full)">
        <div>
          <ResetPasswordInputs.Password.Label title="New Password" class="form-label">
            <ResetPasswordInputs.Password.Error class="form-input-error" />
          </ResetPasswordInputs.Password.Label>

          <ResetPasswordInputs.Password.Input
            autocomplete="New Password"
            type="password"
            class="form-input"
            placeholder={`New Password`}
          />
        </div>
        <div>
          <ResetPasswordInputs.ConfirmPassword.Label title="Confirm Password" class="form-label">
            <ResetPasswordInputs.ConfirmPassword.Error class="form-input-error" />
          </ResetPasswordInputs.ConfirmPassword.Label>

          <ResetPasswordInputs.ConfirmPassword.Input
            autocomplete="Confirm New Password"
            type="password"
            class="form-input"
            placeholder={`Confirm New Password`}
          />
        </div>
      </section>

      <ResetPasswordInputs.Actions.Button
        class="form-btn self-center !md:(w-40% self-end) "
        onclick={onPasswordUpdate}
        statusValid="Update Password"
        statusInvalid="Update Password"
        statusSubmit="Updating..."
      />
    </form>
  );
}
