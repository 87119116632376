// import {
//   LineChart,
//   Line,
//   CartesianGrid,
//   XAxis,
//   YAxis,
//   ResponsiveContainer,
//   Tooltip,
// } from "recharts";
import { SolidApexCharts } from "solid-apexcharts";
import type { ASingleStat } from "../_model";
import { createMemo, createSignal } from "solid-js";
import { Title } from "./title";

export function Chart(props: ASingleStat<{ x: number; y: number }[]>) {
  // const data[] = useState([...props.data]);
  // const $ticks = createMemo(() => props.data.map((item) => item.y).sort((a, b) => a - b));
  const $series = createMemo(() => {
    const series: ApexAxisChartSeries[number] = {} as any;
    series.name = "Participations on this day";
    series.data = [];
    props.data.forEach(({ x, y }) => {
      // @ts-ignore
      series.data.push({ x, y });
    });
    return series;
  });

  return (
    <div class="!min-w-full  !min-h-full">
      {/* title  */}
      <div class="flex flex-row w-full h-fit justify-between items-center">
        <Title title={props?.title} />
      </div>
      <SolidApexCharts
        //
        width="100%"
        height="100%"
        type="line"
        series={[$series()]}
        options={{
          responsive: [
            {
              breakpoint: 640,
              options: {
                chart: {
                  type: "bar",
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                  },
                },
                xaxis: {
                  title: {
                    text: "Number Of Participants",
                  },
                },
                yaxis: {
                  showAlways: false,
                },
                stroke: {
                  curve: "smooth",
                  dashArray: 0,
                  colors: ["blue"],
                },
                dataLabels: {
                  textAnchor: "start",
                  style: {
                    colors: ["black"],
                  },
                },
              },
            },
          ],
          xaxis: {
            type: "numeric",
            tickPlacement: "between",
            title: {
              text: "Days",
            },
          },
          yaxis: {
            showAlways: false,
            title: {
              text: "Number Of Participants",
            },
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: "smooth",
            dashArray: 10,
            colors: ["#FF718B"],
          },
          chart: {
            zoom: {
              enabled: true,
            },
          },
        }}
      />
    </div>
  );
}
