import type { PrimaryButtonProps } from "./_model";

export default function PrimaryButton(props:PrimaryButtonProps){



  return (
    <button onClick={props.onClick} class={` ${props.disabled ? 'bg#disabled cursor-not-allowed' : 'bg#orange cursor-pointer'} w-fit text-white  px-4 py-3 rounded-md font-bold flex justify-center items-center gap-0 ${props?.classes ?? ""}`}>
    {props.label}
    </button>
  )
}