import { Match, Show, Switch, createEffect, createMemo, untrack } from "solid-js";
import { createProjectSubmissionInputs } from "./form";
import { submission_status, team, update_team } from "./apis";
import { solidstate } from ":shared/utils/state-manager";
import { global } from ":global";
import { PrimaryButton } from ":src/components/form";
import { Loader2 } from ":src/components/loaders";
// import { downloadFile } from ":shared/helpers/methods";

export default function ProjectSubmission(props: { onRouteToCreateTeam: () => void }) {
  // $image_max().values?.value is image size maximum in MB
  const $image_max = global.store.hooks.platform.useSettings("image_max");
  const $pdf_max = global.store.hooks.platform.useSettings("pdf_max");
  const settings = global.store.actions.platform.getProjectSubmission();
  const tracks = global.store.actions.platform.getTracks();

  const FormInputs = createProjectSubmissionInputs({
    image_max: $image_max().values?.value,
    pdf_max: $pdf_max().values?.value,
  });
  const $submission_status = solidstate.createAsync(submission_status.get);
  const $submission_details = solidstate.createAsync(team.get);
  const $can_update = solidstate.create(false);
  const $loading = solidstate.create(false);
  // FormInputs.Track?.control?.markDisabled(true);
  const char_limit = 500;
  const calculateCharCount = (inputField) => {
    const user_chars = inputField.control.value;
    return `${!user_chars ? 0 : user_chars.length}/${char_limit}`;
  };

  const $ProblemStatementChars = createMemo(() => calculateCharCount(FormInputs.ProblemStatement));
  const $IdeationChars = createMemo(() => calculateCharCount(FormInputs.Ideation));
  const $SolutionFeasibilityChars = createMemo(() => calculateCharCount(FormInputs.Feasibility));
  const $ProjectImpactChars = createMemo(() => calculateCharCount(FormInputs.Impact));
  const $ResearchProcessChars = createMemo(() => calculateCharCount(FormInputs.ResearchProcess));
  const $ApplicableSolutionChars = createMemo(() => calculateCharCount(FormInputs.ApplicableSolution));
  const $BusinessModelChars = createMemo(() => calculateCharCount(FormInputs.BusinessModel));

  createEffect(() => {
    if ($submission_status.state.success) {
      if ($submission_status.unwrap.value?.submission) {
        $can_update.set(true);
        if ($submission_details.state.success) {
          if ($submission_details?.unwrap?.value?.team == undefined) {
            return;
          }
          // TODO: fix this shit with typescript
          // @ts-ignore
          FormInputs.Actions.update($submission_details?.unwrap?.value?.team);
          // console.log("submission_details.unwrap.value?.team:: ", $submission_details.unwrap.value?.team);
        }
      }
    }
  });

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    $loading.set(true);
    try {
      const values = FormInputs.Actions.getValuesSnakecase();

      const { presentation, project_cover, project_images, ...filteredValues } = values;
      const payload = {
        ...filteredValues,
        project_images: FormInputs?.ProjectImages?.control?.value?.[0],
        presentation: FormInputs?.Presentation?.control?.value?.[0],
        project_cover: FormInputs?.ProjectCover?.control?.value?.[0],
      };
      console.log("values:: ", values);
      await update_team.put(payload);
      global.store.actions.alert.pushToast({
        type: "success",
        message: "Project Submitted Successfully!",
      });
      $loading.set(false);
    } catch (error: any) {
      $loading.set(false);
    }
  };

  return (
    <section class="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden md:(px-5 py-3) <md:(px-3 py-2)">
      <div class="flex flex-row w-fit items-center xs:(space-x-2) <xs:(flex-wrap)">
        <h1 class="w-full  text-xl font-[600] text-black <md:(text-22px) xs:(whitespace-nowrap) ">
          Project Submission
        </h1>
        <a
          href={settings.submission?.url}
          download
          target="_blank"
          class="flex flex-row whitespace-nowrap w-fit h-fit text-center cursor-pointer py0.5 px1 text-0.5rem rounded-1 no-underline bg!a text#page"
        >
          Instructions PDF
        </a>
      </div>
      <Switch>
        <Match when={$submission_details.state.loading}>
          <div class="flex w-full min-h-screen md:h-full  items-center justify-center">
            <Loader2 msg="Loading Submission Details..." />
          </div>
        </Match>
        <Match when={$submission_details.state.failed}>
          an error occurred while fetching {$submission_details.error}
        </Match>

        <Match when={!$can_update.value}>
          <div class="w-full h-full flex flex-col justify-center gap-4 items-center">
            <h2 class="font-bold text-1rem  text-center w-50% <md:(w-full text-0.8rem)">
              You can not submit project because you are not in a team or your team members did not reach the minimum of{" "}
              {settings.team_min.value} members.
            </h2>
            <div class="w-full flex justify-center items-center ">
              <PrimaryButton
                label="Create Team"
                classes="!bg#p !text-19px"
                onClick={() => {
                  props?.onRouteToCreateTeam?.();
                }}
              />
            </div>
          </div>
        </Match>
        <Match when={$submission_details.state.success && $can_update.value}>
          <form class="grid grid-cols-[50%_50%] w-full h-full gap-y-3 gap-x-2 justify-center  pt-6 <md:(grid-cols-1 py-5 gap-y-2 !gap-x-0)">
            <div>
              <FormInputs.TeamName.Label title="Team Name" class="form-label ">
                <FormInputs.TeamName.Error class="form-input-error" />
              </FormInputs.TeamName.Label>

              <FormInputs.TeamName.Input
                autocomplete="Team Name"
                type="text"
                class="form-input"
                placeholder={`Enter Team Name`}
              />
            </div>

            <div>
              <FormInputs.Track.Label title="Team Track" class="form-label">
                <FormInputs.Track.Error class="form-input-error" />
              </FormInputs.Track.Label>
              <FormInputs.Track.Select
                class="form-select"
                options={tracks.values}
                onNoOptions={() => {
                  console.warn("no track options to select from");
                  global.store.actions.alert.pushToast({
                    type: "error",
                    message: "Tracks list was not provided by admin!",
                  });
                }}
              />
            </div>

            <div>
              <FormInputs.ProjectName.Label title="Project Name" class="form-label">
                <FormInputs.ProjectName.Error class="form-input-error" />
              </FormInputs.ProjectName.Label>
              <FormInputs.ProjectName.Input
                autocomplete="Project Name"
                type="text"
                class="form-input"
                placeholder={`Enter Project Name`}
              />
            </div>

            <div>
              <FormInputs.ProjectCover.Label title="Project Cover" class="form-label">
                <FormInputs.ProjectCover.Error class="form-input-error" />
              </FormInputs.ProjectCover.Label>
              <FormInputs.ProjectCover.InputFile
                placeholder="/images/profile_image_template.png"
                accept="image/*"
                classes={{
                  container:
                    "flex flex-col justify-center items-center bg#bg-light text#orange border-2 border#orange border-dashed text-14px px-2.5 py-5 my-[2px] rounded-9px w-full !text-0.7rem",
                }}
                elements={{
                  input({ onInputClicked, viewer_data, drag }) {
                    return (
                      <div
                        onclick={onInputClicked}
                        class="flex flex-col w-full h-full justify-center items-center gap-5px cursor-pointer"
                      >
                        <p class="cursor-pointer">
                          <Show when={!drag} fallback={"Drop Files"}>
                            <span class="underline text-16px">
                              Upload .PNG, JPG, JPEG file, less than {FormInputs.$$META.image_max} MB
                            </span>
                          </Show>
                        </p>
                        <Show when={viewer_data.file}>{viewer_data.file.name}</Show>
                      </div>
                    );
                  },
                }}
              />
            </div>

            <div>
              <FormInputs.ProblemStatement.Label title="Problem Statement" class="form-label">
                <FormInputs.ProblemStatement.Error class="form-input-error" />
              </FormInputs.ProblemStatement.Label>

              <div class="relative">
                <FormInputs.ProblemStatement.TextArea
                  autocomplete="Problem_Statement"
                  class="form-textarea"
                  placeholder="Write a brief about the problem"
                  maxlength={char_limit}
                />
                <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">{$ProblemStatementChars()}</div>
              </div>
            </div>

            <div>
              <FormInputs.ResearchProcess.Label title="Research Process" class="form-label">
                <FormInputs.ResearchProcess.Error class="form-input-error" />
              </FormInputs.ResearchProcess.Label>

              <div class="relative">
                <FormInputs.ResearchProcess.TextArea
                  autocomplete="Research_Process"
                  class="form-textarea"
                  placeholder="Write a brief about the problem"
                  maxlength={char_limit}
                />
                <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">{$ResearchProcessChars()}</div>
              </div>
            </div>

            <div>
              <FormInputs.Ideation.Label title="Ideation" class="form-label">
                <FormInputs.Ideation.Error class="form-input-error" />
              </FormInputs.Ideation.Label>

              <div class="relative">
                <FormInputs.Ideation.TextArea
                  autocomplete="Ideation"
                  class="form-textarea"
                  placeholder="Write a brief about the idea with its objectives "
                  maxlength={char_limit}
                />

                <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">{$IdeationChars()}</div>
              </div>
            </div>

            <div>
              <FormInputs.ApplicableSolution.Label title="Applicable Solution" class="form-label">
                <FormInputs.ApplicableSolution.Error class="form-input-error" />
              </FormInputs.ApplicableSolution.Label>

              <div class="relative">
                <FormInputs.ApplicableSolution.TextArea
                  autocomplete="Applicable_Solution"
                  class="form-textarea"
                  placeholder="Write a brief about the problem"
                  maxlength={char_limit}
                />
                <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">{$ApplicableSolutionChars()}</div>
              </div>
            </div>

            <div>
              <FormInputs.Feasibility.Label title="Solution Feasibility" class="form-label">
                <FormInputs.Feasibility.Error class="form-input-error" />
              </FormInputs.Feasibility.Label>

              <div class="relative">
                <FormInputs.Feasibility.TextArea
                  autocomplete="Solution Feasibility"
                  class="form-textarea"
                  placeholder="Write a brief about the Feasibility Solution"
                  maxlength={char_limit}
                />

                <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">{$SolutionFeasibilityChars()}</div>
              </div>
            </div>

            <div>
              <FormInputs.BusinessModel.Label title="Business Model" class="form-label">
                <FormInputs.BusinessModel.Error class="form-input-error" />
              </FormInputs.BusinessModel.Label>

              <div class="relative">
                <FormInputs.BusinessModel.TextArea
                  autocomplete="Business_Model"
                  class="form-textarea"
                  placeholder="Write a brief about the problem"
                  maxlength={char_limit}
                />
                <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">{$BusinessModelChars()}</div>
              </div>
            </div>

            <div>
              <FormInputs.Impact.Label title="Project Impact" class="form-label">
                <FormInputs.Impact.Error class="form-input-error" />
              </FormInputs.Impact.Label>

              <div class="relative">
                <FormInputs.Impact.TextArea
                  autocomplete="Project Impact"
                  class="form-textarea"
                  placeholder="Write a brief about The impacts of your project "
                  maxlength={char_limit}
                />

                <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">{$ProjectImpactChars()}</div>
              </div>
            </div>

            <div>
              <FormInputs.ProjectVideo.Label title="Project Video" class="form-label">
                <FormInputs.ProjectVideo.Error class="form-input-error" />
              </FormInputs.ProjectVideo.Label>

              <FormInputs.ProjectVideo.Input
                autocomplete="Project Video"
                type="text"
                class="form-input px-3 py-4! md:py-5! outline-none rounded-9px  my-2 text-0.7rem! "
                placeholder="https://..."
              />
            </div>

            <div>
              <FormInputs.ProjectImages.Label title="Project Images" class="form-label">
                <FormInputs.ProjectImages.Error class="form-input-error" />
              </FormInputs.ProjectImages.Label>
              <FormInputs.ProjectImages.InputFile
                accept=".pdf"
                classes={{
                  container:
                    "flex flex-col justify-center items-center bg#bg-light text#orange border-2 border#orange border-dashed text-14px px-2.5 py-5 my-[2px] rounded-9px w-full text-0.7rem!",
                }}
                elements={{
                  input({ onInputClicked, viewer_data, drag }) {
                    return (
                      <div
                        onclick={onInputClicked}
                        class="flex flex-col w-full h-full justify-center items-center gap-5px cursor-pointer"
                      >
                        <p class="cursor-pointer">
                          <Show when={!drag} fallback={"Drop Files"}>
                            <span class="underline text-16px">
                              Upload .PDF file, less than {FormInputs.$$META.pdf_max} MB
                            </span>
                          </Show>
                        </p>
                        <Show when={viewer_data.file}>{viewer_data.file.name}</Show>
                      </div>
                    );
                  },
                }}
              />
              <p class="text-gray-500 text-0.5rem py-2">previous upload: {$submission_details.value?.image}</p>
            </div>

            <div>
              <FormInputs.Presentation.Label title="Project Presentation" class="form-label">
                <FormInputs.Presentation.Error class="form-input-error" />
              </FormInputs.Presentation.Label>
              <FormInputs.Presentation.InputFile
                accept=".pdf"
                classes={{
                  container:
                    "flex flex-col justify-center items-center bg#bg-light text#orange border-2 border#orange border-dashed text-14px px-2.5 py-5 my-[2px] rounded-9px w-full text-0.7rem!",
                }}
                elements={{
                  input({ onInputClicked, viewer_data, drag }) {
                    return (
                      <div
                        onclick={onInputClicked}
                        class="flex flex-col w-full h-full justify-center items-center gap-5px cursor-pointer"
                      >
                        <p class="cursor-pointer">
                          <Show when={!drag} fallback={"Drop Files"}>
                            <span class="underline text-16px">
                              Upload .PDF file, less than {FormInputs.$$META.pdf_max} MB
                            </span>
                          </Show>
                        </p>
                        <Show when={viewer_data.file}>{viewer_data.file.name}</Show>
                      </div>
                    );
                  },
                }}
              />
              <p class="text-gray-500 text-0.5rem py-2">previous upload: {$submission_details.value?.presentation}</p>
            </div>

            {/* submit btn */}
            <div class="flex flex-col w-full h-full col-span-2 items-end justify-center <md:(!col-auto)">
              <FormInputs.Actions.Button
                class="form-btn md:!w-30%"
                onclick={(e) => {
                  global.store.actions.popop.pushConfirmDialog({
                    type: "warn",
                    message: "Are you sure you want to submit this project?",
                    events: {
                      onConfirmed() {
                        onSubmit(e);
                      },
                    },
                  });
                }}
                statusValid="Submit Project"
                statusInvalid="Submit Project"
                statusSubmit="Submitting Project..."
              />
            </div>
          </form>
        </Match>
      </Switch>
    </section>
  );
}
