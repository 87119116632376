import { global } from ":global";
import { ENDPOINTS } from "./endpoints";

// import { Login } from "../model";
// import { actions } from "../store";

export async function resetPassword(token: string, password: string) {
  return global.api
    .post(ENDPOINTS.post_password_reset_confirm, { password, token })
    .then((res) => {
      // store.alert.send({
      //   type: "success",
      //   message: "password updated successfully!",
      // });
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      // const message = service.api.decode_error(
      //   err,
      //   "error resetting password!"
      // );
      // log.dev.info(
      //   "from password update :: ",
      //   message,
      //   " || ",
      //   err.request.response
      // );
      // store.alert.send({ type: "error", message });
      return Promise.reject(err);
    });
}
