import { For, Match, Show, Switch, createMemo, splitProps } from "solid-js";
import type { ATable, ATableCell, ATableScreen, ATableEmptyKey } from "./_model";
import { global } from ":global";

// TODO[done]: turn base table style to grid, add it to options and force control flow to use grid
// TODO[pending]: check if it's better to draw the grid column by column instead of row by row on the desktop.
// TODO[pending]: add gap to grid
// TODO[pending]: on the phone, check if the moving width control to grid-template-columns with 2 columns, is better for mentality and features
// TODO[pending]: turn headers option to optional and accept any items object and turn it to table
export default function Table<T extends any[]>(props: ATable<T>) {
  const [local, others] = splitProps(props, [
    "_items",
    "_headers",
    "_emptyKey",
    "_fallback",
    "_class",
    "_containers",
    "_overlays",
  ]);
  const _empty_key: ATableEmptyKey = {
    desktop: typeof local._emptyKey === "string" ? local._emptyKey : local._emptyKey?.desktop ?? "",
    phone: typeof local._emptyKey === "string" ? local._emptyKey : local._emptyKey?.desktop ?? "",
  };
  const $breakpoint = global.store.hooks.dom.useBreakpoints();
  const $screen: () => ATableScreen = createMemo(() => {
    return $breakpoint()?.misc?.md?.widthIsLess ? "phone" : "desktop";
  });
  // headers and cells pairs
  const $hcp = createMemo(() => {
    // TODO: look for user given keys from local.items passed if local.headers is not given
    if (local._headers == undefined) {
      return undefined;
    }
    const result = [] as [string, ATableCell<T>][];
    for (const header in local._headers) {
      const value = local._headers[header];
      if (header == undefined || value == undefined) {
        console.warn(`table: detected null header definition in <${header ?? "UNKNOWN HEADER"}> !!`);
        continue;
      }
      result.push([header, value]);
    }
    return result;
  });
  const $items = createMemo(() => {
    const hcp = $hcp();
    const result = {
      exist: true, // assume items exist
      array: typeof local._items === "function" ? local._items() : local._items,
    };
    if (local._items == undefined || local._items.length <= 0 || hcp == undefined || hcp.length <= 0) {
      result.exist = false;
    }
    return result;
  });

  function getItemMeta(props: { screen: ATableScreen; item: T[number]; itemIndex: () => number }) {
    const { screen, item, itemIndex } = props;
    const result = {
      container:
        typeof local._containers?.items === "function"
          ? local._containers?.items({ screen, item, itemIndex })
          : local._containers?.items,
      overlay:
        typeof local._overlays?.items === "function"
          ? local._overlays?.items({ screen, item, itemIndex })
          : local._overlays?.items,
    };

    return result;
  }

  function getCellMeta(props: {
    screen: ATableScreen;
    header: string;
    cell: ATableCell<T>;
    hcpIndex: () => number;
    item?: T[number];
    itemIndex?: () => number;
  }) {
    const { screen, item, itemIndex, header, cell, hcpIndex } = props;
    const empty_key: ATableEmptyKey = {
      desktop: typeof cell.emptyKey === "string" ? cell.emptyKey : cell?.emptyKey?.desktop ?? _empty_key.desktop,
      phone: typeof cell.emptyKey === "string" ? cell.emptyKey : cell?.emptyKey?.desktop ?? _empty_key.desktop,
    };
    const result = {
      hide: typeof cell?.hide === "function" ? cell.hide({ screen, item }) : cell.hide,
      hideHeader: screen === "desktop" ? false : cell?.hideHeader?.phone ?? false,
      empty_key: screen === "desktop" ? empty_key.desktop : empty_key.phone,
      overlay_below: cell?.overlayBelow == undefined ? false : cell?.overlayBelow,
      value: null,
      container: {
        header: undefined,
        cell: undefined,
      },
    };
    if (result.hide == undefined) {
      result.hide = false;
    }

    // the item itself is undefined, there's no point setting up signals or further details
    if (item == undefined) {
      return result;
    }

    if (screen === "desktop") {
      result.container.header =
        typeof cell.containers?.desktop?.header === "function"
          ? cell.containers.desktop.header({ screen, item, itemIndex })
          : cell.containers?.desktop?.header;
      result.container.cell =
        typeof cell.containers?.desktop?.cell === "function"
          ? cell.containers.desktop.cell({ screen, item, itemIndex })
          : cell.containers?.desktop?.cell;
    } else {
      result.container.header =
        typeof cell.containers?.phone?.header === "function"
          ? cell.containers.phone.header({ screen, item, itemIndex })
          : cell.containers?.phone?.header;
      result.container.cell =
        typeof cell.containers?.phone?.cell === "function"
          ? cell.containers.phone.cell({ screen, item, itemIndex })
          : cell.containers?.phone?.cell;
    }

    if (typeof cell.key === "function") {
      result.value = cell.key({ screen, item, itemIndex, emptyKey: result.empty_key });
    } else if (cell.key === "INDEX") {
      result.value = itemIndex?.() ?? "UNKNOWN INDEX"; // don't know if this is evenr possible; not having an itemIndex
    } else if (!(cell.key in item) && !(header in item)) {
      console.warn(
        "table: item key for <",
        header,
        `> is not found, please use <${header}>.key to define it appropriately!`
      );
      result.value = `ITEM KEY NOT FOUND ${(cell.key as string) ?? header}`;
    } else {
      result.value = item[cell.key ?? header] ?? result.empty_key;
    }

    return result;
  }

  return (
    //_ overflow works for all devices the same with an exception for mobile where overflow-x has to be stopped
    <section
      {...others}
      class={(others?.class ?? "") + " " + "relative flex flex-col min-w-full w-full !overflow-hidden"}
    >
      <Switch>
        {/* phone and tablet */}
        <Match when={$screen() === "phone"}>
          <Show when={$items().exist} fallback={local._fallback ?? "table has no content"}>
            {/* we can't use table with tr as immediate children in solidjs, so we have to change hierarchy :( */}
            <section
              class={
                (local._class?.phone?.wrapper ?? "") +
                " " +
                "relative flex flex-col w-full h-full  scrollbar text-black"
              }
            >
              <For each={$items().array}>
                {(item, itemIndex) => {
                  const $meta = createMemo(() => getItemMeta({ screen: "phone", item, itemIndex }));
                  return (
                    // single card
                    <div
                      {...($meta()?.container as any)}
                      style={{
                        ...$meta()?.container?.style,
                        display: "grid",
                        "grid-template-columns": "max-content 1fr",
                      }}
                      class={
                        ($meta()?.container?.class ?? "") +
                        " " +
                        (local._class?.phone?.card ?? "") +
                        " " +
                        "!relative w-full h-fit"
                      }
                    >
                      {$meta()?.overlay != undefined && (
                        <p class="!absolute left-0 right-0 bottom-0 top-0 w-full h-full overflow-visible !z-1">
                          {$meta()?.overlay}
                        </p>
                      )}
                      <For each={$hcp()}>
                        {([header, cell], hcpIndex) => {
                          const $meta = createMemo(() =>
                            getCellMeta({ screen: "phone", header, cell, hcpIndex, item, itemIndex })
                          );
                          const $icon = createMemo(() => {
                            if (cell.icon == undefined) {
                              return undefined;
                            }
                            if (typeof cell.icon === "object" && "phone" in cell.icon) {
                              return cell.icon?.phone;
                            }
                            return cell.icon as JSX.Element;
                          });

                          return (
                            <Show when={!$meta()?.hide}>
                              {!$meta()?.hideHeader && (
                                <p
                                  {...$meta()?.container.header}
                                  style={{
                                    ...$meta()?.container.header?.style,
                                  }}
                                  class={
                                    ($meta()?.container?.header?.class ?? "") +
                                    " " +
                                    (cell?.class?.phone?.header ?? "") +
                                    " " +
                                    (cell?.class?.phone?.headerAndCell ?? "") +
                                    " " +
                                    (local._class?.phone?.header ?? "") +
                                    " " +
                                    "text-start !z-0"
                                  }
                                >
                                  {$icon()}
                                  <span
                                    class={
                                      (cell?.class?.phone?.headerTitle ?? "") +
                                      " " +
                                      (local._class?.phone?.headerTitle ?? "")
                                    }
                                  >
                                    {header}
                                  </span>
                                </p>
                              )}
                              <p
                                {...$meta()?.container.cell}
                                style={{
                                  ...$meta()?.container.cell?.style,
                                }}
                                class={
                                  ($meta()?.overlay_below ? "!z-2" : "!z-0") +
                                  " " +
                                  ($meta()?.hideHeader ? "!col-span-2" : "") +
                                  " " +
                                  ($meta()?.container?.cell?.class ?? "") +
                                  " " +
                                  (cell?.class?.phone?.cell ?? "") +
                                  " " +
                                  (cell?.class?.phone?.headerAndCell ?? "") +
                                  " " +
                                  (local._class?.phone?.cell ?? "") +
                                  " " +
                                  "!relative overflow-auto w-full text-start"
                                }
                              >
                                {$meta()?.value}
                              </p>
                            </Show>
                          );
                        }}
                      </For>
                    </div>
                  );
                }}
              </For>
            </section>
          </Show>
        </Match>
        <Match when={true}>
          {/* wrapper */}
          <table
            class={
              (local._class?.desktop?.table ?? "") +
              " " +
              "relative block h-full !overflow-auto  border-spacing-0 border-separate scrollbar"
            }
          >
            <thead class={"!sticky !top-0 !z-2" + " " + (local._class?.desktop?.thead ?? "") + " " + ""}>
              <tr class={"!relative !z-0" + " " + (local._class?.desktop?.theadTr ?? "")}>
                <For each={$hcp()}>
                  {([header, cell], hcpIndex) => {
                    const $meta = createMemo(() => getCellMeta({ screen: "desktop", header, cell, hcpIndex }));
                    const $icon = createMemo(() => {
                      if (cell.icon == undefined) {
                        return undefined;
                      }
                      if (typeof cell.icon === "object" && "desktop" in cell.icon) {
                        return cell.icon?.desktop;
                      }
                      return cell.icon as JSX.Element;
                    });
                    return (
                      // single header
                      <Show when={!$meta()?.hide}>
                        <th
                          {...$meta()?.container.header}
                          style={{
                            ...$meta()?.container.header?.style,
                          }}
                          class={
                            ($meta()?.container?.header?.class ?? "") +
                            " " +
                            (cell?.class?.desktop?.th ?? "") +
                            " " +
                            (cell?.class?.desktop?.thtd ?? "") +
                            " " +
                            (local._class?.desktop?.theadTrTh ?? "") +
                            " " +
                            "relative w-full text-start !z-1"
                          }
                        >
                          {$icon()}
                          <span
                            class={
                              (cell?.class?.desktop?.thTitle ?? "") +
                              " " +
                              (local._class?.desktop?.theadTrThTitle ?? "")
                            }
                          >
                            {header}
                          </span>
                        </th>
                      </Show>
                    );
                  }}
                </For>
                <th class="min-w-0 w-0 max-w-0 min-h-0 h-0 max-h-0 !border-0 !outline-0 !ring-0">
                  <div
                    class={
                      "!absolute left-0 right-0 bottom-0 top-0 w-100% h-full overflow-hidden " +
                      " " +
                      (local._class?.desktop?.theadBase ?? "")
                    }
                  />
                </th>
              </tr>
            </thead>
            <tbody class={"!z-0" + " " + (local._class?.desktop?.tbody ?? "") + " " + "text-black"}>
              <Show
                when={$items().exist}
                fallback={
                  <td colspan="100%" class="w-full h-full">
                    {local._fallback ?? "table has no content"}
                  </td>
                }
              >
                <For each={$items().array}>
                  {(item, itemIndex) => {
                    const $meta = createMemo(() => getItemMeta({ screen: "desktop", item, itemIndex }));
                    return (
                      <tr
                        {...$meta()?.container}
                        style={{
                          ...$meta()?.container?.style,
                        }}
                        class={
                          ($meta()?.container?.class ?? "") +
                          " " +
                          (local._class?.desktop?.tbodyTr ?? "") +
                          " " +
                          "!relative" //! don't touch this; overlay depends on it
                        }
                      >
                        <For each={$hcp()}>
                          {([header, cell], hcpIndex) => {
                            const $meta = createMemo(() =>
                              getCellMeta({ screen: "desktop", header, cell, hcpIndex, item, itemIndex })
                            );

                            return (
                              // cell value
                              <Show when={!$meta()?.hide}>
                                <td
                                  {...$meta()?.container.cell}
                                  style={{
                                    ...$meta()?.container.cell?.style,
                                  }}
                                  class={
                                    ($meta()?.overlay_below ? "!z-2" : "!z-0") +
                                    " " +
                                    ($meta()?.container?.cell?.class ?? "") +
                                    " " +
                                    (cell?.class?.desktop?.td ?? "") +
                                    " " +
                                    (cell?.class?.desktop?.thtd ?? "") +
                                    " " +
                                    (local._class?.desktop?.tbodyTrTd ?? "") +
                                    " " +
                                    "relative overflow-auto w-full text-start"
                                  }
                                >
                                  {$meta()?.value}
                                </td>
                              </Show>
                            );
                          }}
                        </For>
                        {$meta()?.overlay != undefined && (
                          <td class="min-w-0 w-0 max-w-0 min-h-0 h-0 max-h-0 !border-0 !outline-0 !ring-0 overflow-visible !z-1">
                            <div class="!absolute left-0 right-0 bottom-0 top-0 w-full h-full overflow-visible">
                              {$meta()?.overlay}
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  }}
                </For>
              </Show>
            </tbody>
          </table>
        </Match>
      </Switch>
    </section>
  );
}
