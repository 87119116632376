import { For, Match, Show, Switch, createEffect, createMemo, untrack } from "solid-js";
import type { MainProps, ExhibitionCardProps } from "./_model";
import { ExhibitionCard } from "./components";
import { DropDown, SearchTool } from ":src/components/form";
import { solidstate } from ":shared/utils/state-manager";
import { get_exhibition } from "./apis";
import { Loader2 } from ":src/components/loaders";

export default function Exhibition(props: MainProps) {
  const $ExhibitionList = solidstate.createAsync(get_exhibition);
  const exhibition_keyword = ["team_name", "track", "project_name"];

  const $applied_filter = solidstate.create({} as { search?: string; track?: string });

  const $tracks = createMemo(() => {
    const tracks = [];
    $ExhibitionList?.value?.forEach((s, index) => {
      if (!tracks.find((f) => f.value === s.track) && s.track) {
        tracks.push({
          id: index,
          value: s.track,
        });
      }
    });
    return tracks;
  });

  createEffect(() => {
    if ($ExhibitionList.state.success) {
      console.log("ExhibitionList success");
      untrack(() => {});
    }
  });

  return (
    <section class="flex flex-col w-full min-h-full h-full px-5 py-3 gap-2 <md:(px-2 py-1)">
      <h1 class="text-xl  font-[600] text-black z-1 <md:(text-22px )">Exhibition</h1>
      <Switch>
        <Match when={$ExhibitionList.state.loading}>
          <div class="flex w-full min-h-screen md:h-full  items-center justify-center">
            <Loader2 msg="Loading Exhibition Details..." />
          </div>
        </Match>
        <Match when={$ExhibitionList.state.failed}>an error occurred while fetching {$ExhibitionList.error}</Match>
        <Match when={$ExhibitionList.state.success}>
          <div class="w-full flex flex-row justify-between items-center gap-3 py-2 !<md:(flex-col)">
            <div class="w-full">
              <SearchTool onSearch={({ value }) => $applied_filter.set((s) => ({ ...s, search: value }))} />
            </div>
            <div class=" w-[40%] flex flex-row gap-3 justify-center items-center !<md:(w-full)">
              <p class="text-black text-[18px] min-w-fit">Filter by</p>

              <DropDown
                label="All Tracks"
                options={$tracks()}
                onValueChoose={(value) => $applied_filter.set((s) => ({ ...s, track: value }))}
              />
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-3 gap-4 h-fit  overflow-x-hidden !overflow-y-auto pb-13 pt-6 <md:(py-10)">
            <For each={$ExhibitionList.value}>
              {(exhib: ExhibitionCardProps) => {
                const show = createMemo(() => {
                  if (
                    $applied_filter.value.search &&
                    !exhibition_keyword.some((prop) =>
                      exhib[prop]?.toLowerCase().includes($applied_filter.value.search.toLowerCase())
                    )
                  ) {
                    return false;
                  }
                  if ($applied_filter.value.track && exhib.track !== $applied_filter.value.track) {
                    return false;
                  }
                  return true;
                });
                return (
                  <Show when={show()}>
                    <ExhibitionCard {...exhib} />
                  </Show>
                );
              }}
            </For>
          </div>
        </Match>
      </Switch>
    </section>
  );
}
