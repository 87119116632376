import createTokenHandler from "./create-token-handler";

//
import {
  AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from "axios";
import type { __ConfigObjectHandler, __InitConfig, _Interceptors } from "../_model";
import type { Options, __TokenHelpers, __ConfigState, __EncodedToken, InvalidTokenStates } from "./_model";
import { checkAuthConfig } from "./check-auth-config";
import { date } from "@qundus.tc/agnostic.helpers";
import { checkEndpointURL } from "../helpers";

// exports
export type * as _Auth from "./_model";
//

export default function createAuthFeature<TokenDecoded = any, Events extends Record<string, Function> = any>(props: {
  options: Options<TokenDecoded, Events>;
  axios: AxiosInstance;
  _coh: __ConfigObjectHandler;
  __initConfig: __InitConfig;
}) {
  const { options, axios, _coh, __initConfig } = props;
  //
  const _token = createTokenHandler<TokenDecoded, Events>({
    options: options as any,
    _coh,
    __initConfig,
  });

  function _onInvalidTokenStatus(props: {
    res: any;
    err: any;
    status: InvalidTokenStates;
    options: Options<TokenDecoded, Events>;
  }) {
    const { res, err, status, options } = props;
    // const defaults = options.auth.defaults
    switch (options?.mode) {
      case "http-only-cookie":
        if (status === "clear token") {
          if (_token.helpers.exists()) {
            const config = checkAuthConfig(undefined);
            axios.get(options?.endpoints?.getTokenRemove.url, config);
            return;
          }
        } else if (status === "force token set") {
          _token.decodeAndSave({ resOrToken: res });
        }
        break;
      case "localstorage":
        if (status === "clear token") {
          _token.helpers.clear({ res, err });
        } else if (status === "force token set") {
          _token.decodeAndSave({ resOrToken: res });
        }
        break;
      default:
        break;
    }
  }

  function _checkHTTPResponse(props: { res?: AxiosResponse; err?: any; __configState: __ConfigState }) {
    const { res, err, __configState } = props;
    const config = res?.config ?? err?.config;
    let defaults = options?.defaults;
    // console.log("_checkHTTPResponse :: ", res, " :: ", err, " :: ", __configState);
    if (!_coh.hasAuth({ config })) {
      return;
    }
    try {
      // -- first check non-auth based endpoints --
      if (!__configState.is_auth_endpoint) {
        if (err) {
          const err_status = err?.response?.status;
          if (!options?.defaults?.ignoreUnauthorizedAccessStatusCodes?.includes(err_status)) {
            const status = options?.triggers?.onUnauthorizedAccess?.({
              encoded: _token.getEncoded(),
              decoded: _token.getDecoded(),
              res,
              err,
              defaults,
              helpers: _token.helpers,
              __configState,
            });
            if (status != undefined) {
              _onInvalidTokenStatus({ status: status as any, res, err, options: options as any });
              return;
            }
          }
          throw new Error(err);
        }
        return;
      }
      // -- then check for errors --
      if (err) {
        // ! special case: http-only logging out but has already logged out which will cause no triggers fired
        if (__configState.is_token_remove) {
          _token.helpers.clear({ res, err });
          return;
        }
        const status = options?.triggers?.onInvalidatedToken?.({
          res,
          err,
          defaults,
          __configState,
        });
        if (status != undefined) {
          _onInvalidTokenStatus({ status: status as any, res, err, options: options as any });
          return;
        }
        throw new Error(err);
      }
      // alast treat each endpoint based on auth mode
      if (__configState.is_token_remove) {
        _token.helpers.clear({ res, err });
        return;
      }
      const status = _token.decodeAndSave({ resOrToken: res });
      if (status != undefined) {
        _onInvalidTokenStatus({ status: status as any, res, err, options: options as any });
      }
      // if (options?.mode === "localstorage") {
      //   const token_resonse = options?.events?.getTokenRevalidate?.({ res });
      //   if (token_resonse == undefined) {
      //     const status = options?.triggers?.onInvalidatedToken?.({ res, err, defaults, __axiosConfigState });
      //     if (status !== undefined && status !== null) {
      //       this.onInvalidTokenStatus({ status: status as any, res, err, options: options as any });
      //       return;
      //     }
      //     console.error("AxiosInstance: options.auth?.events?.onTokenRevalidate returned a undefined or null object");
      //     // we can't have a null _token_response unlike in http-only-cookie
      //     throw new Error(
      //       `AxiosResponse: token has been refreshed properly from remote api but options.auth?.events?.onTokenRefreshed
      //       returned a null or undefined object, this will cause new token to not be stored and it burns the old refresh token.
      //       and since i have no way to know your tokenRefresh response body i can't store anything so i have to log user out.`
      //     );
      //   }
      //   _setEncodedToken({ res: token_resonse });
      // }
    } catch (err: any) {
      _onInvalidTokenStatus({ status: defaults.invalidToken, res, err, options: options as any });
    }
  }

  function _getAxiosConfigState(config: InternalAxiosRequestConfig<any>): __ConfigState {
    const is_token = config?.url === options?.endpoints?.postToken?.url;
    let is_token_revalidate = undefined;
    let is_token_remove = undefined;
    if (options?.mode === "http-only-cookie") {
      is_token_revalidate = config?.url === options?.endpoints?.getTokenRevalidate?.url;
      is_token_remove = config?.url === options?.endpoints?.getTokenRemove?.url;
    } else if (options?.mode === "localstorage") {
      is_token_revalidate = config?.url === options?.endpoints?.postTokenRevalidate?.url;
    }
    return {
      is_auth_endpoint: is_token || is_token_revalidate || is_token_remove,
      is_token,
      is_token_revalidate,
      is_token_remove,
    };
  }

  return {
    interceptorsCycle: {
      async request(props) {
        const { config, helpers } = props;
        const __configState = _getAxiosConfigState(config);
        const __initConfig = helpers.getInitConfig();

        // -- never remove the following checks, it protects against infinite loops --
        if (!_coh.hasAuth({ config })) {
          return config;
        }
        if (_coh.hasInternalRequest({ config })) {
          return config;
        }
        // --!

        let mode: "withCreds" | "bearer" | "clear" = undefined;
        if (__initConfig.httpOnlyCookieTempSession) {
          mode = "bearer";
        } else {
          switch (options.mode) {
            case "http-only-cookie":
              mode = "withCreds";
              // console.log("with creds :: ", state);
              if (__configState.is_token && _token.helpers.exists()) {
                try {
                  //? request is skipped internally and revalidating is handled on the response side
                  const c = _coh.appendInternalRequest({ config });
                  c.withCredentials = true;
                  await axios.get(options.endpoints.getTokenRemove?.url, c);
                } catch (e) {
                  // throw new Error("AxiosInstance: couldn't refresh token, logging out");
                }
                _token.helpers.clear({ skipUpdate: true });
              } else if (!__configState.is_auth_endpoint) {
                if (__initConfig.first_auth_request) {
                  // first auth call and it hasn't resolved yet, intercept an call the check endpoint
                  try {
                    //? request is skipped internally and revalidating is handled on the response side
                    const c = _coh.appendInternalRequest({ config });
                    c.withCredentials = true;
                    await axios.get(options?.endpoints.getTokenRevalidate?.url, c);
                  } catch (e) {
                    throw new AxiosError("AxiosInstance: couldn't refresh token, logging out");
                  }
                }
              }
              break;
            case "localstorage":
              // console.log("options.mode localstorage in request :: ", __axiosConfigState);
              mode = "bearer";
              if (__configState.is_token && _token.helpers.exists()) {
                _token.helpers.clear({ skipUpdate: true });
              } else if (!__configState.is_auth_endpoint && options.tokenExpirationTechnique === "proactive") {
                let should_refresh = false;
                if (_token.getEncoded() == undefined) {
                  // special case
                  __configState.is_auth_endpoint = true;
                  __configState.is_token_revalidate = true;
                  _checkHTTPResponse({
                    err: "no token response to use for token refresh",
                    __configState,
                  });
                  throw new AxiosError("Qunxios: token doesn't exist, detected future unauthorized requests");
                } else if (__initConfig.manualTokenExpire) {
                  const date = __initConfig.manualTokenExpire;
                  should_refresh = _token.helpers.expired({ tokenOrExp: date.getTime() });
                } else {
                  // should_refresh = JWT.isExpired({ tokenOrExp: 1727863633 });
                  // const d = new Date(_jwt_decoded.exp * 1000);
                  should_refresh = _token.helpers.expired();
                  // should_refresh = _token.helpers.expired({ tokenOrExp: 1727864602 });
                  // const d = new Date(1727864602 * 1000);
                  // const s = date.getCountdown(d.toString());

                  // console.log("inside :: ", _token.getDecoded());
                  // console.log("resolve is :: ", s);
                }
                // console.log("should_refresh :: ", should_refresh);
                //

                if (should_refresh) {
                  console.log("Qunxios: detected expired token before next auth call, refreshing!");
                  const refresh = options?.events?.getTokenRefresh({
                    encoded: _token.getEncoded(),
                    decoded: _token.getDecoded(),
                    helpers: _token.helpers,
                  });
                  try {
                    //? request is skipped internally and revalidating is handled on the response side
                    const c = _coh.appendInternalRequest({ config });
                    // @ts-ignore
                    c.headers = {
                      ...c.headers,
                      Authorization: `Bearer ${_token.getEncoded().access}`,
                    };
                    await axios.post(options.endpoints.postTokenRevalidate?.url, refresh, c);
                  } catch (e) {
                    throw new AxiosError("Qunxios: couldn't refresh token, you should end session!", null, config);
                  }
                }
              }
              break;
            default:
              break;
          }
        }

        //

        if (mode === "withCreds") {
          config.withCredentials = true;
        } else {
          if (_token.helpers.exists()) {
            config.headers.Authorization = `Bearer ${_token.getEncoded().access}`;
            // config.headers["x-access-token"] = token; // for Node.js Express back-end
          }
        }
        if (__initConfig.first_auth_request) {
          helpers.updateInitConfig({ first_auth_request: false });
        }

        return config;
      },
      async response(props) {
        const { res } = props;
        const config = res?.config;
        const __configState = _getAxiosConfigState(config);
        // -- never remove the following checks, it protects against infinite loops --
        if (!_coh.hasAuth({ config })) {
          return res;
        }
        // --!

        switch (options.mode) {
          case "http-only-cookie":
            if (__configState.is_auth_endpoint) {
              _checkHTTPResponse?.({ res, __configState: __configState });
            }
            break;
          case "localstorage":
            if (__configState.is_auth_endpoint) {
              _checkHTTPResponse?.({ res, __configState: __configState });
            }
            break;
          default:
            break;
        }

        return res;
      },
      async responseError(props) {
        const { err, helpers } = props;
        const config = err?.config;
        const __configState = _getAxiosConfigState(config);
        const __initConfig = helpers.getInitConfig();
        const unauthorizedStatusCode = options?.defaults?.unauthorizedStatusCode;
        let new_err = undefined;

        // -- never remove the following checks, it protects against infinite loops --
        if (!_coh.hasAuth({ config })) {
          return err;
        } else if (__configState.is_auth_endpoint) {
          _checkHTTPResponse?.({ err, __configState: __configState });
          return err;
        } else if (err.response?.status !== unauthorizedStatusCode && !_coh.hasInternalRequest({ config })) {
          _checkHTTPResponse?.({ err, __configState: __configState });
          return err;
        }
        // --!

        switch (options.mode) {
          case "http-only-cookie":
            // in http only cookie, refreshing of token is handled on backend side, so we just return the error
            // const jwt = props.getJWTDecoded();
            const one_time_session_allowed = !options?.disableOneTimeSessionToken;
            const activate_ots =
              !__initConfig.httpOnlyCookieTempSession && _token.helpers.exists() && one_time_session_allowed;
            if (activate_ots) {
              console.warn("Qunxios: one time session only token activated upon a call to ", config?.url);
              config["header"] = {
                ...config?.["header"],
                Authorization: `Bearer ${_token.getEncoded().access}`,
              } as unknown as any;
              helpers.updateInitConfig({ httpOnlyCookieTempSession: true });
              options?.triggers?.onOneTimeSessionsActivated?.({ err });
              return axios(config);
            }
            break;
          case "localstorage":
            if (options.tokenExpirationTechnique !== "reactive") {
              break;
            }
            const token_endpoint = options.endpoints.postToken.url;
            // console.log("check access token response expire :: ", unauthorizedStatusCode, " :: ", config);
            if (config.url !== token_endpoint && err.response?.status === unauthorizedStatusCode) {
              // Access Token was expired
              const refresh = options?.events?.getTokenRefresh({
                encoded: _token.getEncoded(),
                decoded: _token.getDecoded(),
                helpers: _token.helpers,
              });
              // console.log("access token expired :: ", token_refresh);
              if (refresh == undefined) {
                console.error(
                  "Qunxios: token refresh obtained through options.auth?.events?.getTokenRefresh is undefined or null,",
                  " aborting refresh and logging out"
                );
              } else {
                try {
                  // config._retry = true;
                  // const c = _coh.appendInternalRequest({ config });
                  await axios.post(options.endpoints.postTokenRevalidate.url, refresh, config);
                  // props.revalidateAuthedCall?.({ res, state });
                  return axios(config);
                } catch (_error) {
                  new_err = _error as unknown as AxiosError;
                }
              }
            }
            break;
          default:
            break;
        }

        _checkHTTPResponse?.({ err, __configState: __configState });
        if (new_err !== undefined) {
          return new_err;
        }
        return err;
      },
    } as _Interceptors.Cycle,
    api: {
      getAuth<T = any>(url: string, config?: AxiosRequestConfig) {
        url = checkEndpointURL(url);
        config = checkAuthConfig({ config, _coh });
        return axios.get<T>(url, config);
      },
      postAuth<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
        url = checkEndpointURL(url);
        config = checkAuthConfig({ config, _coh });
        return axios.post<T>(url, data, config);
      },
      putAuth<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
        url = checkEndpointURL(url);
        config = checkAuthConfig({ config, _coh });
        return axios.put<T>(url, data, config);
      },
      patchAuth<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
        url = checkEndpointURL(url);
        config = checkAuthConfig({ config, _coh });
        return axios.patch<T>(url, data, config);
      },
      delAuth<T = any>(url: string, config?: AxiosRequestConfig) {
        url = checkEndpointURL(url);
        config = checkAuthConfig({ config, _coh });
        return axios.delete<T>(url, config);
      },
    },
    user: {
      events: options?.customEvents?.({
        getDecodedToken: () => _token.getDecoded(),
        helpers: _token.helpers,
      }) as ReturnType<typeof options.customEvents>,
      get mode() {
        return options?.mode;
      },
      get token() {
        return {
          get encoded() {
            return () => _token.getEncoded();
          },
          get decoded() {
            return () => _token.getDecoded();
          },
          get helpers() {
            return _token.helpers;
          },
        };
      },
      get login() {
        return async (data: any) => {
          const config = checkAuthConfig({ config: undefined, _coh });
          // console.log("login config is :: ", config);
          return axios.post(options?.endpoints?.postToken?.url, data, config).then((res) => {
            const encoded = _token.getDecoded();
            const decoded = _token.getDecoded();

            return {
              encoded,
              decoded,
              res,
            };
          });
        };
      },
      get logout() {
        return async () => {
          let result = {
            error: undefined,
            res: undefined,
          };
          if (options?.mode === "http-only-cookie") {
            const config = checkAuthConfig({ config: undefined, _coh });
            await axios
              .get(options?.endpoints?.getTokenRemove?.url, config)
              .then((res) => {
                result.res = res;
              })
              .catch((e) => {
                result.error = e;
              });
          }
          if (result.error == undefined) {
            _token.helpers.clear({ res: { data: { meessage: "logging out" } } as any });
          }
          return Promise.resolve(result);
        };
      },
      get checkToken() {
        return async <T extends TokenDecoded = TokenDecoded>(): Promise<{ token: T; error: any }> => {
          let result = undefined;
          let error = undefined as any;

          if (_token.helpers.exists()) {
            result = _token.getDecoded();
          } else {
            if (options?.mode === "http-only-cookie") {
              const config = checkAuthConfig({ config: undefined, _coh });
              await axios
                .get(options?.endpoints?.getTokenRevalidate?.url, config)
                .then(() => {
                  result = _token.getDecoded();
                })
                .catch((e) => {
                  error = e;
                });
            }
          }

          if (result == undefined) {
            if (error == undefined) {
              error = "token not found, should logout!";
            }
            // better than throwing for better code readability on error handling side
            return Promise.resolve({ token: result, error });
          }
          // _token.helpers.clear({ err: "token not found, should logout!" });
          return Promise.resolve({ token: result, error });
        };
      },
    },
  };
}
