import { For, Match, Show, Switch, createEffect, createMemo, createSignal, untrack } from "solid-js";
import type { MainProps, ResourcesCardProps } from "./_model";
import { ResourcesCard } from "./components";
import { solidstate } from ":shared/utils/state-manager";
import { get_resources } from "./apis";
import { DropDown, SearchTool } from ":src/components/form";
import { Loader2 } from ":src/components/loaders";

export default function Resources(props: MainProps) {
  const $resources_list = solidstate.createAsync(get_resources);
  const $applied_filter = solidstate.create({} as { search?: string; category?: string });
  const resources_keyword = ["title", "category"];

  const $categories = createMemo(() => {
    const categories = [];
    $resources_list?.value?.forEach((s, index) => {
      if (!categories.find((f) => f.value === s.category) && s.category) {
        categories.push({
          id: index,
          value: s.category,
        });
      }
    });
    return categories;
  });

  return (
    <section class="flex flex-col w-full min-h-full h-full px-5 py-3  <md:(px-2 py-1) ">
      <h1 class="text-xl  font-[600] text-black z-1 <md:(text-22px )">Resources</h1>
      <Switch>
        <Match when={$resources_list.state.loading}>
          <Loader2 msg="Loading Resources Details..." />
        </Match>
        <Match when={$resources_list.state.failed}>an error occurred while fetching {$resources_list.error}</Match>
        <Match when={$resources_list.state.success}>
          <div class="w-full flex flex-row justify-between items-center gap-3 py-2 !<md:(flex-col)">
            {/* <SearchTool  onSearch={handleSearch}/> */}
            <div class="w-full">
              <SearchTool onSearch={({ value }) => $applied_filter.set((s) => ({ ...s, search: value }))} />
            </div>
            <div class="w-[40%] flex flex-row gap-3 justify-center items-center !<md:(w-full)">
              <p class="text-black text-[18px] min-w-fit">Filter by</p>

              <DropDown
                label="Category"
                options={$categories()}
                onValueChoose={(value) => $applied_filter.set((s) => ({ ...s, category: value }))}
              />
            </div>
          </div>

          <div class="grid grid-cols-4 w-full  gap-x-1rem gap-y-4 h-fit  overflow-x-hidden !overflow-y-auto pb-13 pt-6 scrollbar lt-md:(!grid-cols-2 py-10) sm:(!grid-cols-1 py-10)">
            <For each={$resources_list.value}>
              {(resource: ResourcesCardProps) => {
                const show = createMemo(() => {
                  if (
                    $applied_filter.value.search &&
                    !resources_keyword.some((prop) =>
                      resource[prop]?.toLowerCase().includes($applied_filter.value.search.toLowerCase())
                    )
                  ) {
                    return false;
                  }
                  if ($applied_filter.value?.category && resource?.category !== $applied_filter?.value?.category) {
                    return false;
                  }

                  return true;
                });

                return (
                  <Show when={show()}>
                    <ResourcesCard
                      {...resource}
                      class={`no-underline 
                          w-full min-h-17rem max-h-17rem h-17rem
                          space-y-2
                          relative  flex flex-col rounded-xl bg-white bg-clip-border 
                          !overflow-hidden
                          border-1 border-gray/30 border-solid
                        `}
                    />
                  </Show>
                );
              }}
            </For>
          </div>
        </Match>
      </Switch>
    </section>
  );
}
