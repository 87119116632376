import { Anchor, type _Navigation } from ":shared/components/route";
import { default as routes } from "../routes";
import { global } from ":global";
// import { LockedAnchor } from ":src/components/overlays";
export default function Livestage(props: _Navigation.SideNavJSXItemProps) {
  // const $settings = global.store.hooks.platform.useSettings("live_stage");
  return (
    <Anchor
      class={`relative  ${props?.anchor?.class}`}
      setup={{
        to: routes,
        classes: {
          active: `${props.anchor.setup.classes.active}`,
        },
      }}
      // events={{
      //   getShouldNavigate: () => $settings()?.active,
      // }}
      // triggers={{
      //   onBeforeNavigate: () => {
      //     if (!$settings().active) {
      //       global.store.actions.alert.pushToast({
      //         type: "error",
      //         message: "This page is locked by admin, you can't access it right now!",
      //       });
      //     }
      //   },
      // }}
      components={{
        title: "Statistics",
        idleIcon: "icon-gridicons:stats-alt text-white",
        activeIcon: "icon-gridicons:stats-alt text#p",
      }}
    >
      {/* <LockedAnchor locked={!$settings().active} /> */}
    </Anchor>
  );
}
