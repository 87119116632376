import { solidstate } from ":shared/utils/state-manager";
import type { AItemProps } from "./_model";
import { runWithOwner } from "solid-js";

export const $modals = solidstate.create<AItemProps[]>([]);

export const actions = {
  pushModal(props: AItemProps) {
    const { owner } = solidstate.helpers.getOwner();
    runWithOwner(owner, () => {
      $modals.set((s) => [...s, props]);
    });
  },
};
