import { global } from ":global";
import { ENDPOINTS } from "./endpoints";

export async function get_teams() {
  // return Promise.resolve(import("./get-teams.mocks")).then((r) => {
  //   return r.success;
  // });
  return global.api
    .getAuth(ENDPOINTS.get_teams)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err.message);
    });
}
