import type { WorkshopsCardProps } from "../_model";
import { Show, splitProps } from "solid-js";
import placeholder_image from "../assets/images/cover_placeholder.png";

export function WorkshopCard(props: WorkshopsCardProps) {
  const [local, others] = splitProps(props, [
    "link",
    "image",
    "title",
    "category",
    "description",
    "id",
    "instructor",
    "start",
    "end",
    "status",
  ]);
  const dates = (() => {
    const start = new Date(local.start);
    const end = new Date(local.end);
    return {
      start: {
        monthName: start.toLocaleString("default", { month: "short" }).toUpperCase(),
        time: start.toLocaleString("default", { timeStyle: "short" }),
      },
      end: {
        monthName: end.toLocaleString("default", { month: "short" }).toUpperCase(),
        time: end.toLocaleString("default", { timeStyle: "short" }),
      },
    };
  })();
  return (
    <a {...others} href={local.link} target="_blank">
      <div class="flex w-full h-50%">
        <img class="w-full h-full object-stretch" src={local.image ?? placeholder_image} alt="alt" />
        \\\
      </div>
      <div class="flex flex-col w-full h-full overflow-y-auto px-2 pb-2 ">
        <div class="absolute top-2 right-2 flex flex-col text-0.4rem gap-y-2 children:(text-white py-1.5 px-2 rounded-xl items-center text-center) ">
          <span class="bg#warn">{local.category ?? "No category"}</span>
          <span class="bg-black">{local.status ?? "No status"}</span>
        </div>
        {/* <div class="absolute top-40% right-2 flex flex-row text-0.45rem gap-x-2 children:(text-white py-1.5 px-2 rounded-xl items-center text-center) ">
          <span class="bg#warn">{local.category ?? "No category"}</span>
          <span class="bg-black">{local.status ?? "No status"}</span>
        </div> */}
        <div class="flex flex-col w-full items-start justify-between py-2">
          <span class="text-black font-bold whitespace-nowrap text-truncate h-fit">{local.title ?? "No Title"}</span>
          <p class="flex flex-col flex-wrap w-full justify-between text-0.5rem gap-y-2">
            <span>By: {local.instructor}</span>
            <span class="text-start text#orange">{`${dates.start.monthName} ${dates.start.time} - ${dates.end.monthName} ${dates.end.time}`}</span>
          </p>
        </div>
        <p class="text-black  text-16px h-full !overflow-y-auto overflow-x-hidden py-2 scrollbar">
          {local.description ?? "No description"}
        </p>
      </div>
    </a>
  );
}
