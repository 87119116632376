import type { AccessDeniedCollection } from "../_model";

function isLocalStorageDenied() {
  try {
    localStorage.length;
  } catch (e: any) {
    if (e.name === "SecurityError") {
      console.warn("Qunxios: access denied to localstorage!!");
      return true;
    }
  }
  return false;
}

export default function initAccessDenied(): AccessDeniedCollection {
  return {
    localstorageDenied: isLocalStorageDenied(),
  };
}

// function isCookiesDenied() {
//   try {
//     document.cookie = "asd=asd";
//   } catch (e: any) {
//     warn("access denied to cookies!!");
//     if (e.name === "SecurityError") {
//       return true;
//     }
//   }
//   return false;
// }
