import { solidstate } from ":shared/utils/state-manager";
import { get_video } from "./apis";
import { Platform } from ":shared/components/loaders";
import { Show, Switch, createMemo, createSignal } from "solid-js";
import { global } from ":global";
export default function LiveStage() {
  const $settings = global.store.hooks.platform.useSettings("live_stage");
  const $link = createMemo(() => $settings().values?.url);

  return (
    <section class="flex flex-row w-full h-full <md:(px-2 py-1 !rounded-xl)">
      <Show when={$link()} fallback={<div></div>}>
        <iframe
          src={$link()}
          class="min-w-full min-h-full <md:(!rounded-xl)"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </Show>
    </section>
  );
}
