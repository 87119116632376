import { Portal } from "solid-js/web";
import { $toast_group } from "./store";
import { ToastGroup as TG } from ":shared/components/alerts";
import "./style.css";

export default function ToastGroup() {
  return (
    <Portal>
      <TG alerts={$toast_group} />
    </Portal>
  );
}
