import { Table } from ":src/components/list";
import { _API } from "../_model";
import { EmptyTable } from "./empty-table";
import { global } from ":global";
import { createMemo } from "solid-js";

export type Props = {
  tickets: _API.requests.Ticket[];
  filter: { search?: string; issue_type?: string; status?: string };
  onResolveConfirmed: (props: { item: _API.requests.Ticket; index: number }) => void;
};

export default function TicketsTable(props: Props) {
  const $tickets = createMemo(() => {
    const result = [];
    const filter = props.filter;
    if (props.tickets == undefined || props.tickets.length <= 0) {
      return undefined;
    }
    for (let index in props.tickets) {
      const item = props.tickets[index];
      const search_values = `${item.requester},${item.mobile}`; // search keys
      let remove = false;
      if (filter.search && !search_values.includes(filter.search)) {
        remove = true;
      } else if (filter.status && item.status !== filter.status) {
        remove = true;
      } else if (filter.issue_type && item.issue_type !== filter.issue_type) {
        remove = true;
      }
      if (!remove) {
        result.push(item);
      }
    }
    return result;
  });
  return (
    <Table
      // dir="rtl"
      _items={$tickets()}
      _fallback={<EmptyTable msg="You do not have any ticket requests in your inbox!" />}
      _emptyKey="UNKOWN"
      _class={{
        desktop: {
          table: "border-spacing-y-2 border-spacing-x-1 text-black pt-0",
          thead: "h-2rem font-semibold text-0.77rem",
          theadTrTh: "px-5",
          theadBase: "bg#bg-default rounded-t-6",
          tbodyTr: "text-0.75rem",
          tbodyTrTd: "items-center py-2 px-5",
        },
        phone: {
          wrapper: "gap-y-3 py-2 px-0.5",
          card: "items-center shadow-md py-2 px-2 gap-y-2 rounded-xl !justify-between text-0.8rem text-black",
          header: "w-100px",
          cell: "justify-start overflow-x-auto",
        },
      }}
      _containers={{
        items: ({ item, itemIndex, screen }) => {
          // for when ticket is resolved
          if (item.status === "solved") {
            return {
              class: "!ring-green ring-2 ring-solid rounded-xl ",
            };
          }
          return {
            class: "!ring-gray-100 ring-2 ring-solid rounded-xl",
          };
        },
      }}
      _overlays={{
        items: ({ screen, item }) => {
          return (
            <div
              aria-label="item_name"
              class="w-full h-full rounded-xl cursor-pointer"
              onclick={() => {
                global.store.actions.popop.pushModal({
                  title: `${item.requester} | ${item.table_number}`,
                  // startExpanded: true,
                  element(action) {
                    return (
                      <section>
                        <span>{item.description}</span>
                      </section>
                    );
                  },
                });
              }}
            />
          );
        },
      }}
      _headers={{
        Requester: {
          key: ({ item, emptyKey }) => {
            return (
              <>
                <p class="flex flex-col mt-1.5">
                  <span class="font-bold text-0.8rem ">{`${item.requester ?? emptyKey}`}</span>
                </p>
              </>
            );
          },
        },
        Mobile: {
          key: "mobile",
          class: {
            desktop: {
              th: "min-w-200px",
            },
          },
        },
        Table: {
          key: "table_number",
          class: {
            desktop: {
              th: "min-w-50px w-50px max-w-50px",
            },
          },
        },
        "Issue Type": {
          key: "issue_type",
          class: {
            desktop: {
              th: "min-w-200px",
            },
          },
        },
        Status: {
          key: "status",
          class: {
            desktop: {
              th: "min-w-100px",
            },
          },
        },
        Action: {
          hide: ({ item }) => item && item.status === "solved",
          class: {
            desktop: {
              th: "min-w-100px",
            },
          },
          key: ({ screen, item, itemIndex }) => {
            // if (item.status === "solved") {
            //   return null;
            // }
            return (
              <div class="flex flex-row ">
                <span
                  class="text-green underline cursor-pointer"
                  onclick={() => {
                    global.store.actions.popop.pushConfirmDialog({
                      type: "warn",
                      message: `Are you sure this ticket is resolved for ${item.requester}?!`,
                      events: {
                        async onConfirmed() {
                          return props.onResolveConfirmed?.({ item, index: itemIndex() });
                        },
                      },
                    });
                  }}
                >
                  Resolve
                </span>
              </div>
            );
          },
        },
      }}
    />
  );
}
