import { global } from ":global";
import type { ApiResponse } from "./_model";

function generateRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export async function getStats(): Promise<ApiResponse.GetStats> {
  // return Promise.resolve(import("./get-stats.mocks")).then((r) => {
  //   return r.success;
  // });
  return global.api
    .getAuth("statistics/")
    .then(async (res) => {
      // const drnd = res.data?.daily_registration_number?.data;
      // for (const stat of drnd) {
      //   stat.y = generateRandomNumber(0, 100);
      // }
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
