import { For, Show, createEffect, createMemo, splitProps } from "solid-js";
import { createFormControl } from "solid-forms";
// import { animate } from "motion";
import type { MainProps } from "./_model";
import type { FormControlData, FormControlValue } from "../SHARED/_model";
import { animate } from "motion";

export default function Error<V extends FormControlValue, D extends FormControlData>(props: MainProps<V, D>) {
  const [local, others] = splitProps(props, ["class", "control"]);
  // const control = props.control;
  const _control = Array.isArray(local.control) ? createFormControl<V, D>(...local.control) : local.control;
  const $error = createMemo(() => {
    // const value = _control.value
    if (!_control.isValid) {
      if (_control.errors !== undefined && _control.errors !== null) {
        return Object.keys(_control.errors)?.[0];
      }
    }

    return "unknown error";
  });
  let div_ref = null;
  createEffect(() => {
    if (!_control.isValid) {
      animate(
        div_ref,
        {
          opacity: [0, 100],
        },
        { duration: 0.5, easing: "ease-in-out" }
      );
    }
  });
  return (
    <div {...props} ref={div_ref} class={`capitalize ${local?.class ?? ""}`}>
      <Show when={!_control.isValid}>{$error()}</Show>
    </div>
  );
}
