import { createFormActions, createInput } from ":shared/components/form";
import { auth } from ":shared/helpers/validations";
import { is_dev, mocks } from "../mocks/login";

export function createLoginInputs(mock?: keyof typeof mocks) {
  const m = is_dev && mock ? mocks[mock] : undefined;
  const Email = createInput(m?.email, {
    id: "Email",
    required: true,
    validators: (value) => {
      const err = auth.email.validate(value);
      // return { "wow what a very loong user error": true };
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const Password = createInput(m?.password, {
    id: "Password",
    required: true,
    validators: (value) => {
      const err = auth.password.validate(value);
      // console.log("validating password", value, " :: ", err);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const Actions = createFormActions({ Email, Password });
  return {
    Actions,
    Email,
    Password,
  };
}
