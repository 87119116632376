import type { SignalOptions, Accessor, createComputed } from "solid-js";
import type { StateEventsInstance } from "./create-events";
//
import { createSignal, createMemo, createRoot, untrack, runWithOwner } from "solid-js";
import { createEvents } from "./create-events";
import { getNewValue } from "./get-new-value";
import { unwrap } from "solid-js/store";
import clone from "clone";
import { getOwner } from "./helpers";

// export function createGlobal<T>(...args: Parameters<typeof create<T>>) {
//   // there's no point defining global owner because there's non
//   // args[2] = !args[2] ? getOwner() : args[2];
//   const func = () => create<T>(...args);
//   return createRoot(func);
// }

//? states
export default function create<T>(value: T, options?: SignalOptions<T>) {
  const { owner, ownerMeta, noOwner } = getOwner();
  // ensure that the state is running with owner
  function _getState() {
    return runWithOwner(owner, () => {
      const [$value, $set] = createSignal(value, options);
      const state = {
        hasBeenUsedBefore: false,
        valueUntracked: undefined as T,
        eventHandler: undefined as StateEventsInstance<T>,
      };
      return {
        get unwrap(): T {
          return untrack(() => $value());
        },
        set unwrap(v) {
          $set(v as any);
        },
        get clone() {
          return clone(unwrap($value()));
        },
        get cloneUntracked() {
          return untrack(() => this.clone);
        },
        get accessor(): Accessor<T> {
          if (!state.hasBeenUsedBefore) {
            state.hasBeenUsedBefore = true;
          }
          return $value;
        },
        get value() {
          if (!state.hasBeenUsedBefore) {
            state.hasBeenUsedBefore = true;
          }
          return $value();
        },
        set value(v) {
          $set(getNewValue(v));
        },
        get set() {
          return $set;
        },
        get clear() {
          return () => $set(undefined);
        },
        // non instigators
        owner: ownerMeta,
        hasBeenUsedBefore: () => state.hasBeenUsedBefore,
        get on() {
          if (!state.eventHandler) {
            state.eventHandler = createEvents($value, owner);
          }
          return state.eventHandler;
        },
        derive<G>(fn: (s: T) => G) {
          return createMemo(() => {
            const value = $value();
            return fn(unwrap(value));
          });
        },
      };
    });
  }

  if (noOwner || ownerMeta.global) {
    // console.log("solid state create global :: ", ownerMeta, " :: ", owner);
    return createRoot(_getState);
  }
  // console.log("solid state create  :: ", ownerMeta, " :: ", owner);
  return _getState();
}
