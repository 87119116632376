import { global } from ":global";
import { ENDPOINTS } from "./endpoints";
import type { ResourcesCardProps } from "../_model";

export async function get_resources(): Promise<ResourcesCardProps[]> {
  // return Promise.resolve(import("./get-resources.mocks")).then((r) => {
  //   return r.success;
  // });
  return global.api
    .getAuth(ENDPOINTS.get_resources)
    .then((res) => {
      let res_list = res.data as ResourcesCardProps[];
      res_list = res_list.sort((a, b) => b.id - a.id);
      return Promise.resolve(res_list);
    })
    .catch((err) => {
      // message: service.api.decode_error(err, "couldn't update password"),
      return Promise.reject(err.message);
    });
}
