import { Anchor, type _Navigation } from ":shared/components/route";
import { default as routes } from "../routes";

export default function Agenda(props: _Navigation.SideNavJSXItemProps) {
  return (
    <Anchor
      class={props.anchor?.class}
      setup={{
        to: {
          path: routes,
          base: props.base,
        },
        classes: {
          active: `${props.anchor.setup.classes.active}`,
        },
      }}
      triggers={{
        onBeforeNavigate() {
          console.log("agenda before navigation");
        },
        onAfterNavigate() {
          console.log("agenda after navigation");
        },
      }}
      components={{
        title: "Agenda",
        idleIcon: <i class="i-uil:schedule" />,
        activeIcon: <i class="i-uil:schedule" />,
      }}
    />
  );
}
