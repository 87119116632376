import { default as exhibition } from ":src/modules/exhibition/routes";
import { default as livestage } from ":src/modules/livestage/routes";
import { default as mentors } from ":src/modules/mentors/routes";
import { default as resources } from ":src/modules/resources/routes";
import { default as schedule } from ":src/modules/schedule/agenda/routes";
import { default as myteam } from ":src/modules/teaming/my-team/routes";
import { default as participants } from ":src/modules/teaming/participants/routes";
import { default as project_submission } from ":src/modules/teaming/project-submission/routes";
import { default as help } from ":src/modules/help-center/routes";
import { default as profile } from ":src/modules/profile/routes";
import { default as workshops } from ":src/modules/workshops/routes";
export default {
  BASE: "/participant",
  exhibition,
  livestage,
  mentors,
  resources,
  schedule,
  myteam,
  participants,
  project_submission,
  help,
  profile,
  workshops,
};
