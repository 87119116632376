// import "solid-devtools";
import "uno.css";
import "virtual:unocss-devtools";
import { render } from "solid-js/web";
import { EntryClient } from "./entry-client";
import { ErrorBoundary } from "solid-js";

const root = document.getElementById("ROOT_APP");
if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
  throw new Error(
    "Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?"
  );
}

render(
  () => (
    <ErrorBoundary
      fallback={(err, reset) => {
        console.log("GLOBAL APP ERROR :: ", err);
        return <div>fallback</div>;
      }}
    >
      <EntryClient />
    </ErrorBoundary>
  ),
  root!
);
