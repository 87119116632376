import { createFormActions, createInput } from ":shared/components/form";
import { auth } from ":shared/helpers/validations";

export function createPasswordResetInputs() {
  const Password = createInput("", {
    required: true,
    validators: (value) => {
      const err = !!value && auth.password.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const ConfirmPassword = createInput("", {
    required: true,
    validators: (value) => {
      const original_password = Password.control.value;
      const err = !!value && original_password !== value && "Password not matched";
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });

  const Actions = createFormActions({ password: Password, confirm_password: ConfirmPassword });
  return {
    Actions,
    Password,
    ConfirmPassword,
  };
}
