import { For, Show } from "solid-js";
import type { ExhibitionCardProps } from "../_model";

export function ExploreProject(props: ExhibitionCardProps) {
  return (
    <div class="w-full h-full p-4 flex flex-row gap-2">
      <div class="w-full h-full !md:w-70% w-full h-full  flex items-center">
        <Show
          when={props?.project_images}
          fallback={
            <div class="w-full text-black flex justify-center items-center font-bold text-xl">No PDF Provided</div>
          }
        >
          <iframe src={props?.project_images} class="w-full h-full" />
        </Show>
      </div>
      <div class="w-full !md:w-30% flex flex-col px-2 overflow-y-scroll  ">
        <h2 class="bg#orange text-white font-bold text-16px rounded-md px-2 py-2 ">Team</h2>
        <h2 class="text-black font-[600] text-16px rounded-md px-2 pt-2 ">Team Name</h2>
        <p class="text-black font-[400]  text-16px rounded-md px-2 pb-3">{props.team_name ?? "No team name"}</p>
        <h2 class="text-black font-[600] text-16px rounded-md px-2 pt-2">Track</h2>
        <p class="text-black font-[400]  text-16px rounded-md px-2 pb-2">{props.track ?? "No track"}</p>
        <h2 class="text-black font-[400] text-16px rounded-md px-2 pt-3 ">Team Members</h2>

        <div class="pt-[7px] pb-[12px] px-2 flex flex-col gap-1">
          <For each={props?.team_members}>
            {(member) => {
              return (
                <div class="flex justify-start gap-2 items-center ">
                  <div class={`w-10px h-10px rounded-full bg#p! }`} />
                  <p class={`text-[14px] py-3 md:py-0 text-start text-black `}>{member?.name ?? "No name"}</p>
                </div>
              );
            }}
          </For>
        </div>

        <h2 class="bg#orange text-white font-bold text-16px rounded-md px-2 py-2 mt-3 ">Project</h2>
        <h2 class="text-black font-[600] text-16px rounded-md px-2 pt-2 ">Project Name</h2>
        <p class="text-black font-[400]  text-16px rounded-md px-2 pb-2">{props.project_name ?? "No project name"}</p>
        <h2 class="text-black font-[600] text-16px rounded-md px-2 pt-2 ">Project Description</h2>
        <p class="text-black font-[400]  text-16px rounded-md px-2 " pb-2>
          {props.ideation}
        </p>
      </div>
    </div>
  );
}
