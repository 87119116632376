import type { RequestFormProps } from "../_model";
import { send_request } from "../apis";
import { createRequestFromInputs } from "../forms";
import { global } from ":global";

export function RequestForm(props: RequestFormProps) {
  const FormInputs = createRequestFromInputs();

  async function onSubmit(e: Event) {
    const values = FormInputs.Actions.getValuesSnakecase();
    values["issue_type"] = props.issue_type;
    console.log("values as payload:: ", JSON.stringify(values));
    return send_request(JSON.stringify(values)).then(() => {
      props.triggers?.onSubmitted?.();
      global.store.actions.alert.pushToast({
        type: "success",
        message: "Request Sent, we'll be with you shortly inshallah",
      });
    });
  }

  return (
    <div class="h-fit px-2 py-3 md:(min-w-26rem) <md:(w-full)">
      <h1 class="text-center font-bold text-black text-26px">Request On-Site Help</h1>
      <p class="text-center py-2 font-[500] text-black text-18px">
        Enter your information details so we can reach you out 😄
      </p>

      <form class="w-full h-full md:(space-x-1.5) ">
        <div class="flex  flex-row justify-between items-center <md:(!flex-col !items-start) ">
          {/* table number input */}
          <div class="relative w-20%  py-1 <md:(w-40%)">
            <FormInputs.TableNumber.Label title="Table Number" class="form-label">
              <FormInputs.TableNumber.Error class="form-input-error" />
            </FormInputs.TableNumber.Label>

            <FormInputs.TableNumber.Select
              options={props?.tables_range}
              class="form-select px-3 py-2! outline-xl rounded-9px my-2  text-0.7rem!"
              placeholder="Table No."
            />
          </div>
          {/* Phone number */}
          <div class="w-75% py-1 <md:(w-full)">
            <FormInputs.Mobile.Label title="Phone Number" class="form-label">
              <FormInputs.Mobile.Error class="form-input-error" />
            </FormInputs.Mobile.Label>
            <FormInputs.Mobile.Input type="tel" class="form-input" placeholder="05xxxxxxxx" />
          </div>
        </div>
        {/* issue description */}
        <div class="w-full ">
          <div class="flex flex-row gap-1 my-1">
            <FormInputs.Description.Label
              title="Describe your issue "
              class="form-label"
            ></FormInputs.Description.Label>
            <FormInputs.Description.Error class="form-input-error" />
          </div>

          <FormInputs.Description.TextArea
            autocomplete="Describe_your_issue "
            class="text-0.7!rem  min-h-130px  min-w-99% p-5px form-input px-3 py-2! mt-2 outline-none rounded-9px"
            placeholder="write a brief about your issue"
          />
          <p class="text-0.5rem text-rose-300">
            please describe your problem in either arabic or english and be as specific as possible
          </p>
        </div>

        <div class="flex justify-center">
          <FormInputs.Actions.Button
            class="disabled:bg#disabled text-white  flex items-center justify-center text-16px font-bold cursor-pointer bg#orange mt-7 px-30px py-2 rounded-md"
            onclick={onSubmit}
            statusValid={"Submit Request"}
            statusInvalid={"Submit Request"}
          />
        </div>
      </form>
    </div>
  );
}
