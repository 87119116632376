import { global } from ":global";
import type { _Guarded } from ":shared/components/route";
import { platform$ } from ":src/modules/platform";
import { default as auth_routes } from "../../auth/routes";

// TODO: check token only once across routes
// TODO: disable token checks on /auth but not /auth/login
// TODO: store non critical indicators in local storage to know this
export const checkPlatformSettings: _Guarded.AGuardStep = {
  runOn: ["app_init", "first_load"],
  loaderMsg: "loading platform settings...",
  async checker({ state, base, routeMatchBase, location }) {
    // return
    let result: _Guarded.AGuardStepCheckerError = undefined;
    await platform$.apis.getPlatformSettings().catch(async (error) => {
      console.error("error from check platform :: ", error);
      await global.api.auth.logout();
      result = {
        message: "an error occured while loading platform settings",
        error,
        // navigate: {
        //   base: auth_routes.BASE,
        //   path: auth_routes.login,
        // },
      };
    });

    return result;
  },
};
