import IconEmptyTeams from "../assets/icons/empty-teams.svg";

export function EmptyTable(props?: { msg?: string }) {
  return (
    <div class="!self-center flex flex-col w-full h-full items-center justify-center my-auto space-y-10 pt-4 pb-2">
      <IconEmptyTeams class="w-100px h-100px" />
      <span class="text-0.8rem text-gray text-center">{props.msg ?? "table has no records"}</span>
    </div>
  );
}
