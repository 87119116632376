import { Guarded, route$navigation } from ":shared/components/route";
import { Route } from "@solidjs/router";
import { Show } from "solid-js";
import { grading$judging, Judging } from ":src/modules/grading";
import { DashboardLayout } from ":src/layouts";
import routes from "./routes";
import { checkUserToken, checkPlatformSettings } from "../SHARED/guards";
import { account$signout } from ":src/modules/account";
import { HelpCenter, help_center$ } from ":src/modules/help-center";
import { Profile, profile$ } from ":src/modules/profile";
import { Loader1 } from ":src/components/loaders";

export default function Evaluator() {
  const baseRoute = routes.BASE;
  return (
    <Guarded
      setup={{
        baseRoute,
        guard: {
          steps: [checkUserToken, checkPlatformSettings],
        },
      }}
      components={{
        pageNotFound: () => <div>this is Evaluator page error, this page doesn't exist</div>,
        layout(props) {
          const { guard } = props;
          const flavor = global.contexts.whitelabel.useAppFlavor();

          return (
            <Show when={!guard.loading()} fallback={<Loader1 subtitle="Judge" msg={guard.msg()} />}>
              <DashboardLayout
                sideNav={{
                  setup: {
                    baseRoute,
                  },
                  components: {
                    items: [
                      grading$judging.Anchors.Side,
                      //
                      () => route$navigation.Elements.Filler(),
                      () => route$navigation.Elements.Divider({ class: "bg#a" }),
                      //
                      help_center$.Anchors.Side,
                      profile$.Anchors.Side,
                      account$signout.Anchors.Side,
                    ],
                  },
                }}
                children={props.children}
              />
            </Show>
          );
        },
      }}
    >
      <Route path={routes.judging} component={Judging} />
      <Route path={routes.help} component={HelpCenter} />
      <Route path={[routes.profile, "/"]} component={Profile} />
    </Guarded>
  );
}
