import { For, Portal, Show } from "solid-js/web";
import { $confirm_dialogs } from "./store";
import { Match, Switch, createMemo, splitProps } from "solid-js";
import { directives$ } from ":shared/components/directives";
import IconSuccess from "./assets/success.svg";
import IconErrorOrange from "./assets/error_orange.svg";
import IconError from "./assets/error.svg";
import IconInfo from "./assets/info.svg";
import IconLoader from "./assets/loader.svg";
import { solidstate } from ":shared/utils/state-manager";

export default function ConfirmDialog() {
  // @ts-ignore: unused function 'clickOutside'
  const { clickOutside } = directives$.events;
  function removeDialog(index: number) {
    $confirm_dialogs.set((s) => {
      s.splice(index, 1);
      return [...s];
    });
  }
  return (
    <Portal>
      <Show when={$confirm_dialogs.value.length > 0}>
        <main class={`!absolute bottom-0 !z-99 flex flex-col !items-center !justify-center w-full h-full bg-black/30`}>
          <For each={$confirm_dialogs.value}>
            {(props, index) => {
              const [local, other] = splitProps(props, [
                "type",
                "message",
                "note",
                "events",
                "cancel_msg",
                "confirm_msg",
              ]);

              const $loading = solidstate.create(false);
              return (
                //? outer container
                <section
                  use:clickOutside={() => {
                    removeDialog(index());
                    local.events?.onCancelled?.();
                  }}
                  class={`bottom-0 
                  flex flex-col w-40% h-fit 
                  self-center !items-center !justify-center  
                  !z-10 !my-1 
                  px-2 py-3
                  transition-all duration-300 ease-in-out
                  <md:(w-full)
                  `}
                >
                  {/* inner container */}
                  <section
                    class={`text-1rem
                      flex flex-col w-full h-full justify-between !self-center 
                      !z-50  overflow-hidden rounded-2xl 
                      bg-white text-black
                      px-4 pt-4 pb-4
                      `}
                  >
                    {/* icons and message */}
                    <div
                      class={`
                        flex flex-row items-center justify-center text-0.8rem mb-1 space-x-2
                        <md:(!flex-col space-y-2 mb-3)
                      `}
                    >
                      <div class="children:(w-6vw h-6vw rounded-lg px-3 <md:(!w-20vw !h-20vw) )">
                        <Switch fallback={<IconInfo />}>
                          <Match when={local.type === "warn"}>
                            <IconErrorOrange class="bg-orange-100" />
                          </Match>
                          <Match when={local.type === "error"}>
                            <IconError class=" bg-red-100" />
                          </Match>
                          <Match when={local.type === "success"}>
                            <IconSuccess class="!w-8vw !h-8vw <md:(!w-30vw !h-30vw)" />
                          </Match>
                        </Switch>
                      </div>
                      <p class="flex flex-col w-full font-bold text-justify text-0.9rem text-start <md:(text-0.8rem)">
                        {local.message ?? "MESSAGE MISSING"}
                        {local.note != undefined && (
                          <span class="before:(content-['note:_'] text-gray) font-normal mt-2 capitalize text-justify text-0.6rem text-start <md:(text-0.5rem) w-full">
                            {local.note}
                          </span>
                        )}
                      </p>
                    </div>
                    {/* <div class="flex flex-col w-full h-full overflow-auto">{local.element}</div> */}
                    <div
                      class={`
                        flex flex-row w-full capitalize items-center justify-center space-x-2 
                        children:(!w-full capitalize rounded-lg font-semibold text-0.55rem px-4 py-10px rounded-md cursor-pointer <md:(text-13px))
                        children:(flex flex-row items-center justify-center)
                        children:(<md:(text-13px))
                    `}
                    >
                      <button
                        class="text#txtgray border-2  border#txtgray border-solid"
                        onClick={() => {
                          removeDialog(index());
                          local.events?.onCancelled?.();
                        }}
                      >
                        {local.cancel_msg ?? "No, Cancel"}
                      </button>
                      <button
                        class={`text-white
                        ${local.type === "info" ? "bg#info " : ""}
                        ${local.type === "warn" ? "bg#warn " : ""}
                        ${local.type === "error" ? "bg#error " : ""}
                        ${local.type === "success" ? "bg#success " : ""}
                        `}
                        onClick={async () => {
                          try {
                            $loading.set(true);
                            await Promise.resolve(local.events?.onConfirmed?.());
                            removeDialog(index());
                          } catch (e) {}
                          $loading.set(false);
                        }}
                      >
                        <Show when={$loading.value} fallback={local.confirm_msg ?? "Yes, Confirm"}>
                          <IconLoader class="h-24px animate animate-spin" />
                        </Show>
                      </button>
                    </div>
                  </section>
                </section>
              );
            }}
          </For>
        </main>
      </Show>
    </Portal>
  );
}
