import { global } from ":global";

export type Ticket = {
  id: number;
  table_number: number;
  mobile: string;
  status: "pending" | "solved";
  requester: string;
  solved_by: string;
  description: string;
  issue_type: string;
  created_at: string;
  updated_at: string;
};
export type GET = {
  returns: Ticket[];
};

export const endpoint = (id?: number) => (id == undefined ? "support/requests/" : `support/requests/${String(id)}/`);
export async function get() {
  return Promise.resolve(
    import("./mocks.requests").then((e) => {
      return Promise.resolve(e.get);
    })
  );

  return global.api.getAuth<GET["returns"]>(endpoint()).then((res) => {
    return res.data;
  });
}

export type PUT = {
  props: {
    id: number;
  };
};
export async function put(props: PUT["props"]) {
  return global.api.putAuth(endpoint(props?.id), { status: "solved" }).then((res) => {
    return res.data;
  });
}
