import { Switch, Match, Show, createEffect } from "solid-js";
import type { MainProps } from "./_model";
import { SideNav } from ":shared/components/route";
import { global } from ":global";
import { solidstate } from ":shared/utils/state-manager";

//
import "./style/nav-side.css";

export default function DashboardLayout(props: MainProps) {
  const $breakpoint = global.store.hooks.dom.useBreakpoints();
  const $show_sidenav = solidstate.create(false);
  global.store.triggers.route.onNavigated(() => {
    if ($show_sidenav.value) {
      $show_sidenav.set(false);
    }
  });

  // createEffect(() => {
  //   if ($breakpoint()?.misc.md.widthIsLess) {
  //     $show_sidenav.set(true);
  //   }
  // });
  return (
    <main class={`flex flex-col w-full h-full space-y-2`}>
      <Switch fallback="awaiting breakpoint layout...">
        {/* phone view */}
        <Match when={$breakpoint()?.misc.lg.widthIsLess}>
          <section class="relative flex flex-col !z-1 w-full h-full">
            {props.childrenTop}
            {/* burger area */}
            <div class="relative flex flex-row w-full h-2.5rem bg#p px-4 items-center justify-end">
              {/* idle */}
              <div
                class={`flex flex-row w-full h-full justify-between items-center transition transition-all duration-300 ease-in-out !z-100
                    ${$show_sidenav.value ? "hidden" : ""}`}
                onclick={() => $show_sidenav.set((s) => !s)}
              >
                <i class="i-iconamoon:menu-burger-horizontal-fill w-1rem h-1rem text#a" />
                <img class="w-4rem h-full !z-99 mt-2" src="/logo.svg" alt="logo" />
              </div>
              {/* bg shade */}
              <div
                class={`fixed top-0 left-0 bg-black/30 min-w-full w-full h-full !z-99
                      ${$show_sidenav.value ? "" : "hidden"}`}
              />
              {/* slided */}
              <div
                class={`
                  fixed top-0 -left-100% grid grid-cols-[80%_20%] w-full h-full !z-100 !overflow-hidden
                  transition transition-all duration-300 ease-in-out 
                  ${$show_sidenav.value ? "!left-0" : ""}
                  `}
              >
                {/* the menu itself */}
                <div class="flex flex-col w-full min-h-full bg#p pl-2 pt-2 space-y-2">
                  <img class="self-center <sm:(w-4rem) sm:(w-7rem) !z-1" src="/logo.svg" alt="white_logo" />
                  <SideNav
                    {...props.sideNav}
                    triggers={{
                      ...props.sideNav?.triggers,
                      onClickedOutside: () => {
                        if ($show_sidenav.value) {
                          $show_sidenav.set(false);
                        }
                        props.sideNav?.triggers?.onClickedOutside?.();
                      },
                    }}
                    class={`nav-side ${props.sideNav.class ?? ""}`}
                  />
                </div>
                {/* close button */}
                <div class="flex flex-col w-full h-full self-center items-center">
                  <i class="fixed i-lets-icons:close-ring-duotone relative text#a sm:(w-3.5rem h-3.5rem mt-4rem) <sm:(w-2rem h-2rem mt-2.5rem)" />
                </div>
              </div>
            </div>
            <div class="w-full max-w-full min-w-full h-full  !overflow-hidden  bg#pt text#p !z-0">{props.children}</div>
          </section>
        </Match>
        {/* desktop/all-views view */}
        <Match when={true}>
          <section class="flex flex-row w-full h-full !overflow-hidden">
            <div class="flex flex-col w-15rem min-w-12.5rem min-h-full bg#p  pt-4 px-2.5 scrollbar">
              <img class="w-7rem mb-5 self-center" src="/logo.svg" alt="logo" />
              <SideNav {...props.sideNav} class={`nav-side ${props.sideNav.class ?? ""}`} />
            </div>
            <div class="flex flex-col w-full h-full !overflow-hidden">
              {props.childrenTop}
              <div class="w-97% max-w-97% min-w-97% h-full ml-3 my-4  rounded-2xl !overflow-hidden bg#pt text#p">
                {props.children}
              </div>
            </div>
          </section>
        </Match>
      </Switch>
    </main>
  );
}
