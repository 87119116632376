import { solidstate } from ":shared/utils/state-manager";
import { For, Match, Show, Switch, createMemo, splitProps } from "solid-js";
import { global } from ":global";

import { Loader2 } from ":src/components/loaders";
import { DropDown, SearchTool } from ":src/components/form";
import { requests } from "./apis";
import { TicketsTable } from "./components";
export default function OnSiteSupport() {
  const $tickets = solidstate.createAsync(requests.get);
  const $filters_options = createMemo(() => {
    const issue_types = {} as Record<string, { id: number; value: string }>;
    const status = {} as typeof issue_types;
    $tickets.value?.forEach((item, index) => {
      issue_types[item.issue_type] = { id: index, value: item.issue_type };
      status[item.status] = { id: index, value: item.status };
    });
    return {
      issue_type: Object.values(issue_types),
      status: Object.values(status),
    };
  });
  const $applied_filter = solidstate.create({} as { search?: string; issue_type?: string; status?: string });
  return (
    <section class="flex flex-col w-full h-full overflow-y-hidden overflow-x-hidden md:(px-5 py-3) <md:(px-3 py-2)">
      <h1 class="text-1.1rem  whitespace-nowrap  <md:text-1rem">On-Site Support Tickets</h1>
      {/* title and tabs */}
      <div class="flex flex-row   min-w-full justify-between py-2 md:(space-x-2) <md:(!flex-col flex-wrap space-y-2) ">
        <div class="flex w-full">
          <SearchTool onSearch={({ value }) => $applied_filter.set((s) => ({ ...s, search: value }))} />
        </div>
        <div class=" w-full md:w-[60%]! flex flex-row gap-3 justify-center items-center">
          <p class="text-black text-[18px] min-w-fit">Filter by</p>
          {/* <DropDown label="All Tracks" options={CategoryList} onValueChoose={handleFilteredData} /> */}
          <DropDown
            //
            label="Status"
            options={$filters_options()?.status}
            onValueChoose={(value) => $applied_filter.set((s) => ({ ...s, status: value }))}
          />
          <DropDown
            //
            label="Issue Type"
            options={$filters_options()?.issue_type}
            onValueChoose={(value) => $applied_filter.set((s) => ({ ...s, issue_type: value }))}
          />
        </div>
      </div>
      {/* list -- whatever it is */}
      <Switch>
        <Match when={$tickets.state.loading}>
          <Loader2 msg="Loading Support Requests..." />
        </Match>
        <Match when={$tickets.state.failed}>error occured while loading memebres {$tickets.error}</Match>
        <Match when={$tickets.state.success}>
          <TicketsTable
            tickets={$tickets.value}
            filter={$applied_filter.value}
            onResolveConfirmed={({ item, index }) => {
              return requests.put({ id: item.id }).then((data) => {
                global.store.actions.alert.pushToast({
                  type: "success",
                  message: `Ticket resolved, thank you champ!`,
                });
                $tickets.set((s) => {
                  const tickets = [...s];
                  tickets[index] = { ...item, status: "solved" };
                  return tickets;
                });
              });
            }}
          />
        </Match>
      </Switch>
    </section>
  );
}
