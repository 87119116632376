import { For, Show, createMemo, splitProps } from "solid-js";
import type { SideNavAccordionItem, SideNavProps } from "./_model";
import type { _Anchor } from "../anchor";
import { actions } from "../SHARED/store";
import { Accordion } from ":shared/components/foldable";
import { Anchor } from "../anchor";
import { mergeRouteHref } from "../SHARED/helpers";
import { directives$ } from ":shared/components/directives";

export default function SideNav(props: SideNavProps) {
  const [local, other] = splitProps(props, ["setup", "components", "options", "triggers", "class"]);
  const $status = createMemo(() => local.options?.status);
  // @ts-ignore
  const { clickOutside } = directives$.events;

  return (
    <nav
      {...other}
      use:clickOutside={() => {
        local.triggers?.onClickedOutside?.();
      }}
      class={`
    ${local.class ?? ""} 
    ${$status() === "hide" ? " children:!invisible " : ""}
    `}
    >
      {/* navs */}
      <div class="anchor-group">
        <Show when={!local.components?.layer} fallback={local.components?.layer}>
          <For each={local.components?.items}>
            {(item) => {
              if (typeof item === "function") {
                return (
                  <>
                    {item({
                      actions: {
                        ...actions,
                      },
                      base: local.setup?.baseRoute,
                      anchor: {
                        class: "anchor",
                        setup: {
                          // to: item.to,
                          classes: {
                            active: "active",
                          },
                        },
                        // activeClass="active"
                      },
                    })}
                  </>
                );
              }
              if (Array.isArray(item)) {
                const [title, ...items] = item;
                const options: SideNavAccordionItem[0] = typeof title === "string" ? { title: title } : title;
                return (
                  <Accordion
                    title={options.title}
                    class="accordion"
                    groupClass="group"
                    titleClass="title"
                    immediateLoad={options.immediateLoad}
                    dontCollapseOnOutsideClick
                  >
                    <For each={items}>
                      {(item) => {
                        return <InternalAnchor {...item} baseRoute={local.setup?.baseRoute} />;
                      }}
                    </For>
                  </Accordion>
                );
              }

              return <InternalAnchor {...item} baseRoute={local.setup?.baseRoute} />;
            }}
          </For>
        </Show>
      </div>
    </nav>
  );
}

function InternalAnchor(item: _Anchor.Item & { baseRoute: string }) {
  const to = item.setup?.to && mergeRouteHref({ base: item?.baseRoute }, item.setup.to);
  return (
    <Anchor
      {...item}
      class={`anchor ${item.class ?? ""} `}
      setup={{
        ...item.setup,
        to: to,
        classes: {
          ...item.setup?.classes,
          active: `active ${item.setup?.classes?.active ?? ""}`,
        },
      }}
    />
  );
}
