import { global } from ":global";

export type GET = {
  props: never;
  returns: {
    id: number;
    by: string;
    type: string;
    action: string;
    created_at: string;
  }[];
};

export const endpoint = "/teaming/team-history/";
export async function GET(): Promise<GET["returns"]> {
  return global.api.getAuth(endpoint).then((res) => {
    return res.data;
  });
}
