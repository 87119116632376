import { Route } from "@solidjs/router";
import { checkUserToken } from "../SHARED/guards";
import { Guarded, PageNotFound } from ":shared/components/route";
import { PasswordReset, Signin } from ":src/modules/account";
import { default as routes } from "./routes";
import { AuthLayout } from ":src/layouts";
import { Loader1 } from ":src/components/loaders";

// TODO: put otp in it's own path while being under create account, use search params
export default function Auth() {
  const baseRoute = routes.BASE;
  return (
    <Guarded
      setup={{
        baseRoute,
        guard: {
          steps: [checkUserToken],
        },
      }}
      components={{
        layout: (props) => {
          const { guard } = props;

          return <AuthLayout children={guard.loading() ? <Loader1 msg={guard.msg()} /> : props.children} />;
        },
        pageNotFound: () => <PageNotFound path={routes.login} base={baseRoute} duration={0} />,
      }}
      events={{
        onGuardSuccess: (trigger, { state, location, isBaseRoute }) => {
          if (isBaseRoute) {
            // if (global.api.auth.token.helpers.exists()) {
            //   global.api.auth.events.redirectToUserRouteBase();
            // }
            trigger.loadRoute();
          } else {
            trigger.loadRoute();
          }
        },
        onGuardFailure: (trigger, { error, stepEquals, isBaseRoute, routeMatchUnderBase }) => {
          // console.warn("user has no token ", error);

          if (stepEquals(checkUserToken) && isBaseRoute) {
            if (routeMatchUnderBase(routes.login)) {
              trigger.loadRoute();
            } else {
              trigger.navigate(routes.login);
            }
          } else {
            // console.warn("unhandled route error case in ", error);
            trigger.loadRoute();
          }
        },
        onRouteCleanup: (trigger, { location }) => {
          // console.log("cleaning auth route , ", location, " :: ", prevLocation);
        },
        onRouteChange: (trigger, { location }) => {
          // console.log("changing auth route , ", location, " :: ", prevLocation);
        },
      }}
    >
      {() => {
        return (
          <>
            <Route path={routes.login} component={() => <Signin />} />
            <Route path={routes.password_reset} component={() => <PasswordReset signinRoute={routes.login} />} />
            {/* <Route path={ROUTES.auth.signup} element={<Signup {...props} />} /> */}
          </>
        );
      }}
    </Guarded>
  );
}
