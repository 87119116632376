import IconNoProfileImage from "../assets/icons/no-profile-picture.svg?string";
//
import { Table } from ":src/components/list";
import { EmptyTable } from "../components/empty-table";

//
import type { _SolidState } from ":shared/utils/state-manager";
import type { _Api } from "../../SHARED/_model";
import { createMemo } from "solid-js";
import { global } from ":global";

export type Props = {
  details: _Api._UserRequests.Get["returns"];
};

export function TableTeamMembers(props: Props) {
  const jwt = global.api.auth.token.decoded();
  const $info = createMemo(() => {
    const user_requests = props.details;
    return {
      role: user_requests.role,
      members: user_requests.team_members,
    };
  });

  return (
    <Table
      // dir="rtl"
      _items={$info().members}
      _fallback={<EmptyTable msg="You do not have any member in your team currently " />}
      _emptyKey="UNKOWN"
      _class={{
        desktop: {
          table: "border-spacing-y-2 border-spacing-x-1 text-black pt-0",
          thead: "h-2rem font-semibold text-0.77rem",
          theadTrTh: "px-5",
          theadBase: "bg#bg-default rounded-t-6",
          tbodyTr: "text-0.75rem",
          tbodyTrTd: "items-center py-2 px-5",
        },
        phone: {
          wrapper: "gap-y-3 py-2 px-0.5",
          card: "items-center shadow-md py-2 px-2 gap-y-2 rounded-xl !justify-between text-0.8rem text-black",
          header: "w-100px",
          cell: "justify-start overflow-x-auto",
        },
      }}
      _containers={{
        items({ item }) {
          if (item.email === jwt.email_address) {
            return null;
          }
          return {
            class: "!ring-gray-100 ring-1 ring-solid rounded-xl",
          };
        },
      }}
      _overlays={{
        items: ({ screen, item }) => {
          if (item.email === jwt.email_address) {
            if (screen === "desktop") {
              return (
                <div class="!border-black border-1 border-solid w-full h-full rounded-xl overflow-hidden">
                  <p class="animate animate-pulse text-3 px-1.5 pt-1 w-fit h-fit -translate-x-10px -rotate-50 text-blue bg-black">
                    You
                  </p>
                </div>
              );
            }
            return (
              <div class="!border-black border-1 border-solid w-full h-full rounded-xl overflow-hidden">
                <p class="animate animate-pulse text-3 px-1.5 pt-1 w-fit h-fit -translate-x-10px -rotate-50 text-blue bg-black">
                  You
                </p>
              </div>
            );
          }
          return null;
        },
      }}
      _headers={{
        User: {
          hideHeader: {
            phone: true,
          },
          key: ({ item, emptyKey }) => {
            const image = typeof item.image !== "string" ? IconNoProfileImage : item.image;
            return (
              <div class="flex flex-row w-full h-full">
                <img class="w-80px h-80px rounded-full object-cover z-0" src={image} alt={"profile_image"} />
                <p class="flex flex-col mx-3 mt-1.5">
                  <span class="font-bold text-0.8rem ">{`${item.name ?? emptyKey}`}</span>
                  <span>{`${item.reference ?? "#000000"}`}</span>
                </p>
              </div>
            );
          },
        },
        Role: {
          class: {
            desktop: {
              td: "min-w-100px",
            },
          },

          key: ({ item, emptyKey }) => (
            <span
              class={`capitalize rounded-xl px-2 py-1 text-0.64rem  <md:(text-0.6rem !self-end)
            ${item.role === "leader" ? "bg-orange-100 text-orange-400" : "bg-[#85B1AD40] text-[#85B1AD]"}`}
            >
              {item.role ?? emptyKey}
            </span>
          ),
        },
        "Email Address": {
          key: "email",
          // overlayBelow: true,
          // hide: true,
        },
      }}
    />
  );
}
