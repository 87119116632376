import { For, onMount, splitProps } from "solid-js";
import { animate } from "motion";

export default function TextRoller(props: {
  word: string;
  delay?: number;
  distance?: number;
  duration?: number;
  initialDelay?: number;
  containerClass?: string;
  wordClass?: string;
  letterClass?: string;
}) {
  const words = props.word.split(" ");
  return (
    <p
      class={`flex flex-row w-full h-fit text-1.5rem text-center  <md:(text-0.9rem text-justify flex-wrap space-y-1.5 items-!center !justify-center) ${
        props.containerClass ?? ""
      }`}
    >
      <For each={words}>
        {(word, wordIndex) => {
          const letters = word.split("");
          return (
            <div
              class={`flex flex-row !w-full h-fit px-2 font-semibold space-x-0.6 !items-center justify-center <md:(space-x-0.5) ${
                props.wordClass ?? ""
              }`}
            >
              <For each={letters}>
                {(letter, index) => {
                  const i = index();
                  let ref = undefined;
                  onMount(() => {
                    setTimeout(() => {
                      animate(
                        ref as any,
                        {
                          y: [0, props.distance ?? 10, 0],
                        },
                        {
                          duration: props.duration ?? 0.5,
                          delay: props.delay ?? 0.01,
                          easing: "ease-in-out",
                          repeat: Infinity,
                        }
                      );
                      // s.finished.then(() => anim(s));
                    }, (props.initialDelay ?? 50) * (i + 1));
                  });
                  return (
                    <span ref={ref} class={` ${letter === " " ? "mx-5" : ""} ${props.letterClass ?? ""}`}>
                      {letter}
                    </span>
                  );
                }}
              </For>
            </div>
          );
        }}
      </For>
    </p>
  );
}
