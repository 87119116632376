import { getOwner as go } from "solid-js";

// if the following keys do not exist within a owner obejct, then the
// state should be global; aka not belonging to a component
type Root = {
  owner: Omit<Solid.Owner, "owner">;
  index: number;
  component?: { name: string; location: string };
  _component?: any;
};
type GetOwner = {
  returns: {
    owner: Solid.Owner;
    ownerMeta: {
      root: Root;
      roots: Root[];
      depth: number;
      loops: number;
      global: boolean;
      globalKeyNotFound: string;
    };
    noOwner: boolean;
  };
};
const GLOBAL_OWNER_INDICATORS = ["pure", "sources", "state", "updatedAt", "value"]; // "observers", "observersSlots", "sourcesSlots"
export default function getOwner(options?: { depth?: number }): GetOwner["returns"] {
  const owner = go();
  // console.log("owner :: ", owner.owner.component.name, " :: ", "component" in owner.owner);
  if (owner == undefined) {
    // console.warn("getOwner: owner is not found, weird!!");
    return { owner, ownerMeta: undefined, noOwner: true };
  }

  const result: GetOwner["returns"]["ownerMeta"] = {
    root: undefined,
    roots: [],
    depth: options?.depth ?? 3,
    loops: 0,
    global: false,
    globalKeyNotFound: undefined,
  };

  let owner_depth = owner?.owner;
  do {
    if (owner_depth == undefined || result.loops >= result.depth) {
      break;
    }
    result.loops++;
    const root: Root = {
      owner: undefined,
      index: result.loops,
      _component: (owner_depth as any)?.component,
    };
    // do owner meta logic
    if (root._component != undefined) {
      const comp = root._component;
      root.component = {
        name: comp?.name?.substring(comp?.name?.lastIndexOf("]") + 1),
        location: comp?.location,
      };
    }
    // remove nested object keys with the same name to prevent
    // possible infinite cycles and provide better objects
    const next_owner = owner_depth?.owner;
    // TODO: cleanup observers and sources
    // if ("observers" in owner_depth) {
    //   const observers = owner_depth.observers as Solid.Owner[]
    //   if (observers != undefined) {
    //   }
    // }

    delete owner_depth?.owner;
    delete owner_depth?.owned;
    result.roots.push(root);
    // move to next owner
    owner_depth = next_owner;
  } while (owner_depth != undefined && result.loops < result.depth);

  // then, add root component details
  if (result.roots != undefined && result.roots.length > 0) {
    result.root = result.roots.shift();
  }
  // if any of the valuse of GLOBAL_OWNER_INDICATORS do not exist within this
  // owner, then it's a global owner
  for (const key of GLOBAL_OWNER_INDICATORS) {
    if (!(key in owner)) {
      result.global = true;
      result.globalKeyNotFound = key;
      break;
    }
  }

  return { owner, ownerMeta: result, noOwner: false };
}
