import { global } from ":global";
import { getDate } from "../../SHARED/helpers/get-date";
import type { EvaluationData } from "../../SHARED/_model";

export async function getEvaluations(): Promise<EvaluationData> {
  // return Promise.resolve(
  //   import("./get-evaluations.mocks").then((r) => {
  //     const data = r.success as EvaluationData;
  //     data.c_keys = Object.keys(data.criteria);
  //     data.teams.forEach((t) => {
  //       // t.project_video = "https://www.youtube.com/watch?v=wrEK6mm4XpU&ab_channel=Abdodes";
  //       if (t.eval_time && t.eval_time.length > 0) {
  //         t.eval_time = getDate(t.eval_time);
  //       }
  //     });
  //     // const sample = r.success.teams[0];
  //     // console.log("asd :: ", sample.id);
  //     // for (let i = 0; i < 50; i++) {
  //     //   r.success.teams.push(sample);
  //     // }

  //     return data;
  //   })
  // );
  return global.api
    .getAuth("teaming/teams/")
    .then((res) => {
      const data = res.data as EvaluationData;
      data.c_keys = Object.keys(data.criteria);
      data.teams.forEach((t) => {
        // t.project_video = "https://www.youtube.com/watch?v=wrEK6mm4XpU&ab_channel=Abdodes";
        if (t.eval_time && t.eval_time.length > 0) {
          t.eval_time = getDate(t.eval_time);
        }
      });
      // store.eval.default.evaluator_data.set(data);
      return Promise.resolve(data);
    })
    .catch((err) => {
      // const message = service.api.decode_error(
      //   err,
      //   "couldn't fetch evaluation teams!"
      // );
      // store.alert.send({ type: "error", message, as_loader: false });
      return Promise.reject(err);
    });
}
