import { Show } from "solid-js";
import IconLockBold from "./assets/icons/lock-bold.svg";

export default function (props: { locked: boolean }) {
  return (
    <Show when={props.locked}>
      <div
        class={`-ml2
          absolute top-0 flex flex-row bg-black/45 w-full h-full opacity-80 hover:(bg-black/80 opacity-100) 
          group items-center justify-end rounded-md 
          border-1 border-white border-dashed
          px-1
        `}
      >
        <span class="w-full h-fit text-white items-center text-0.8rem text-center items-center justify-center invisible group-hover:(visible)">
          admin locked
        </span>
        <IconLockBold class="w-24px  text#pt" />
      </div>
    </Show>
  );
}
