import { solidstate } from ":shared/utils/state-manager";
import { getStats } from "./apis/get-stats";
import { Match, Switch, createEffect, onCleanup, onMount } from "solid-js";
import { Percentage } from "./components/percentage";
import { Card } from "./components/card";
import { Chart } from "./components/chart";
import { Loader2 } from ":src/components/loaders";
export default function Stats() {
  const refresh_rate = 10; // in seconds
  const $stats = solidstate.createAsync(getStats);
  const $refresh_after = solidstate.create(refresh_rate);
  let timer = undefined;
  createEffect(() => {
    const t = $refresh_after.value;
    if (t <= 0) {
      $stats.refetch();
    }
  });
  onMount(() => {
    timer = setInterval(() => {
      if ($stats.value) {
        if ($refresh_after.value <= 0) {
          $refresh_after.set(refresh_rate);
        } else {
          $refresh_after.set((s) => --s);
        }
      }
    }, 1000);
  });
  onCleanup(() => {
    if (timer) {
      clearTimeout(timer);
    }
  });
  return (
    <main class="flex flex-col w-full !min-h-full h-full px-5 pb-3 space-y-7 !overflow-y-auto overflow-x-hidden">
      <div class="flex flex-row w-full items-center justify-between">
        <h1 class="sticky top-0 text-22px md:text-xl md:text-2xl font-[600] text-black z-1 py-2 bg-white">
          Statistics
        </h1>
        <span class="<md:(text-0.6rem)">refreshing in {$refresh_after.value}</span>
      </div>
      <Switch>
        <Match when={$stats.value == undefined}>
          <Loader2 msg="Loading Resources Details..." />
        </Match>
        <Match when={$stats.state.failed}>an error occurred while fetching {$stats.error}</Match>
        <Match when={$stats.value}>
          <section class="flex flex-col w-full h-fit space-y-8 children:(flex flex-row justify-between items-center space-x-4 <md:(flex-col space-x-0 space-y-4))">
            <section>
              <Percentage {...$stats.value?.registration_tracking} />
            </section>
            <section>
              <Card {...$stats.value?.registration_and_acceptance} keyColor="orange" />
              <Card {...$stats.value?.number_of_teams_for_each_track} />
            </section>
            <section>
              <Percentage {...$stats.value?.acceptance_tracking} />
              <Percentage {...$stats.value?.team_formation_tracking} />
            </section>
            <section>
              <Card {...$stats.value?.number_of_accepted_for_each_track} keyColor="orange" />
              <Card {...$stats.value?.submission_and_judging} />
            </section>
            <div class="flex-col !space-x-0 space-y-4">
              <Percentage {...$stats.value?.submission_tracking} />
              <Percentage {...$stats.value?.judging_tracking} />
              <Percentage {...$stats.value?.evaluation_tracking} />
            </div>
          </section>
          <div class="flex !min-w-full  !min-h-full h-full">
            <Chart {...$stats.value?.daily_registration_number} />
          </div>
        </Match>
      </Switch>
    </main>
  );
}
