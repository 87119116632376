import nope from "nope-validator";
import type { StringValidator } from "./_model";

export const first_name: StringValidator = nope
  .string()
  .required("is required")
  .min(2, "must be 3 letters at least")
  .max(20, "limited to 20 letters");
export const last_name: StringValidator = nope
  .string()
  .required("is required")
  .min(2, "must be 3 letters at least")
  .max(20, "limited to 20 letters");
export const otp: StringValidator = nope.string().min(3, "must be only 4 digits");
