import PlusIcon from "./assets/icons/Plus.svg";
import CloseIcon from "./assets/icons/Close.svg";
import QuestionIcon from "./assets/icons/message-question.svg";
import SendIcon from "./assets/icons/send-2.svg";
//
import { For, Match, Show, Switch } from "solid-js";
import { RequestForm } from "./components/request-form";
import { solidstate } from ":shared/utils/state-manager";
import { get_questions, get_issue_types, get_table_range } from "./apis";
import { Accordion } from ":shared/components/foldable";
import type { FAQSProps } from "./_model";
import { Loader2 } from ":src/components/loaders";
import { global } from ":global";

export default function HelpCenter(props) {
  const $issues_list = solidstate.createAsync(get_issue_types);
  const $fqa_list = solidstate.createAsync(get_questions);
  const $tables_range = solidstate.createAsync(get_table_range);

  function onRequestClicked(card: any) {
    global.store.actions.popop.pushModal({
      title: `${card?.name}`,
      element: (action) => (
        <RequestForm
          triggers={{ onSubmitted: () => action("close") }}
          tables_range={$tables_range.value}
          issue_type={card?.id}
        />
      ),
      outerContainer: {
        class: "!w-fit",
      },
    });
  }

  return (
    <section class="flex flex-col w-full h-full px-5 py-3 gap-2  overflow-y-auto <md:(px-2 py-1)">
      <h1 class="text-xl  font-[600] text-black z-1 <md:(text-22px )">On-Site Support</h1>
      <Switch>
        <Match when={$fqa_list?.state?.loading}>
          <div class="flex w-full min-h-screen md:h-full items-center justify-center">
            <Loader2 msg="Loading Help Details..." />
          </div>
        </Match>
        <Match when={$fqa_list?.state?.failed}>an error occurred while fetching {$fqa_list.error}</Match>
        <Match when={$issues_list?.state?.failed}>an error occurred while fetching {$issues_list.error}</Match>
        <Match when={$fqa_list?.state?.success}>
          <div class="flex flex-col w-full h-fit">
            {/* <Show when={showRequestPopup()}>
              <div class="h-full w-full my-auto flex justify-center items-center  z-20 ">
                <RequestForm tables_range={tables()} issue_type={issueType()} onClose={onCloseRequest} />
              </div>
            </Show> */}
            <Show when={$issues_list?.state?.success}>
              <div class="grid  grid-cols-3 gap-4 w-full h-full <sm:(grid-cols-1 gap-y-4 mb-4) <lg:(grid-cols-2)">
                <For each={$issues_list.unwrap.value}>
                  {(card, i) => {
                    return (
                      <div class="relative flex flex-col w-full min-h-7rem max-h-7rem   rounded-xl bg-white shadow-md p-3 border-1 border-gray-200 border-solid overflow-hidden">
                        <div class="flex flex-row space-x-2 flex-1 items-center">
                          <QuestionIcon class="w-50px h-50px rounded-full bg#bg-pink p-14px  " />
                          <h3 class="text-black font-bold text-18px pt-2 pb-3">{card?.name}</h3>
                        </div>
                        <button
                          onClick={() => onRequestClicked(card)}
                          class="w-full mx-auto! text-white bg#orange py-[9px] rounded-md font-bold text-[16px] cursor-pointer flex justify-center items-center gap-0 "
                        >
                          <SendIcon class="w-25px mr-2" />
                          Request Help
                        </button>
                      </div>
                    );
                  }}
                </For>
              </div>
            </Show>

            <div class="flex flex-col w-full h-fit">
              <p class="uppercase font-[500]! text#green py-2 text-[16px] ">Frequently Asked Questions</p>
              <h3 class="font-[600] text-black py-2 ">Get your doubts answered</h3>
              <div class="w-full grid  grid-cols-2 gap-4 h-full pb-13 pt-6 <md:(grid-cols-1 py-10) ">
                <For each={$fqa_list.unwrap.value}>
                  {(section: FAQSProps) => {
                    return (
                      <Accordion
                        arrowMode="hidden"
                        class={`h-fit border-2px border-solid border#border_light rounded-md w-99% `}
                        activeClass="border#green"
                        groupClass="flex flex-row space-x-2 py-3 px-3"
                        title={({ isOpen }) => {
                          return (
                            <section class="flex flex-row justify-center items-center w-full text-16px">
                              <div class="font-700 font-semibold text-start w-full">
                                <h2 class="text-black text-16px font-600">{section.question}</h2>
                              </div>
                              {isOpen ? <CloseIcon class="w-15px h-15px" /> : <PlusIcon class="w-15px h-15px" />}
                              {/* <i class={`!w-24px !h-24px icon-local-top-nav:down-arrow-dark ${isOpen ? "" : "rotate-90"}`} /> */}
                            </section>
                          );
                        }}
                      >
                        <div class="flex flex-col gap-10px justify-between text-16px pt-1 pb-2.5 px-25px!">
                          <p class="text#txtgray ">{section.answer}</p>
                        </div>
                      </Accordion>
                    );
                  }}
                </For>
              </div>
            </div>
          </div>
        </Match>
      </Switch>
    </section>
  );
}
