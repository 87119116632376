import { global } from ":global";
import { Match, Show, Switch, createEffect, onCleanup, onMount } from "solid-js";
import type { ApiResponse } from "./_model";
import { solidstate } from ":shared/utils/state-manager";
import { getSubmissionDate } from "./apis";
import type { _AgnosticHelpers } from "@qundus.tc/agnostic.helpers";
import routes from "../project-submission/routes";
function getFutureDate() {
  return global.helper.date.getDateFrom(new Date(), "h", 1);
}
export default function Timer(props?: { noRedirect?: boolean }) {
  const $breakpoint = global.store.hooks.dom.useDedicatedBreakpoints("xs", "sm", "md");
  const $settings = global.store.hooks.platform.useSettings("submission");
  const countdown = solidstate.create(
    global.helper.date.getCountdown(new Date().toTimeString(), 60) as _AgnosticHelpers._Date.Countdown
  );
  let interval = undefined;

  onMount(() => {
    const date = $settings().values?.date;
    if (date) {
      countdown.set(global.helper.date.getCountdown(date, 60));
      if (interval) {
        clearInterval(interval);
      }
      interval = setInterval(function () {
        const { expired } = countdown.unwrap;
        if (!expired) {
          let ddd = global.helper.date.getCountdown(date, 60);
          if (ddd.expired)
            ddd = {
              d: 0,
              h: 0,
              m: 0,
              s: 0,
              expired: ddd.expired,
              almost_there: ddd.almost_there,
            };
          countdown.set(ddd);
        }
      }, 1000);
    }
  });

  onCleanup(() => {
    if (interval) {
      clearInterval(interval);
    }
  });
  function DrawDateSlot(props: { name: string; num: number; no_handle?: boolean }) {
    return (
      <div class="flex flex-row w-fit h-full space-x-2">
        <Switch>
          {/* phone */}
          <Match when={$breakpoint("sm", "xs")}>
            <p class="flex flex-col  items-center justify-center text-0.5rem space-y-0.3">
              <span class="font-bold text-0.5rem">{props.num}</span>
              <span>{props.name}</span>
            </p>
            {!props.no_handle && <div class="self-center !w-1.5px h-30px bg#a !opacity-60  !mr-1.5" />}
          </Match>
          {/* desktop */}
          <Match when={$breakpoint()}>
            <p class="flex flex-col  items-center justify-center ">
              <span class="font-bold text-0.77rem text-0.3rem">{props.num}</span>
              <span class="text-0.6rem ">{props.name}</span>
            </p>
            {!props.no_handle && <div class="self-center w-3px h-50px bg#a opacity-60  !mr-4" />}
          </Match>
        </Switch>
      </div>
    );
  }
  return (
    <Switch>
      {/* less than phoe */}
      <Match when={$breakpoint("xs")}>
        <div class="relative flex flex-col w-full h-4rem">
          <section class="relative flex flex-row self-center w-fit h-fit z-0 text#a  space-x-1.2 pt-2 ">
            <DrawDateSlot num={countdown?.value?.d} name="DAY" />
            <DrawDateSlot num={countdown?.value?.h} name="HRS" />
            <DrawDateSlot num={countdown?.value?.m} name="MIN" />
            <DrawDateSlot num={countdown?.value?.s} name="SEC" no_handle />
          </section>
          <section class="flex flex-row w-full h-full items-center justify-between pr-1 ">
            <span
              class={`text-0.35rem font-bold text#a ${countdown.value?.almost_there ? "animate animate-bounce" : ""}`}
            >
              {countdown.value?.expired
                ? "Submission is closed"
                : countdown.value?.almost_there
                ? "Submission is about to close"
                : "Until submission is closed"}
            </span>
            <Show when={!props?.noRedirect && !$settings().locked}>
              <button
                class="font-bold py-1.5 px-1 text-0.25rem rounded-md bg#a text#pt cursor-pointer whitespace-nowrap"
                onclick={() => {
                  global.store.actions.route.navigate(routes);
                }}
              >
                Submit Project
              </button>
            </Show>
          </section>
          <div class="absolute top-0 bg#a opacity-14 w-full h-full -z-1 opacity-10" />
        </div>
      </Match>
      {/* phone */}
      <Match when={$breakpoint("sm")}>
        <div class="relative flex flex-row w-full h-2.8rem  ">
          <section class="relative flex flex-row self-center w-fit h-fit z-0 text#a ml-3% pl-2 pr-1 ">
            <DrawDateSlot num={countdown?.value?.d} name="DAY" />
            <DrawDateSlot num={countdown?.value?.h} name="HRS" />
            <DrawDateSlot num={countdown?.value?.m} name="MIN" />
            <DrawDateSlot num={countdown?.value?.s} name="SEC" no_handle />
          </section>
          <section class="flex flex-row w-full h-full items-center justify-between pr-1">
            <span
              class={`text-0.35rem font-bold text#a ${countdown.value?.almost_there ? "animate animate-bounce" : ""}`}
            >
              {countdown.value?.expired
                ? "Submission is closed"
                : countdown.value?.almost_there
                ? "Submission is about to close"
                : "Until submission is closed"}
            </span>
            <Show when={!props?.noRedirect && !$settings().locked}>
              <button
                class="font-bold py-2 px-2 text-0.4rem rounded-md bg#a text#pt cursor-pointer whitespace-nowrap"
                onclick={() => {
                  global.store.actions.route.navigate(routes);
                }}
              >
                Submit Project
              </button>
            </Show>
          </section>
          <div class="absolute top-0 bg#a opacity-14 w-full h-full -z-1 opacity-10" />
        </div>
      </Match>
      {/* desktop */}
      <Match when={$breakpoint()}>
        <div class="relative flex flex-row w-full h-4.6rem py-4">
          <section class="relative flex flex-row self-center w-fit h-fit z-0 text#a ml-3% px-2">
            <DrawDateSlot num={countdown?.value?.d} name="DAY" />
            <DrawDateSlot num={countdown?.value?.h} name="HRS" />
            <DrawDateSlot num={countdown?.value?.m} name="MIN" />
            <DrawDateSlot num={countdown?.value?.s} name="SEC" no_handle />
          </section>
          <section class="flex flex-row w-full h-full items-center justify-between px-4">
            <span
              class={`text-0.7rem font-bold text#a ${countdown.value?.almost_there ? "animate animate-bounce" : ""}`}
            >
              {countdown.value?.expired
                ? "Submission is closed"
                : countdown.value?.almost_there
                ? "Submission is about to close"
                : "Until submission is closed"}
            </span>
            <Show when={!props?.noRedirect && !$settings().locked}>
              <button
                class="font-bold py-2 px-4 text-0.7rem rounded-xl bg#a text#pt cursor-pointer"
                onclick={() => {
                  global.store.actions.route.navigate(routes);
                }}
              >
                Submit Project
              </button>
            </Show>
          </section>
          <div class="absolute top-0 bg#a opacity-14 w-full h-full -z-1 opacity-10" />
        </div>
      </Match>
    </Switch>
  );
}
