import { splitProps } from "solid-js";
import type { MainProps } from "./_model";
import { createFormControl } from "solid-forms";
import type { FormControlData, FormControlValue } from "../SHARED/_model";

export default function TextArea<V extends FormControlValue, D extends FormControlData>(props: MainProps<V, D>) {
  const [local, others] = splitProps(props, ["control"]);
  const _control = Array.isArray(local.control) ? createFormControl<V, D>(...local.control) : local.control;
  return (
    <textarea
      {...others}
      classList={{
        invalid: !!_control.errors,
        touched: _control.isTouched,
        disabled: _control.isDisabled,
        // required: control.isRequired,
      }}
      value={_control.value as any}
      oninput={(e) => {
        _control.setValue(e.currentTarget.value as V);
      }}
      onblur={() => _control.markTouched(true)}
      onfocus={() => _control.markTouched(true)}
      onfocusin={() => _control.markTouched(true)}
      onfocusout={() => _control.markTouched(false)}
      disabled={_control.isDisabled}
      required={_control.isRequired}
    />
  );
}
