import { global } from ":global";

export type GET = {
  returns: {
    id: number;
    first_name: string;
    last_name: string;
    gender: string;
    email: string;
    track: string;
    user_type: number;
    first_sign_in: boolean;
    major: string;
    portfolio: string;
    bio: string;
    image: string;
  };
};

export const endpoint = "account/user/";
export async function get_user() {
  return global.api
    .getAuth<GET["returns"]>(endpoint)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err.message);
    });
}
