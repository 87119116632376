import IconNoProfileImage from "../assets/icons/no-profile-picture.svg?string";
//
import { Table } from ":src/components/list";
import { EmptyTable } from "../components/empty-table";
import { cancel_request } from "../apis";
import { global } from ":global";

//
import type { _Api } from "../../SHARED/_model";
import type { _SolidState } from ":shared/utils/state-manager";
import { createMemo } from "solid-js";

export type Props = {
  details: _Api._UserRequests.Get["returns"];
  onUpdate: (props: _Api._UserRequests.Get["returns"]) => void;
};

export function TableSentRequests(props: Props) {
  const $info = createMemo(() => {
    const user_requests = props.details;
    return {
      role: user_requests.role,
      requests: user_requests.sent_requests,
    };
  });
  return (
    <Table
      _items={$info().requests}
      _fallback={<EmptyTable msg="You do not have any invitations currently " />}
      _class={{
        desktop: {
          table: "border-spacing-y-2 border-spacing-x-1 text-black pt-0",
          thead: "h-2rem font-semibold text-0.77rem",
          theadTrTh: "px-5",
          theadBase: "bg#bg-default rounded-t-6",
          tbodyTr: "text-0.75rem",
          tbodyTrTd: "items-center py-2 px-5",
        },
        phone: {
          wrapper: "gap-y-3 py-2 px-0.5",
          card: "items-center shadow-md py-2 px-2 gap-y-2 rounded-xl !justify-between text-0.8rem text-black",
          header: "w-100px",
          cell: "justify-start overflow-x-auto",
        },
      }}
      _headers={{
        "Team Name": {
          hide: $info().role === "leader",
          key: ({ item }) => {
            return !item.team ? "No Team" : item.team;
          },
        },
        [$info().role === "leader" ? "User" : "Leader"]: {
          hideHeader: {
            phone: true,
          },
          key: ({ item }) => {
            const image = typeof item.image !== "string" ? IconNoProfileImage : item.image;
            return (
              <>
                <img class="w-80px h-80px rounded-full object-cover " src={image} alt={"profile_image"} />
                <p class="flex flex-col mx-3 mt-1.5">
                  <span class="font-bold text-0.8rem ">{`${item.name ?? "Unknown Name"}`}</span>
                  <span>{`${item.reference ?? "#000000"}`}</span>
                </p>
              </>
            );
          },
        },
        "Email Address": {
          key: "email",
        },
        Action: {
          key: ({ item }) => {
            return (
              <div class="flex flex-row space-x-2">
                <span
                  class="h-fit text-red underline cursor-pointer"
                  onclick={() => {
                    global.store.actions.popop.pushConfirmDialog({
                      type: "warn",
                      message: `Are you sure you want to withdraw request sent to ${item.team ?? item.name}?!`,
                      events: {
                        async onConfirmed() {
                          return cancel_request.get({ id: item.id }).then(() => {
                            global.store.actions.alert.pushToast({
                              type: "success",
                              message: $info().role === "leader" ? "invitation cencelled!" : "request cancelled!",
                            });
                            const data = { ...props.details };
                            data.sent_requests = $info().requests?.filter((x) => x.id !== item.id);
                            props.onUpdate?.(data);
                          });
                        },
                      },
                    });
                  }}
                >
                  Cancel
                </span>
              </div>
            );
          },
        },
      }}
    />
  );
}
