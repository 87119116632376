import { global } from ":global";
import { ENDPOINTS } from "./endpoints";
// TODO : add data type 
export async function send_request(data) {
  return global.api
    .postAuth(ENDPOINTS.send_request, data, {
      // TODO : add proper type 
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}