import type { Options } from "../_model";
import checkEndpointURL from "./check-endpoint-url";

export default function checkOptions(options: Options) {
  const result = options;
  result.baseUrl = checkEndpointURL(result.baseUrl, "baseURL");
  for (let ep in result?.auth?.endpoints) {
    const url = result.auth.endpoints[ep].url;
    result.auth.endpoints[ep].url = checkEndpointURL(url, "auth." + ep);
  }
  // global
  if (!("defaults" in result)) {
    result.defaults = {};
  }
  result.defaults.configKey = result.defaults.configKey ?? "QUNXIOS";
  result.defaults.responseError = result.defaults.responseError ?? "RES_ERR";
  result.defaults.requestError = result.defaults.requestError ?? "REQ_ERR";
  result.defaults.internalRequest = result.defaults.internalRequest ?? "INTERNAL_REQUEST";
  result.defaults.auth = result.defaults.auth ?? "AUTH";
  result.defaults.interceptors = result.defaults.interceptors ?? "INTERCEPTORS";

  // interceptors
  // auth
  if ("auth" in result) {
    if (!("defaults" in result.auth)) {
      // @ts-ignore
      result.auth.defaults = {};
    }
    result.auth.defaults.unauthorizedStatusCode = result.auth?.defaults?.unauthorizedStatusCode ?? 401;
    result.auth.defaults.ignoreUnauthorizedAccessStatusCodes = result.auth?.defaults
      ?.ignoreUnauthorizedAccessStatusCodes ?? [500];
    // token specific
    result.auth.defaults.expirationKey = result.auth.defaults.expirationKey ?? "exp";
    result.auth.defaults.invalidToken = result.auth.defaults.invalidToken ?? "clear token";
    result.auth.defaults.invalidTokenDecode = result.auth.defaults.invalidTokenDecode ?? "clear token";
    result.auth.defaults.localstorageTokenKey = result.auth.defaults.localstorageTokenKey ?? "qunxios_token";

    if (result.auth.mode === "localstorage") {
      result.auth.tokenExpirationTechnique = result.auth.tokenExpirationTechnique ?? "reactive";
    }
  }
  return result;
}
