import { solidstate } from ":shared/utils/state-manager";
import type { Setting } from "./_model";
import { createMemo } from "solid-js";

export const $platform_settings = solidstate.create(undefined as Setting[]);

function getSettingsFor(key: Setting["key"]) {
  const all_settings = $platform_settings.accessor();
  let result: { exist: boolean; values?: Setting; locked?: boolean } = { exist: false, locked: true }; // locking by default
  if (all_settings !== undefined && all_settings !== null && Array.isArray(all_settings) && all_settings.length > 0) {
    const key_settings = all_settings.find((x) => x.key === key);
    if (key_settings !== undefined && key_settings !== null) {
      result.exist = true;
      result.values = JSON.parse(JSON.stringify(key_settings)) as Setting;
    }
  }
  if (!result.exist) {
    console.error(`platform settings key not found ${key}`);
  } else if (result.values.active !== undefined && result.values.active !== null) {
    result.locked = !result.values.active;
  }
  return result;
}

export const actions = {
  getSettingsFor,
  getTracks() {
    const tracks = getSettingsFor("tracks");
    return {
      exist: tracks?.exist ?? false,
      values: (tracks?.values?.value as string[] | undefined) ?? undefined, // ["well being", "sustainablitiy", "awards"]
    };
  },
  getProjectSubmission() {
    type ResultKeyValue = ReturnType<typeof getSettingsFor>["values"];
    const result = {
      valid_settings: false,
      null_message: "UNKNOWN",
      team_min: null as ResultKeyValue,
      team_max: null as ResultKeyValue,
      submission: null as ResultKeyValue,
    };
    try {
      const team_min = getSettingsFor("team_min");
      const team_max = getSettingsFor("team_max");
      const submission = getSettingsFor("submission");
      if (!team_min.exist || team_min.values?.value == undefined) {
        return result;
      } else {
        team_min.values.value = parseInt(team_min.values.value);
        if (Number.isNaN(team_min.values.value)) {
          console.warn("getProjectSubmission: detected non int team_min settings value");
          return result;
        }
      }
      if (!team_max.exist || team_max.values?.value == undefined) {
        return result;
      } else {
        team_max.values.value = parseInt(team_max.values.value);
        if (Number.isNaN(team_max.values.value)) {
          console.warn("getProjectSubmission: detected non int team_max settings value");
          return result;
        }
      }
      if (!submission.exist || submission.values?.url == undefined) {
        console.warn("getProjectSubmission: submission data or url is not provided");
        return result;
      }
      result.valid_settings = true;
      result.team_min = team_min.values;
      result.team_max = team_max.values;
      result.submission = submission.values;
    } catch (e) {
      console.error("getProjectSubmission: error occured ", e);
    }
    return result;
  },
};

export const hooks = {
  useSettings: (key: Setting["key"]) => {
    return createMemo(() => {
      return getSettingsFor(key);
    });
  },
};
