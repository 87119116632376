import type { PopulateRouteHrefProps } from "../_model";

export function populateRouteHref(props: PopulateRouteHrefProps) {
  // const location = useLocation();
  // const $pathname = createMemo(() => {
  //   let pathname = location.pathname;
  //   return !pathname.endsWith("/") ? pathname : pathname.substring(0, pathname.length - 1);
  // });
  const location = window.location;
  const pathname = !location.pathname.endsWith("/")
    ? location.pathname
    : location.pathname.substring(0, location.pathname.length - 1);
  let { path, base, stackPathname } =
    typeof props == "string" ? { path: props, base: undefined, stackPathname: undefined } : props;
  if (!path) {
    path = "";
  } else {
    path = !path.startsWith("/") ? "/" + path : path;
  }
  if (base) {
    base = !base.startsWith("/") ? "/" + base : base;
    if (!stackPathname) {
      path = base + path;
    } else {
      path = pathname + base + path;
    }
  } else {
    if (stackPathname) {
      path = pathname + path;
    }
  }
  const href = path.replace(/^\/+/, "/");
  return encodeURI(href);
}
