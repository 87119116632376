export type * as _Directive from "./_model";
import * as clickOutside from "./click-outside";
import * as dragNDrop from "./drag-n-drop";

export const directives$ = {
  events: {
    ...clickOutside,
    ...dragNDrop,
  },
};
