import { global } from ":global";
import type { _UserRequests } from "../../SHARED/apis";

export interface Put {
  props: {
    item: _UserRequests.Get["returns"]["received_requests"][number];
    status: string;
  };
  returns: _UserRequests.Get["returns"];
}

export default {
  endpoint: "/teaming/accept-request/",
  async put(props: Put["props"]): Promise<Put["returns"]> {
    const jwt = global.api.auth.token.decoded();
    const receiver_email = jwt?.email_address;
    const data = {
      id: props.item.id,
      receiver_email,
      requester_email: props.item.email,
      status: props.status,
      type: props.item.type,
    };
    return global.api.putAuth(this.endpoint, data).then((res) => {
      return res.data;
    });
  },
};
