import { createFormActions, createInput } from ":shared/components/form";
import { file } from ":shared/helpers/validations";

export function createTeamInputs(props: { team_name?: string }) {
  const TeamName = createInput<string>(null, {
    required: true,
    validators: (value: string) => {
      if (value != undefined) {
        if (value.length < 6) {
          return { ["at least 6 characters"]: true };
        }
        if (value.length >= 30) {
          return { ["at most 30 characters"]: true };
        }
      }
      return undefined;
    },
  });

  const Actions = createFormActions(
    {
      TeamName,
    },
    {
      defaultCase: "snake",
    }
  );
  return {
    Actions,
    TeamName,
  };
}
