import { global } from ":global";
export type GET = {
  returns: {
    submission: boolean;
  };
};
export const endpoint = "teaming/submission-status/";
export async function get() {
  return global.api
    .getAuth<GET["returns"]>(endpoint)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err.message);
    });
}
