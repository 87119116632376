import { Portal } from "solid-js/web";
import { solidstate } from ":shared/utils/state-manager";
import { MetaContext } from "../SHARED/store";
import type { MainProps } from "./_model";

export default function Meta(props: MainProps) {
  const $meta = solidstate.create(props);
  const $title = $meta.derive((f) => f.title[0].toUpperCase() + f.title.substring(1).toLowerCase());

  MetaContext.defaultValue = {
    setTitle(title) {
      $meta.set({ title: props.title + " | " + title });
    },
  };
  return (
    <MetaContext.Provider value={MetaContext.defaultValue}>
      <Portal mount={document.head}>
        <title>{$title()}</title>
      </Portal>
      {props.children}
    </MetaContext.Provider>
  );
}
