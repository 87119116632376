import { A } from "@solidjs/router";
import type { ParticipantsCardProps } from "../_model";
import SendIcon from "../assets/icons/send-2.svg";
import { Match, Show, Switch, createSignal } from "solid-js";
import { post_invite, send_request } from "../apis";
import { solidstate } from ":shared/utils/state-manager";
import { global } from ":global";
import { createStore } from "solid-js/store";
import { PrimaryButton } from ":src/components/form/buttons";
import placeholder_img from "../assets/images/person_placeholder.png";

export default function ParticipantsCard(props: ParticipantsCardProps) {
  const $loading = solidstate.create<boolean>(false);
  const $invite_sent = solidstate.create<boolean>(false);
  const requester_email = global.api.auth.events.getUserEmail();
  const [formData, setFormData] = createStore({
    requester_email: requester_email,
    receiver_email: "",
    status: "pending",
  });

  const SendInvite = async (e: Event, receiver_email: string) => {
    $loading.set(true);
    e.preventDefault();

    try {
      setFormData("receiver_email", receiver_email);
      console.log("formData:: ", JSON.stringify(formData));
      await send_request(JSON.stringify(formData));
      // success

      global.store.actions.alert.pushToast({
        type: "success",
        message: "Invite Sent",
      });
      $loading.set(false);
      $invite_sent.set(true);
    } catch (error: any) {
      $loading.set(false);
    }
  };

  return (
    <div class="w-full h-auto relative flex flex-col  rounded-xl bg-white px-3 py-3 border-1 border-solid border-gray-300">
      <div class="flex-1 h-full">
        <div class="w-full flex justify-between items-center gap-3 relative ">
          <div>
            <Show
              fallback={
                <img
                  width={100}
                  height={100}
                  class="w-100px h-100px rounded-full object-cover "
                  src={placeholder_img}
                  alt="img"
                  loading="lazy"
                />
              }
              when={props?.image}
            >
              <img
                width={100}
                height={100}
                class="w-100px h-100px rounded-full object-cover "
                src={props?.image}
                alt="img"
              />
            </Show>
            {/* <p class="text#p text-[12px] py-3 md:py-0 text#txtgray text-start">{props?.major ?? "No major"}</p> */}
            <p class="text#warn bg#bg-orange text-12px font-600  px-2 my-2 w-fit py-[3px] rounded-md">
              {props?.track ?? "No track"}
            </p>
            <h2 class="w-full text-black font-bold text-[18px] py-[2px]">
              {props?.first_name || props?.last_name
                ? `${props?.first_name || ""} ${props?.last_name || ""}`
                : "No name"}
            </h2>
          </div>
        </div>
        <div class=" pt-1 pb-1">
          <A href={props?.portfolio ? props?.portfolio : ""}>
            <p class="text#orange underline text-15px cursor-pointer">Preview Protofolio</p>
          </A>
          <p class=" w-full text-[14px]  text#txtgray text-start py-3">{props?.bio ?? "No bio"}</p>
        </div>
      </div>

      <Show
        fallback={
          <PrimaryButton
            label={`Part of team ${props.team}`}
            classes=" !w-full  !px-4 !py-[10px] !text-[16px]"
            disabled
          />
        }
        when={!props.team}
      >
        <Switch>
          <Match when={$loading.value}>
            <PrimaryButton label="Loading..." classes="!w-full  !px-4 !py-[12px] !text-[16px]" disabled />
          </Match>
          <Match when={$invite_sent.value}>
            <PrimaryButton label="Invite sent" classes=" !w-full  !px-4 !py-[12px] !text-[16px]" disabled />
          </Match>
          <Match when={!$invite_sent.value}>
            <button
              onClick={(e) => SendInvite(e, props?.email)}
              class="w-full text-white bg#orange px-4 py-[12px] rounded-md font-bold text-[16px] cursor-pointer flex justify-center items-center gap-0 "
            >
              <SendIcon class="w-25px mr-1 " />
              Send Invite
            </button>
          </Match>
        </Switch>
      </Show>
    </div>
  );
}
