import IconNoProfileImage from "../assets/icons/no-profile-picture.svg?string";
//
import { Table } from ":src/components/list";
import { EmptyTable } from "../components/empty-table";
import { accept_request, type _AcceptRequest } from "../apis";
import { global } from ":global";
//
import type { _SolidState } from ":shared/utils/state-manager";
import type { _Api } from "../../SHARED/_model";
import { createMemo } from "solid-js";

export type Props = {
  details: _Api._UserRequests.Get["returns"];
  onUpdate: (props: _Api._UserRequests.Get["returns"]) => void;
};

export function TableRecievedRequests(props: Props) {
  const $info = createMemo(() => {
    const user_requests = props.details;
    return {
      role: user_requests.role,
      requests: user_requests.received_requests,
    };
  });
  return (
    <Table
      _items={$info().requests}
      _fallback={<EmptyTable msg="You do not have any requests in your inbox " />}
      _emptyKey="UNKOWN"
      _class={{
        desktop: {
          table: "border-spacing-y-2 border-spacing-x-1 text-black pt-0",
          thead: "h-2rem font-semibold text-0.77rem",
          theadTrTh: "px-5",
          theadBase: "bg#bg-default rounded-t-6",
          tbodyTr: "text-0.75rem",
          tbodyTrTd: "items-center py-2 px-5",
        },
        phone: {
          wrapper: "gap-y-3 py-2 px-0.5",
          card: "items-center shadow-md py-2 px-2 gap-y-2 rounded-xl !justify-between text-0.8rem text-black",
          header: "w-100px",
          cell: "justify-start overflow-x-auto",
        },
      }}
      _headers={{
        User: {
          hideHeader: {
            phone: true,
          },
          key: ({ item, emptyKey }) => {
            const image = typeof item.image !== "string" ? IconNoProfileImage : item.image;
            return (
              <div class="flex flex-row w-full h-full">
                <img class="w-80px h-80px rounded-full object-cover z-0" src={image} alt={"profile_image"} />
                <p class="flex flex-col mx-3 mt-1.5">
                  <span class="font-bold text-0.8rem ">{`${item.name ?? emptyKey}`}</span>
                  <span>{`${item.reference ?? "#000000"}`}</span>
                </p>
              </div>
            );
          },
        },
        "Email Address": {
          key: "email",
        },
        Type: {
          key: "type",
        },
        Action: {
          key: ({ item }) => {
            return (
              <div class="flex flex-row space-x-2 <md:(w-full justify-between)">
                <span
                  class="text-green underline cursor-pointer"
                  onclick={() => {
                    global.store.actions.popop.pushConfirmDialog({
                      type: "info",
                      message: `Are you sure you want to accept ${item.name} request?!`,
                      events: {
                        async onConfirmed() {
                          return accept_request
                            .put({
                              item,
                              status: "accepted",
                            })
                            .then((data) => {
                              global.store.actions.alert.pushToast({
                                type: "success",
                                message: "member joined team successfully!, welcome them.",
                              });
                              data.received_requests = $info().requests.filter((x) => x.id !== item.id);
                              props.onUpdate?.(data);
                            });
                        },
                      },
                    });
                  }}
                >
                  Accept
                </span>
                <span
                  class="text-red underline cursor-pointer"
                  onclick={() => {
                    global.store.actions.popop.pushConfirmDialog({
                      type: "warn",
                      message: `Are you sure you want to reject ${item.name} request?!`,
                      events: {
                        async onConfirmed() {
                          return accept_request
                            .put({
                              item,
                              status: "rejected",
                            })
                            .then(() => {
                              global.store.actions.alert.pushToast({
                                type: "success",
                                message: "member team join rejected!",
                              });
                              const data = props.details;
                              data.received_requests = $info().requests.filter((x) => x.id !== item.id);
                              props.onUpdate?.(data);
                            });
                        },
                      },
                    });
                  }}
                >
                  Remove
                </span>
              </div>
            );
          },
        },
      }}
    />
  );
}
