import { defineQunxios } from ":shared/utils/qunxios";
import { default as store } from "./store";
import type { TokenDecoded, TokenTypeKey, TokenType } from "./_model";
import { TOKEN_TYPE } from "./constants";
import { decodeError } from "./api-helpers/decode-errors";
import auth_routes from ":src/routes/auth/routes";

// TODO: add onTokenRefresh error event
export default defineQunxios({
  baseUrl: import.meta.env.__API,
  triggers: {
    onInitAccessDenied: (access) => {
      if (access.localstorageDenied) {
        store.actions.route.navigate({ base: "/auth", path: "/login" });
      }
    },
  },
  interceptors: {
    processRespondsErrors({ err }) {
      const data = err?.response?.data;
      const is_doc_type = typeof data === "string" && data.startsWith("<!");
      let error_message = null; // decodeError(err, "unknown error");
      if (is_doc_type) {
        error_message = err.response?.statusText;
      } else {
        error_message = decodeError(err, "unknown error response");
      }
      console.log("err is ", err);
      console.log("err error message is :: ", error_message);
      store.actions.alert.pushToast({
        type: "error",
        message: error_message ?? "an error occured",
      });
      return {
        message: error_message,
      };
    },
  },
  auth: {
    mode: "localstorage",
    schema: null as TokenDecoded,

    // manulTokenExpiration: { unit: "s", period: 20 }, // use for testing only, haven't tested this in production
    defaults: {
      invalidToken: "keep token",
      invalidTokenDecode: "keep token",
      expirationKey: "exp",
    },

    endpoints: {
      postToken: {
        url: "/auth/token",
      },
      postTokenRevalidate: { url: "/auth/token/refresh" },
      // getTokenRevalidate: { url: "/auth/token/details/" },
      // getTokenRemove: { url: "auth/logout/" },
    },
    triggers: {
      onTokenDecoded({ decoded, res, helpers }) {
        const id = decoded.user_type;
        for (const key in TOKEN_TYPE) {
          const value = TOKEN_TYPE[key];
          if (value.id === id) {
            // token_type = value;
            decoded.user_key = key as TokenTypeKey;
            break;
          }
        }
        if (decoded.user_key == undefined) {
          throw new Error("TOKEN TYPE WITH ID DOESN'T EXIST :: " + id);
        }
        // console.log("token decoded :: ", shouldLogin);
      },
      // signin/token refreshed
      onTokenSaved({ decoded, res, helpers }) {
        console.log("token saved :: ");
      },
      onTokenRemove: ({ decoded, res, err }) => {
        console.log("token removed :: ", decoded);
        // if (decoded || err) {
        store.actions.route.navigate({ base: auth_routes.BASE, path: auth_routes.login });
        // }
      },
      onInvalidatedToken({ defaults, __configState }) {
        // logout on invalid token upon token remove call
        if (__configState.is_token_revalidate) {
          return "clear token";
        }
        return defaults.invalidToken;
      },
      // onOneTimeSessionsActivated: () => {
      //   toast_group$actions.pushToast({
      //     type: "info",
      //     message:
      //       "One Time Session is activated due to disabled cookies. \nYoull stay connected so long you don't reload the page or exit browser!.",
      //   });
      // },
    },
    events: {
      getEncodedToken: ({ res }) => ({ access: res?.data?.access, refresh: res?.data?.refresh }),
      getTokenRefresh: ({ encoded }) => ({ refresh: encoded.refresh }),
    },
    customEvents: ({ getDecodedToken, helpers }) => ({
      getUserEmail: () => {
        const token = getDecodedToken();
        return token?.email_address ?? "UNKNOWN EMAIL";
      },
      hasToken: () => {
        return helpers.exists();
      },
      getDecodedToken: () => {
        // const ss = getDecodedToken()
        return getDecodedToken();
      },
      getTokenTypeObject<T extends TokenTypeKey>(key: T): TokenType[T] {
        return TOKEN_TYPE[key];
      },
      getUserBaseRoute() {
        const token = getDecodedToken();
        const result = TOKEN_TYPE?.[token.user_key]?.base_route;
        if (result == undefined) {
          // moving navigation handling to route src/routes
          // store.actions.route.navigate(ROUTE_BASES.auth);
          store.actions.alert.pushToast({ type: "error", message: "unknown user type, redirecting!" });
          console.warn("unknown user type or base route is not defined, should redirect to login page!");
          return undefined;
        }
        // else if (token_key === "filterer") {
        //     store.actions.alert.pushToast({
        //       type: "error",
        //       message: "this phase has already passed!",
        //     });
        //     clearToken();
        //     return;
        //   }
        return result;
      },
    }),
  },
});

// function redirectToUserRouteBase(props: { decoded: any; clearToken: any; shouldLogin: boolean }) {
//   const { decoded, clearToken, shouldLogin } = props;
//   // console.log("token Obtained :: ", jwt, " :: ", res);
//   if (!token_key) {
//     store.actions.alert.pushToast({ type: "error", message: "unknown user type, redirecting!" });
//     // store.actions.route.navigate(ROUTE_BASES.auth);
//     return;
//   }

//   if (shouldLogin) {
//     console.log("should login :: ", shouldLogin);
//     store.actions.route.navigate({ base: base_route });
//     store.actions.alert.pushToast({ type: "success", message: `logged in as ${decoded.first_name}` });
//   } else {
//     store.actions.alert.pushToast({ type: "success", message: `welcome back, ${decoded.first_name}` });
//   }
// }
