import { createFormControl } from "solid-forms";
import { splitProps } from "solid-js";
import type { MainProps } from "./_model";
import type { FormControlData, FormControlValue } from "../SHARED/_model";

export default function Label<V extends FormControlValue, D extends FormControlData>(props: MainProps<V, D>) {
  const [local, others] = splitProps(props, ["control", "title", "children"]);
  const _control = Array.isArray(local.control) ? createFormControl<V, D>(...local.control) : local.control;
  return (
    <label {...others} classList={{ disabled: _control.isDisabled }}>
      {local.title}
      {local.children}
    </label>
  );
}
