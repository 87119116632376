import { onCleanup } from "solid-js";
import type { Events } from "./_model";
export default function dragNDrop(el: HTMLElement, _events: Events) {
  const events = _events as () => Events;
  el.addEventListener("dragenter", onDragEnter);
  let registered = false;
  function onDragEnter(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (registered) {
      return;
    }
    registered = true;
    document.body.addEventListener("dragover", onDragOver);
    document.body.addEventListener("drop", onDrop);
    events()?.onEnter?.(e);
  }
  function onDragOver(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (el.contains(e.target as any)) {
      events()?.onMove?.(e);
      return;
    }
    onDragLeave(e);
  }
  function onDragLeave(e: DragEvent) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      events()?.onLeave?.(e);
    }
    registered = false;
    document.body.removeEventListener("dragover", onDragOver);
    document.body.removeEventListener("drop", onDrop);
  }
  function onDrop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (el.contains(e.target as any)) {
      events()?.onDrop?.(e);
    }
    onDragLeave(e);
  }

  onCleanup(() => {
    el.removeEventListener("dragenter", onDragEnter);
    onDragLeave(undefined);
  });
}
