import type { UserTeamState } from "./_model";
//
import { solidstate } from ":shared/utils/state-manager";
import { For, Match, Show, Switch, batch, createEffect, createMemo } from "solid-js";
import { TableTeamMembers } from "./tables/team-members";
import { TableRecievedRequests } from "./tables/recieved-requests";
import { TableSentRequests } from "./tables/sent-requests";
import { Loader2 } from ":src/components/loaders";
import { leave_team } from "./apis";
import { user_requests } from "../SHARED/apis/";
import { global } from ":global";
import { TABS_AND_STATES, TABS_KEYS } from "./const";
import { CreateTeamForm } from "./components/create-team-form";
import { getDateDetails } from "@qundus.tc/agnostic.helpers/date";
import { TeamingHistory } from "../history";

export default function MyTeam() {
  const $details = solidstate.createAsync(user_requests.GET);
  const $user_team_state: () => UserTeamState = $details.derive((value) => {
    if (value == undefined || value.team_info == undefined) {
      return "teamless";
    }
    if (value.role === "leader") {
      return "leader";
    }
    return "member";
  });
  const $tabs = createMemo(() => {
    const state = $user_team_state();
    const result: { [Key in keyof typeof TABS_AND_STATES]?: string | null } = {};
    for (const tab in TABS_AND_STATES) {
      const name = TABS_AND_STATES[tab][state];
      result[tab] = name;
    }
    return result;
  });
  const $team_info = createMemo(() => {
    const info = $details.value?.team_info;
    if (info == undefined) {
      return undefined;
    }
    const date = getDateDetails(info.created_at);
    return {
      ...info,
      date: `${date.month_short}-${date.day_number}-${date.day_short}`,
    };
  });
  const $active_tab = solidstate.create(getActiveTab());

  createEffect(() => {
    batch(() => {
      const current_active_tab = $tabs()[$active_tab.value];
      if (current_active_tab == undefined) {
        $active_tab.set(getActiveTab());
      }
    });
  });

  function getActiveTab(): keyof typeof TABS_AND_STATES {
    const tabs = $tabs();
    let result = undefined;
    for (const tab in tabs) {
      const title = tabs[tab];
      if (title != undefined) {
        result = tab;
        break;
      }
    }
    return result;
  }

  return (
    <section class="flex flex-col w-full h-full overflow-y-hidden overflow-x-hidden md:(px-5 py-3) <md:(px-3 py-2)">
      {/* title and tabs */}
      <div class="flex flex-row  flex-wrap min-w-full justify-between py-2 <md:(!flex-col) ">
        <h1 class="flex flex-col text-black text-1.1rem whitespace-nowrap capitalize space-y-1 <md:text-1rem">
          {$team_info() != undefined ? <p>{$team_info().name}</p> : <p>My Team</p>}
          {$team_info() != undefined && (
            <p class="flex flex-row text-0.6em <md:(flex-wrap gap-y-2 justify-between) md:(gap-x-5)">
              <span
                class={`before:(content-['Role:_'] text#gray) ${
                  $details.value.role === "leader" ? "text-yellow" : "text#a"
                }`}
              >
                {$details.value.role}
              </span>
              <span class="before:(content-['Created_at:_'] text#gray)">{$team_info().date}</span>
              <span class="before:(content-['History:_'] text#gray)">
                <button
                  class="bg-black text-white px-1 py-0.5 rounded-l cursor-pointer"
                  onClick={() => {
                    global.store.actions.popop.pushModal({
                      title: "Team Action History",
                      element: () => <TeamingHistory />,
                    });
                  }}
                >
                  View
                </button>
              </span>
            </p>
          )}
        </h1>
        <div class="flex flex-row justify-between <md:(py-2 justify-evenly flex-wrap gap-y-2) <xs:(flex-wrap)">
          <For each={TABS_KEYS}>
            {(tab, index) => {
              const $title = createMemo(() => $tabs()[tab]);
              const $hidden = createMemo(() => $title() == undefined);
              const $active = createMemo(() => $active_tab.value === tab);
              const $count = createMemo(() => {
                if (tab === "received_requests") {
                  return $details.value?.received_requests?.length;
                }
                if (tab === "sent_requests") {
                  return $details.value?.sent_requests?.length;
                }
                return undefined;
              });
              return (
                <Show when={!$hidden()}>
                  <p
                    class={`flex flex-row  h-2rem  items-center justify-between rounded-lg cursor-pointer text-0.6rem px-2 space-x-1 md:(mx-1) <md:(text-0.5rem whitespace-nowrap !h-1.4rem !px-1 !rounded-md)
                    ${$active() ? "bg#a text#pt" : tab === "leave_team" ? "bg#error" : "bg#at"}`}
                    onclick={async () => {
                      if (tab === "leave_team") {
                        global.store.actions.popop.pushConfirmDialog({
                          type: "warn",
                          message: "Are you sure you want to leave the team?",
                          note: "this is irreversable!",
                          events: {
                            async onConfirmed() {
                              return leave_team.get().then((value) => {
                                // console.log("team leave returns :: ", value);
                                global.store.actions.alert.pushToast({
                                  type: "success",
                                  message: "don't look back, future endevours await!",
                                });
                                $details.set(value);
                              });
                            },
                          },
                        });
                        return;
                      }
                      $active_tab.set(tab);
                    }}
                  >
                    <span class="flex-1 capitalize ">{$title()}</span>
                    {$count() && (
                      <span
                        class={`flex min-w-0.9rem min-h-0.9rem rounded-full items-center justify-center text-0.5rem <md:(text-0.47rem min-w-0.7rem min-h-0.5rem)
                        ${$active() ? "bg#pt text#a" : "bg#a text#pt"}`}
                      >
                        {$count()}
                      </span>
                    )}
                  </p>
                </Show>
              );
            }}
          </For>
        </div>
      </div>
      <Switch>
        <Match when={$details.state.loading}>
          <Loader2 msg="Loading Memebrs And Requests..." />
        </Match>
        <Match when={$details.state.failed}>error occured while loading user requests details {$details.error}</Match>
        <Match when={$details.state.success}>
          <Switch>
            <Match when={$active_tab.value === "create_team"}>
              <div class="flex flex-col w-full h-full overflow-y-auto md:(justify-center !w-50% self-center)">
                <CreateTeamForm details={$details.value} onCreate={(data) => $details.set({ ...data })} />
              </div>
            </Match>
            <Match when={$active_tab.value === "members"}>
              <TableTeamMembers details={$details.value} />
            </Match>
            <Match when={$active_tab.value === "received_requests"}>
              <TableRecievedRequests details={$details.value} onUpdate={(data) => $details.set({ ...data })} />
            </Match>
            <Match when={$active_tab.value === "sent_requests"}>
              <TableSentRequests details={$details.value} onUpdate={(data) => $details.set({ ...data })} />
            </Match>
          </Switch>
        </Match>
      </Switch>
    </section>
  );
}
