export const TABS_AND_STATES = {
  create_team: {
    teamless: "create team",
    leader: null,
    member: null,
  },

  members: {
    teamless: null,
    leader: "members",
    member: "members",
  },
  received_requests: {
    teamless: "team invitation",
    leader: "join requests",
    member: null,
  },
  sent_requests: {
    teamless: "team join requests",
    leader: "invitations",
    member: null,
  },
  leave_team: {
    teamless: null,
    leader: "leave team",
    member: "leave team",
  },
} as const;

export const TABS_KEYS = Object.keys(TABS_AND_STATES) as (keyof typeof TABS_AND_STATES)[];
