import type {
  AGuardOnFailureProps,
  AGuardState,
  AGuardStep,
  AGuardStepCheckerError,
  AGuardStepCheckerProps,
  AGuardStepGroup,
  MainProps,
} from "../_model";
import { general } from "@qundus.tc/agnostic.helpers";

export async function checkGuardSteps<T extends AGuardStepGroup>(props: {
  steps: T;
  events: {
    next?: (step: AGuardStep) => Omit<AGuardStepCheckerProps, "state">;
    onGuardFailureSkip?: MainProps["events"]["onGuardFailureSkip"];
  };
}): Promise<
  | {
      success: boolean;
      fail?: Pick<AGuardOnFailureProps, "error" | "step" | "stepEquals"> & { id: string | number };
    }
  | undefined
> {
  const { events, steps } = props;
  // chek the important events
  if (events == undefined) {
    console.error("guarded: you need to set the ready event handler for guard!");
    return undefined;
  }
  if (!steps) {
    return {
      success: true,
    };
  }
  let failed_step: AGuardStep = undefined;
  let failed_step_id: string | number = undefined;
  let failed_error: AGuardStepCheckerError = undefined;
  for (const key in steps) {
    const step = steps[key];
    const id = steps[key]?.id ?? key;
    failed_step = undefined;
    failed_error = undefined;
    if (!step?.runOn || step?.runOn?.length <= 0) {
      failed_error = {
        error: "guarded: checkGuardSteps unknown guard run on sequence! in " + step,
      };
      throw new Error(
        "guarded: checkGuardSteps step missing runOn property and/or unknown guard run on sequence!, please specify the run on option " +
          step
      );
    } else if (!(step.runOn instanceof Set)) {
      let run_on_array = !step?.runOn
        ? undefined
        : typeof step?.runOn === "string"
        ? [step?.runOn]
        : step?.runOn?.length <= 0
        ? undefined
        : step?.runOn;
      steps[key].runOn = new Set(run_on_array) as any;
    }
    let run_on = steps[key]?.runOn as unknown as Set<AGuardState>;

    try {
      const checker_props = events?.next?.(step) as AGuardStepCheckerProps;
      let checker_error = undefined;
      step.loaderMsg = step?.loaderMsg ?? "Unknown Step Loader Message";
      if (run_on.has(checker_props.state)) {
        if (step.checker == undefined || typeof step.checker != "function") {
          throw {
            message: `guarded: step <${id}> has no checker function!`,
          } as AGuardStepCheckerError;
        }
        await Promise.race([step?.checker?.(checker_props)])
          // user known errors
          .then((e: any) => {
            checker_error = checkStepError({ id, obj: e });
          })
          // user thrown exception, might not be known
          .catch(async (e) => {
            // give the user chance to handle exception
            if (step?.processException != undefined) {
              throw step?.processException?.({ ...checker_props, exception: e });
            } else {
              // inform user that they can handle exceptions in case they didn't know
              console.warn(
                `guarded: unhandled exception was thrown from step ${id}, you can use processException function to handle this!!`
              );
            }
            // fix exception to work as the intended error to avoid issues
            throw {
              message: `uprocessed exception occured at step {${id}}`,
              error: e,
            } as AGuardStepCheckerError;
          })
          .catch((e) => {
            checker_error = checkStepError({ id, obj: e });
          });
        if (checker_error != undefined) {
          if (step.errorFallback != undefined) {
            checker_error = {
              ...step.errorFallback,
              ...checker_error,
            };
          }
          throw checker_error;
        }
      }
    } catch (err: any) {
      if (step?.errorSkip) {
        events?.onGuardFailureSkip?.({ step, error: err, stepEquals: (step) => stepEquals(steps, step, failed_step) });
        failed_error = undefined;
        continue;
      }
      failed_step_id = id;
      failed_step = step;
      failed_error = failed_error == undefined ? err : failed_error;
      break;
    }
  }

  if (failed_step == null) {
    return {
      success: true,
    };
  } else {
    return {
      success: false,
      fail: {
        step: failed_step,
        error: failed_error as any,
        stepEquals: (step) => stepEquals(steps, step, failed_step),
        id: failed_step_id,
      },
    };
  }
}

function stepEquals(steps: AGuardStepGroup, step1: AGuardStep, step2: AGuardStep): boolean {
  if (!steps || steps.length <= 0) {
    return false;
  }
  const s1_idx = steps.indexOf(step1);
  const s2_idx = steps.indexOf(step2);
  // console.log("comparing steps :: ", step1, step2, " :: and indecies :: ", s1_idx, s2_idx);
  return s1_idx >= 0 && s2_idx >= 0 && s1_idx === s2_idx;
}

function checkStepError(props: { id: string; obj: any }): AGuardStepCheckerError {
  const { id, obj } = props;
  let result: AGuardStepCheckerError = undefined;

  if (obj == undefined) {
    result = undefined;
  } else if (typeof obj === "string") {
    result = {
      message: obj,
      error: undefined,
    };
  } else if (general.isAsyncFunction(obj)) {
    result = {
      message: `step <${id}> checker function must return an error object not a promise :: `,
      error: obj,
    };
    console.error(result);
  } else if (typeof obj !== "object") {
    // check if not an object
    result = {
      message: `step <${id}> checker function must return an error object!!`,
      error: obj,
    };
    console.error(result);
  } else {
    // check if object has required keys filled up and not just any object
    const keys = Object.keys(obj);
    // err keys must be between 0 and 3
    if (keys.length <= 0 || keys.length > 3) {
      result = {
        message: `step <${id}> must be a valid step checker error object!!`,
        error: obj,
      };
      console.error(result);
    } else if (!("error" in obj) && !("message" in obj) && !("navigate" in obj)) {
      result = {
        message: `step <${id}> must set error's message, error or navigate keys at at least!!`,
        error: obj,
      };
      console.error(result);
    }
  }

  // console.log("result :: ", result, " :: obj :: ", obj);
  return result ?? obj;
}
