import { getDate } from "../../SHARED/helpers/get-date";
import type { EvaluationData, Team } from "../../SHARED/_model";
//
import { global } from ":global";

export async function updateEvaluation(props: { team: Team; scores: { [k: string]: number } }): Promise<Team> {
  const team = props.team;
  const new_eval = team.scores == undefined;
  const jwt = global.api.auth.token.decoded();
  const endpoint = jwt.user_key !== "evaluator" ? "judging" : "evaluation";
  return (
    new_eval
      ? global.api.postAuth(`scoring/${endpoint}/create/`, { ...props.scores, team: team.id })
      : global.api.putAuth(`scoring/${endpoint}/update/${String(team.eval_id)}/`, props.scores)
  ).then((res) => {
    const res_team = res.data as Team;
    res_team.eval_time = getDate(res_team.eval_time);
    return Promise.resolve(res_team);
  });
}
