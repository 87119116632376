import { useNavigate, Router as SolidRouter, useSearchParams, useLocation, type Location } from "@solidjs/router";
import { ErrorBoundary, createEffect, splitProps, untrack, onMount } from "solid-js";
import { $route, $route_params, triggers_funcs } from "../SHARED/store";
import type { MainProps } from "./_model";
import {} from "solid-js";

export default function Router(props: MainProps) {
  const [local, others] = splitProps(props, ["children"]);
  let after_navigate_funcs = undefined as (() => void)[];

  return (
    <ErrorBoundary
      fallback={(err) => {
        console.log("Route: error occured in router \n :: ", err);
        return <div>Route: error occured in router</div>;
      }}
    >
      <SolidRouter
        {...others}
        // preload={false}
        root={(props) => {
          const $location = useLocation();
          const navigate = useNavigate();
          const [$search_params, $params_actions] = useSearchParams();
          let init = false;

          onMount(() => {
            console.log(
              `%c STARTED NAVIGATION AT ${$location?.pathname} `,
              "color: green;font-weight:bold; background-color: white;font-size: 15px;margin-top: 10px; margin-bottom: 10px; padding-top: 5px;padding-bottom: 5px;"
            );
          });

          // navigation requested
          createEffect(() => {
            const data = $route.accessor();
            // console.log("SolidRouter navigation requested :: ", data);
            untrack(() => {
              if (data) {
                // console.log("sad ::: ", unwrap($search_params));
                if (data.delta) {
                  navigate(data.delta);
                } else {
                  navigate(data.to, data.options);
                }
                if (!after_navigate_funcs) after_navigate_funcs = [];
                if (data.options?.afterNavigate) after_navigate_funcs.push(data.options?.afterNavigate);
                // $route.set(undefined);
              }
              // const [my_options, navigate_options] = splitProps(data.options, ["afterNavigate"]);
              // console.log("router myoptions :: ", my_options, navigate_options);
            });
          });

          // params actions
          createEffect(() => {
            const data = $route_params.accessor();
            // console.log("SolidRouter prarms actions :: ", data);

            untrack(() => {
              if (data) {
                // console.log("data from log is :: ", data);
                $params_actions(data.params, data.options);
                // $route_params.set(undefined);
              }
            });
          });

          // navigation occured
          createEffect(() => {
            const { pathname } = $location;
            if (init) {
              console.log(
                `%c NAVIGATION OCCURED TO ${pathname} `,
                "color: white;font-weight:bold; background-color: RoyalBlue;font-size: 15px;margin-top: 10px; margin-bottom: 10px; padding-top: 5px;padding-bottom: 5px;"
              );
            }
            init = true;
            untrack(() => {
              after_navigate_funcs?.forEach((f) => untrack(() => f()));
              triggers_funcs.on_navigated.forEach((f) => untrack(() => f()));
              after_navigate_funcs = undefined;
            });
          });

          return others?.root?.(props) ?? props.children;
        }}
      >
        {local.children}
      </SolidRouter>
    </ErrorBoundary>
  );
}
