import { Anchor, type _Navigation } from ":shared/components/route";
import { default as routes } from "../routes";

export default function OnSiteSideNavigation(props: _Navigation.SideNavJSXItemProps) {
  // const $settings = global.store.hooks.platform.useSettings("");
  return (
    <Anchor
      class={`relative  ${props?.anchor?.class}`}
      setup={{
        to: routes,
        classes: {
          active: `${props.anchor.setup.classes.active}`,
        },
      }}
      // events={{
      //   getShouldNavigate: () => $settings()?.active,
      // }}
      triggers={
        {
          // onBeforeNavigate: () => {
          //   if (!$settings().active) {
          //     global.store.actions.alert.pushToast({
          //       type: "error",
          //       message: "This page is locked by admin, you can't access it right now!",
          //     });
          //   }
          // },
        }
      }
      components={{
        title: "On-Site Tickets",
        idleIcon: "i-icon-park-outline:tickets-checked text-black",
        activeIcon: "i-icon-park-outline:tickets-checked text#p",
      }}
    >
      {/* <LockedAnchor locked={!$settings().active} /> */}
    </Anchor>
  );
}
