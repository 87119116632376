import { Switch, Match } from "solid-js";
import type { MainProps } from "./_model";
import { global } from ":global";
// import login_logo from "../SHARED/assets/images-hackyard-demo/logo.png?format=webp";

export default function AuthLayout(props: MainProps) {
  const $breakpoint = global.store.hooks.dom.useBreakpoints();
  return (
    // no need for fallback since we're using desktop as default!
    <Switch>
      {/* phones and tablets */}
      <Match when={$breakpoint()?.misc.lg.widthIsLess}>
        <main class={`flex w-full h-full bg#p`}>
          <div class="flex flex-col w-full justify-between items-center self-center gap-3 overflow-y-hidden <sm:(px-5) sm:(px-10)">
            <div class="flex w-full justify-start">
              <img width={150} src={"/logo.png"} alt="signin_logo" />
            </div>
            <section class="flex flex-col w-full h-full ">{props.children}</section>
          </div>
        </main>
      </Match>
      {/* desktop */}
      <Match when={true}>
        <main class={`grid grid-cols-[40%_60%] w-full h-full bg#p `}>
          <div class="flex flex-col w-full justify-between items-center self-center pl-10 gap-3">
            <div class="flex w-full justify-start">
              <img width={150} src={"/logo.png"} alt="signin_logo" />
            </div>
            <section class="flex flex-col w-full h-full ">{props.children}</section>
          </div>
        </main>
      </Match>
    </Switch>
  );
}
