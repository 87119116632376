import { global } from ":global";
import type { _UserRequests } from "../../SHARED/apis";

export interface Post {
  props: { team_name: string };
  returns: _UserRequests.Get["returns"];
}

export default {
  endpoint: `/teaming/create-team`,
  async post(props: Post["props"]): Promise<Post["returns"]> {
    return global.api.postAuth(this.endpoint, props).then((res) => {
      return res.data;
    });
  },
};
