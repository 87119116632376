import { createFormActions, createInput, createSelect, createTextArea } from ":shared/components/form";

export function createRequestFromInputs() {
  const TableNumber = createSelect("", {
    required: true,
  });
  const Mobile = createInput("", {
    required: false,
    validators: (value) => {
      if (value == undefined) {
        return { "is required": true };
      }
      if (!String(value).startsWith("05")) {
        return { "must start with 05": true };
      }
      return undefined;
    },
  });
  const Description = createTextArea("", {
    required: true,
  });

  const Actions = createFormActions({
    TableNumber,
    Mobile,
    Description,
  });

  return {
    Actions,
    TableNumber,
    Mobile,
    Description,
  };
}
