import { global } from ":global";

export type PUT = {
  props: any;
};
export const endpoint = "teaming/update-team/";
export async function put(data: PUT["props"]) {
  return global.api
    .putAuth(endpoint, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
