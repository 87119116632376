import { global } from ":global";
import type { Setting } from "../_model";
import { $platform_settings } from "../store";
export async function getPlatformSettings(): Promise<Setting[]> {
  return global.api.getAuth("platform/settings/").then((res) => {
    // res.data = undefined;
    $platform_settings.set(res.data);
    return res.data;
  });
}
