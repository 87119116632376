import type { AppFlavor } from "../_model";
export const BASE_APP_FLAVOR: AppFlavor = {
  id: "Hackyard-Demo",
  theme: {
    admin_index: 0,
    user_index: undefined,
    schemes: [
      {
        p: "#ffffff",
        gray: "#CCCCCC",
        paper: "#f2f2f2",
        hint: "rgba(0, 0, 0, 0.70)",
        pt: "#ffffff",
        a: "#0000C7",
        at: "#191A1F",
        page: "#ffffff",
        paget: "#585858",
        error: "#AF1F24",
        info: "rgba(0,50,255,0.8)",
        success: "#3DB042",
        warn: "#F59332",
        skeleton: "#D9D9D946",
        green: "#85B1AD",
        brown: "#A84531",
        red: "#AF1F24",
        yellow: "#F59332",
        orange: "#0000C7",
        skin: "#F8BD94",
        disabled: "#00000033",
        txtgray: "#585858",
        border_light: "#F0F0F2",
        border_dark: "#D8DADC",
        bg: {
          default: "#F1F2F4",
          orange: "#FDE9D6",
          light: "#FEF7F5",
          pink: "#FCE0DA",
        },
        label: {
          gray: "#979797",
          text: "#191A1F",
        },
        input: {
          bg: "#D9D9D946",
          border: "#D8DADC",
          text: "#191A1F",
          touched: "rgba(0,50,255, 0.5)",
          placeholder: "#808080",
        },
        alert: {
          bg: "#191A1F",
          text: "#ffffff",
          error: "red",
          info: "rgba(0,50,255,0.8)",
          success: "rgb(0,200,0)",
          warn: "orange",
        },
        select: {
          bg: "#FFFFFF",
          bga: "#FFFFFF",
          bgh: "",
          text: "#000000",
          texta: "#000000",
          texth: "",
        },
        "nav-side": {
          bg: "#ffffff",
          bga: "#F1F2F4",
          text: "#585858",
          texta: "#0000C7",
          divider: "#BDD2EA",
        },
      },
    ],
  },
};
