import { global, type _Global } from ":global";
import type { _Guarded } from ":shared/components/route";
import { default as auth_routes } from "../../auth/routes";

// TODO: check token only once across routes
// TODO: disable token checks on /auth but not /auth/login
// TODO: store non critical indicators in local storage to know this
export const checkUserToken: _Guarded.AGuardStep = {
  id: "checkUserToken",
  runOn: ["app_init", "first_load"],
  loaderMsg: "checking token...",
  processException({ exception, isBaseRoute, routeMatchBase, routeMatchPathsUnderBase }) {
    console.log("exception at checkUserToken :: ", exception);
    const is_auth_route = routeMatchBase(auth_routes.BASE);

    if (is_auth_route) {
      if (isBaseRoute) {
        return {
          navigate: auth_routes.login,
        };
      }
      return undefined;
    }
    return {
      error: exception,
      navigate: {
        base: auth_routes.BASE,
        path: auth_routes.login,
      },
    };
  },
  async checker({ state, base, location, isBaseRoute, routeMatchBase, routeMatchPathsUnderBase }) {
    const { token, error } = await global.api.auth.checkToken();
    const is_auth_route = routeMatchBase(auth_routes.BASE);
    //! make everything revolve around the token error
    // error occured on intial token retrieval
    if (error != undefined) {
      // allowed routes under auth base while token exists
      if (is_auth_route) {
        const allowed = routeMatchPathsUnderBase([auth_routes.password_reset], auth_routes.BASE);
        if (allowed) {
          return undefined;
        }
        if (isBaseRoute) {
          return {
            navigate: auth_routes.login,
          };
        }
        return undefined;
      }
      // for any other route, we should route user to login screen
      return {
        error,
        navigate: {
          base: auth_routes.BASE,
          path: auth_routes.login,
        },
      };
    }

    //! pass shouldLogin from global api to here, to unifi messages
    console.log("token decoded :: ", token);
    if (state === "app_init") {
      global.store.actions.alert.pushToast({
        type: "info",
        message: `welcome back ${token.first_name}`,
      });
    }
    //
    // edge case where users type on the server side is not compatible with frontend
    const user_base_route = global.api.auth.events.getUserBaseRoute();
    if (user_base_route == undefined) {
      return {
        message: "user base route is unknown",
        navigate: {
          base: auth_routes.BASE,
          path: auth_routes.login,
        },
      };
    }
    // edge case where user is not under their respective user type dashboard
    const is_user_under_right_base_route = routeMatchBase(user_base_route);
    if (!is_user_under_right_base_route) {
      return {
        message: `user must be routed to respective base ${user_base_route}`,
        navigate: {
          base: user_base_route,
        },
      };
    }

    return undefined;
  },
};
