import { Platform } from ":shared/components/loaders";
import { global } from ":global";

export default function Loader2(props?: { subtitle?: string; msg: string }) {
  const flavor = global.contexts.whitelabel.useAppFlavor();

  return (
    <Platform
      {...props}
      title={flavor.id}
      class="relative flex flex-col w-full h-full items-center justify-center text#a"
    />
  );
}
