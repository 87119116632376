import { Match, Show, Switch } from "solid-js";
import { solidstate } from ":shared/utils/state-manager";
import { get_agenda } from "./apis";
import { Loader2 } from ":src/components/loaders";
import { EventColumn } from "./components";

//
import type { MainProps, Event } from "./_model";

//
export default function Agenda(props: MainProps) {
  const $agenda_list = solidstate.createAsync(get_agenda);
  const $events_by_day = $agenda_list.derive((events: Event[]) => {
    // console.log("agenda list :: ", events);

    if (!events || events.length <= 0) {
      return undefined;
    }

    const result = events.reduce((result, event) => {
      const date = new Date(event.start);
      const split = date.toISOString()?.split("T");
      const key = split?.[0];

      // console.log("split is :: ", split);
      if (!result[key]) {
        result[key] = [];
      }

      result[key].push(event);
      return result;
    }, {} as { [key: string]: Event[] });

    return result;
  });

  return (
    <section class="flex flex-col w-full min-h-full h-full md:px-5 md:py-3">
      <h1 class="text-xl py-2 font-[600] text-black z-1 <md:(text-22px py-2 px-2)">Agenda</h1>
      <Switch>
        <Match when={$agenda_list.state.loading}>
          <div class="flex w-full min-h-screen md:h-full items-center justify-center">
            <Loader2 msg="Loading Agenda Details..." />
          </div>
        </Match>
        <Match when={$agenda_list.state.failed}>an error occurred while fetching {$agenda_list.error}</Match>
        <Match when={$agenda_list.state.success}>
          <div class="flex flex-row w-full h-full  overflow-auto <md:(px-2 py-2)">
            <div class="flex flex-row min-w-full h-full rounded-md gap-x-2 <md:(!flex-col gap-y-2) ">
              <Show when={$events_by_day() != undefined} fallback={<div>there's no events!</div>}>
                {Object.keys($events_by_day())?.map((dayKey) => {
                  const eventsForDay = $events_by_day()[dayKey];
                  const firstEvent = eventsForDay[0];
                  const firstEventDate = new Date(firstEvent.start);
                  const monthName = firstEventDate.toLocaleString("default", { month: "short" }).toUpperCase();
                  // console.log("event :: ", monthName);
                  return (
                    <EventColumn
                      year={firstEventDate.getFullYear()}
                      day={firstEventDate.getDate()}
                      month={firstEventDate.getMonth() + 1}
                      monthName={monthName}
                      dayOfWeek={firstEventDate.toLocaleDateString("en-US", { weekday: "short" })}
                      events={eventsForDay}
                    />
                  );
                })}
              </Show>
            </div>
          </div>
        </Match>
      </Switch>
    </section>
  );
}
