import nope from "nope-validator";
import type { StringValidator, ObjectValidator } from "./_model";

export const email: StringValidator = nope.string().email("Must be a valid email");
export const password: StringValidator = nope.string().test((value) => {
  // if (value.search(/[a-z]/) === -1) {
  //   return "Must contain at least one lowercase letter";
  // }
  if (!value || value === "") {
    return "Is required";
  }
  if (value.length < 6) {
    return "Minimum of 6 characters";
  }
  if (value.length > 15) {
    return "Maximum of 15 characters";
  }
  if (value.search(/[A-Z]/) === -1) {
    return "Must have one uppercase letter";
  }
  if (value.search(/[0-9]/) === -1) {
    return "Must have one number";
  }
  if (value.search(/[\W]/) === -1) {
    // console.log("no special :: ", value);
    if (value.includes("_")) {
      return undefined;
    }
    return "Must have one special character";
  }
  return undefined;
});
// let's not use regex in user interactive events because they're unpredictable, especially in passwords
// .regex(
//   /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/,
//   "Minimum of 8 characters, including at least one capital letter and one number."
// );
// password_confirm: nope.string().required("is required").oneOf([nope.ref('password'), null], 'must match New Password'),

export const Login: ObjectValidator = nope.object().shape({ email, password });
