import type { ASingleStat } from "../_model";
import { Title } from "./title";

export function Card(
  props: ASingleStat<{ key: string; value: number }[]> & {
    keyColor?: "orange" | "yellow";
  }
) {
  return (
    <div class="flex flex-col w-full h-full space-y-3 <md:(space-y-1)">
      {/* title  */}
      <div class="flex flex-row w-full h-fit justify-between items-center">
        <Title title={props?.title} />
        {/* <p class="text#orange text-2rem font-bold">{`${props?.data}%`}</p> */}
      </div>
      {/* cards */}
      <div class="flex flex-row w-full h-fit space-x-2 items-start justify-between py-2 ">
        {props.data?.map((item, i) => {
          return (
            <div
              class={`
              flex flex-col w-full  h-30 rounded-2xl shadow-stats-card shadow-black/60 items-center justify-center px-2 py-3
              <md:(!py-1 px-1 !h-fit space-y-2)
            `}
            >
              <p
                style={{ color: `var(--${props.keyColor ?? "yellow"})` }}
                class="flex text-2.7rem !h-full items-center justify-center text-center font-bold <md:(text-1.4rem)"
              >
                {item.value}
              </p>
              <p class="text-0.6rem h-10% text-center w-full <md:(text-0.4rem)">{item.key}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
