import { global } from ":global";
import { ENDPOINTS } from "./endpoints";

export async function get_mentors() {
  return global.api
    .getAuth(ENDPOINTS.get_mentors)
    .then((res) => {
      let res_list = res.data as any[];
      res_list = res_list.sort((a, b) => b.id - a.id);
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      // message: service.api.decode_error(err, "couldn't update password"),
      return Promise.reject(err.message);
    });
}
