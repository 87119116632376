import type { InternalAxiosRequestConfig } from "axios";
import type { Options } from "../_model";

export default function createConfigObjectHandler(props: { options: Options }) {
  const { options } = props;
  const _main_key = options?.defaults?.configKey ?? "QUNXIOS";
  return {
    get keys() {
      return options.defaults;
    },
    get appendKey() {
      return (porps: { config: InternalAxiosRequestConfig; key: string; obj: any }) => {
        const { key, obj } = porps;
        let { config } = porps;
        let qunxios = config?.[_main_key] ?? {};
        let old_obj = qunxios?.[key] ?? obj;
        if (typeof old_obj !== typeof obj) {
          console.error(`Qunxios: error appending key ${key} since the old and new object types are incompatible!`);
          return config;
        }
        return {
          ...config,
          [_main_key]: {
            ...qunxios,
            [key]:
              typeof obj !== "object"
                ? obj
                : {
                    ...old_obj,
                    ...obj,
                  },
          },
        };
      };
    },
    get get() {
      return (props: { config: InternalAxiosRequestConfig }) => {
        const { config } = props;
        const result = config?.[_main_key];
        return result;
      };
    },
    get exists() {
      return (props: { config: InternalAxiosRequestConfig; key: string }) => {
        const { config, key } = props;
        const qunxios = config?.[_main_key];
        if (qunxios == undefined) {
          return false;
        }
        const result = qunxios?.[key];
        if (result == undefined) {
          return false;
        }
        return true;
      };
    },
    get appendInternalRequest() {
      return (props: { config: InternalAxiosRequestConfig }) => {
        const { config } = props;
        return this.appendKey({ config, key: this.keys.internalRequest, obj: true });
      };
    },
    get appendResponseError() {
      return (props: { config: InternalAxiosRequestConfig; obj: any }) => {
        const { config, obj } = props;
        return this.appendKey({ config, key: this.keys.responseError, obj });
      };
    },
    get appendRequestError() {
      return (props: { config: InternalAxiosRequestConfig; obj: any }) => {
        const { config, obj } = props;
        return this.appendKey({ config, key: this.keys.requestError, obj });
      };
    },
    get hasInternalRequest() {
      return (props: { config: InternalAxiosRequestConfig }) => {
        const { config } = props;
        return this.exists({ config, key: this.keys.internalRequest });
      };
    },
    get hasAuth() {
      return (props: { config: InternalAxiosRequestConfig }) => {
        const { config } = props;
        return this.exists({ config, key: this.keys.auth });
      };
    },
    get hasInterceptors() {
      return (props: { config: InternalAxiosRequestConfig }) => {
        const { config } = props;
        return this.exists({ config, key: this.keys.interceptors });
      };
    },
  };
}
