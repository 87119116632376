import { global } from ":global";
import { ENDPOINTS } from "./enpoints";

export async function get_questions() {
  return global.api
    .getAuth(ENDPOINTS.get_questions)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err.message);
    });
}
export async function get_table_range() {
  return global.api
    .getAuth(ENDPOINTS.tables_range)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err.message);
    });
}

