import { solidstate } from ":shared/utils/state-manager";
import { Match, Show, Switch, createEffect, splitProps } from "solid-js";
import { createLoginInputs } from "./forms";
import { requestPasswordResetEmail } from "../password-reset/apis";
import type { MainProps } from "./_model";
import { global } from ":global";

import BackArrow from "../SHARED/assets/icons/back-arrow.svg";

export default function Signin(props: MainProps) {
  const app_flavor = global.contexts.whitelabel.useAppFlavor();
  const app_meta = global.contexts.head_tag.useMeta();
  app_meta?.setTitle?.("Login");
  //
  const FormLogin = createLoginInputs("eslam_hd_part");
  const $reset_pass = solidstate.create(false);
  const $totp = solidstate.create(false);

  createEffect(() => {
    if ($reset_pass.value) {
      // FormLogin.Password.control.markRequired(false);
      FormLogin.Password.control.markDisabled(true);
    } else {
      FormLogin.Password.control.markDisabled(false);
    }
  });
  const onSubmit = async (e: Event) => {
    const values = FormLogin.Actions.getValuesLowercase();
    // console.log("login submit");
    if ($reset_pass.value) {
      await requestPasswordResetEmail(values.email).then((res) => {
        global.store.actions.alert.pushToast({
          type: "success",
          message: "confirmation email has been sent!",
        });
        console.log("Res:: ", res);
      });
      return;
    }
    await global.api.auth.login(values).then(({ decoded }) => {
      // $.actions.pushToast({ type: "success", message: "Logged in" });
      const user_base_route = global.api.auth.events.getUserBaseRoute();
      if (user_base_route == undefined) {
        return;
      }
      global.store.actions.alert.pushToast({
        type: "success",
        message: `logged in as ${decoded.first_name}`,
      });
      global.store.actions.route.navigate({ base: user_base_route });

      // if (code) {
      // 	log.dev.info("code", code);
      // 	$totp.set(true);
      // } else {
      // }
    });

    return;
  };

  return (
    <form class="flex flex-col w-full h-full overflow-auto">
      <Show when={$reset_pass.value}>
        <span
          class="cursor-pointer text-16px p-0 pt-2 text-black flex justify-start items-center gap-1"
          onClick={(e) => {
            if (!$reset_pass.value) {
            }
            $reset_pass.set(!$reset_pass.value);
          }}
        >
          <BackArrow class="w-25px h-25px" />
          Back to login
        </span>
      </Show>

      <h1 class="text-black text-xl font-bold py-2 md:(py-3 text-2xl)">
        <Switch fallback={"Sign in" || app_flavor.id}>
          <Match when={$reset_pass.value}>Forgot password?</Match>
        </Switch>
      </h1>
      <Show when={$reset_pass.value}>
        <p class="text#hint text-17px xl:max-w-[60%]! py-2">
          Don’t worry! It happens. we will sent a link to your email to reset password.Please enter the email associated
          with your account.
        </p>
      </Show>
      <Switch fallback={null}>
        <Match when={!$totp.value} keyed={true}>
          <div class="w-full py-3 ">
            <FormLogin.Email.Label title="Email Address" class="form-label">
              <FormLogin.Email.Error class="form-input-error" />
            </FormLogin.Email.Label>
            <FormLogin.Email.Input
              autocomplete="username"
              type="email"
              class="form-input"
              placeholder={!$reset_pass.value ? "Your email" : "Enter your email address"}
            />
          </div>
          <Show when={!$reset_pass.value}>
            <div class="w-full py-1">
              <FormLogin.Password.Label title="Password" class="form-label">
                <FormLogin.Password.Error class="form-input-error" />
              </FormLogin.Password.Label>
              <FormLogin.Password.Input
                autocomplete="current-password"
                type="password"
                class="form-input "
                placeholder="Password"
              />
            </div>
            {/* forgot passowrd btn */}
            <span
              class="w-full h-fit text-xs text-16px p-0 px-2 items-center justify-end text-end text#orange cursor-pointer md:(my-1) <md:(text-0.6rem my-1)"
              onClick={(e) => {
                $reset_pass.set(!$reset_pass.value);
              }}
            >
              {!$reset_pass.value ? "Forgot Password?" : "Back To Login"}
            </span>
          </Show>
        </Match>
      </Switch>

      <FormLogin.Actions.Button
        class="form-btn"
        onclick={onSubmit}
        statusValid={() => ($totp.value ? "Confirm TOTP" : !$reset_pass.value ? `Login` : "Send Link")}
        statusInvalid={!$reset_pass.value ? `Login` : "Send Link"}
        // onlyStatus={["valid", "submit"]}
      />
    </form>
  );
}
