import { global } from ":global";

export interface Get {
  props: { id: number };
  returns: any;
}

export default {
  endpoint: (props: { id: number }) => `/teaming/cancel-request/${props.id}/`,
  async get(props: Get["props"]): Promise<Get["returns"]> {
    const endpoint = this.endpoint({ id: props.id });
    return global.api.getAuth(endpoint).then((res) => {
      return res.data;
    });
  },
};
