import { onMount } from "solid-js";
import type { EvaluationModal } from "./_model";

export function URLViewer(props: { url: EvaluationModal.AUrl; onExit: () => void }) {
  onMount(() => {
    fetch(props.url?.url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("couldn't fetch url");
        }
        console.log("the res is :: ", res);
      })
      .catch((err) => {
        console.error("fetching error \n", err);
      });
  });
  return (
    <div class="flex flex-col w-full h-full space-y-2 px-2 pt-1">
      <div class="flex flex-row w-full fit$h justify-between">
        <span class="fit$ text-1.2rem text#p font-bold whitespace-nowrap">Preview</span>
        <button
          class="transition-all duration-300 ease-in-out
                            rounded-lg fit$ px-2 py-1 my-1 rtl:(ml-auto mr-35) hover:(shadow-xl) fight$  cursor-pointer bg#a text#page"
          onClick={(e) => props.onExit()}
        >
          Back To Grading
        </button>
      </div>
      {props.url?.type === "pdf" ? (
        <object
          // ${form_transition.stage === "from" && "translate-x-[0%]"}
          // ${form_transition.stage === "enter" && "translate-x-[0%]"}
          // ${form_transition.stage === "leave" && "translate-x-[0%]"}
          class={`transition-all duration-300 ease-in-out flex flex-col h-full !w-[99.1%]
                            `}
          data={String(props.url?.url ?? "")}
          type="application/pdf"
        >
          <iframe
            class="flex flex-col w-full h-full"
            src={`https://docs.google.com/viewer?url=${String(props.url?.url ?? "")}&embedded=true`}
          ></iframe>
        </object>
      ) : props.url?.type === "image" ? (
        <img class="w-full h-full" src={props.url?.url} alt="project.image" />
      ) : (
        <iframe
          class="flex flex-col w-full h-full"
          src={props.url?.url}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      )}
    </div>
  );
}
