import { Anchor, type _Navigation } from ":shared/components/route";
import { global } from ":global";

export default function Logout(props: _Navigation.SideNavJSXItemProps) {
  return (
    <div class="relative ">
      <Anchor
        class={`${props.anchor?.class ?? ""} border#pt border-2 border-solid`}
        setup={{
          // to: authRoutes.,
          classes: {
            active: `${props.anchor.setup.classes.active}`,
          },
        }}
        triggers={{
          // check that this is not working and change the function to onClick
          // also, check the anchor shared component to make sure it works fine
          onClick: async () => {
            const { res, error } = await global.api.auth.logout();
            if (error != undefined) {
              console.log("an error occured while trying to logout!!", error);
              return;
            }
            // const auth_routes = global.constants.ROUTES.auth;
            // global.store.actions.route.navigate({ base: auth_routes.BASE, path: auth_routes.login });
          },
        }}
        components={{
          title: "Logout",
          idleIcon: <i class="i-ri:logout-box-line" />,
        }}
      />
    </div>
  );
}
