import { Platform, type _Platform } from ":shared/components/loaders";
import { global } from ":global";
export default function Loader(props: { subtitle?: string; msg: string }) {
  const flavor = global.contexts.whitelabel.useAppFlavor();

  return (
    <Platform
      {...props}
      title={flavor.id}
      class="relative flex flex-col w-full h-full items-center justify-center bg#p text#a"
    />
  );
}
