import { AxiosError } from "axios";
import type { Props } from "./_model";

export default function Request(props: Props) {
  const { instance, options, cycles, helpers, _coh } = props;
  instance.interceptors.request.use(
    async (config) => {
      if (!config) config = {} as any;
      if (!config.headers) config.headers = {} as any;

      try {
        const config_obj = options?.processRequests?.({ req: config });
        if (config_obj != undefined) {
          config = {
            ...config_obj,
            ...config,
          };
        }

        //
        const auth_req = await cycles?.auth?.request?.({ config, helpers });

        if (auth_req != undefined) {
          // @ts-ignore
          if (auth_req.then) {
            return auth_req;
          }

          config = auth_req;
        }
      } catch (e: any) {
        console.error(e);
        if (typeof e === "string") {
          throw new AxiosError(e);
        } else if ("isAxiosError" in e) {
          throw e;
        } else {
          throw new AxiosError("Qunxios: unknown request error!!");
        }
      }
      return config;
    },
    async (err) => {
      try {
        const err_obj = options?.processRequestsErrors?.({ err });
        const config = _coh.appendRequestError({ config: err?.config, obj: err_obj });
        err.config = config;

        //
        const auth_err = await cycles?.auth?.requestError?.({ err, helpers });

        if (auth_err != undefined) {
          if (auth_err.then) {
            return auth_err;
          }
          err = auth_err;
        }
      } catch (e: any) {
        console.error(e);

        if (typeof e === "string") {
          throw new AxiosError(e);
        } else if ("isAxiosError" in e) {
          throw e;
        } else {
          throw new AxiosError("Qunxios: unknown request_err error!!");
        }
      }
      return Promise.reject(err);
    }
  );
}
