import { createFormControl } from "solid-forms";
import InputFile from "./main";
import { Error } from "../error";
import { Label } from "../label";
//
import type { FormControlData, CreateFormControlProps } from "../SHARED/_model";
import type { ICollectionInputFile } from "./_model";

export default function createInputFile<D extends FormControlData = FormControlData>(
  ...args: CreateFormControlProps<FileList, D>
): ICollectionInputFile<D> {
  const control = createFormControl<FileList, D>(...args);
  const name = String(control.id);
  return {
    control,
    name,
    Label: (props) => <Label control={control} for={name} {...props} />,
    Error: (props) => <Error control={control} {...props} />,
    InputFile: (props) => <InputFile control={control} name={name} dir="auto" {...props} />,
  };
}
