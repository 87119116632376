import { global } from ":global";
import type { AWorkshop } from "../_model";

export async function getWorkshops(): Promise<AWorkshop[]> {
  // return Promise.resolve(import("./get-workshops.mocks")).then((r) => {
  //   return r.success;
  // });
  return global.api
    .getAuth("workshops")
    .then((res) => {
      let res_list = res.data as AWorkshop[];
      res_list = res_list.sort((a, b) => b.id - a.id);
      return Promise.resolve(res_list);
    })
    .catch((err) => {
      // message: service.api.decode_error(err, "couldn't update password"),
      return Promise.reject(err.message);
    });
}
