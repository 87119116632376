export default function checkEndpointURL(url: string, name?: string) {
  if (url == undefined) {
    throw new Error(`Axios: <${name ?? "nameless"}> api endpoint URL cannot be null or undefined!`);
  }
  let result = url;
  const lowercase = url.toLowerCase();
  const last_slash_idx = result.lastIndexOf("/");
  const last_part_of_string = result.substring(last_slash_idx + 1);
  if (last_part_of_string.indexOf("?") < 0) {
    if (!lowercase.endsWith("/")) result += "/";
  }
  // link is whole link and not apeended to any base url
  if (lowercase.startsWith("http:") || lowercase.startsWith("https:")) {
  } else {
    if (!lowercase.startsWith("/")) {
      result = "/" + result;
    }
  }
  return result;
}
