import { TextRoller } from "../../animations";
import { For, splitProps } from "solid-js";
import type { MainProps } from "./_model";

export default function Platform(props: MainProps) {
  const [local, others] = splitProps(props, ["title", "subtitle", "msg"]);
  return (
    <div {...others} class={`text-1rem w-full <md:text-0.67rem ${others.class ?? ""}`}>
      {/* title */}
      <TextRoller word={local.title} containerClass="flex flex-row !w-fit !h-fit !text-justify" />
      {/* subtitle */}
      {props.subtitle && <p class="text-0.75em mt-3 text-black">{props.subtitle}</p>}
      {/* msg */}
      <For each={local?.msg?.split("\n")}>
        {(item) => {
          return <p class="text-1rem pt-4 <md:(text-0.7rem pt5) ">{item}</p>;
        }}
      </For>
    </div>
  );
}
